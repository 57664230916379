/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { BiCog, BiImageAdd } from 'react-icons/bi'
import { FiPlus, FiSend } from 'react-icons/fi'
import { PiPencilBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { ClubLogo } from '../../../components/Picture'
import { Text } from '../../../components/Text'
import { TextNew } from '../../../components/TextNew'
import { GroupMembersIcon, ShieldPlus } from '../../../components/icons'
import { Flex } from '../../../components/layout/Flex'
import { Modal, ModalTrigger } from '../../../components/modal/Modal'
import { useAuthContext } from '../../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { buttonReset, prettyLetterSpacing } from '../../../styles/styles'
import { colors, hexOpacity } from '../../../styles/theme'
import { colorIsLight } from '../../Admin/Utils'
import { userClubMembershipsQuery } from '../../Profile/queries'
import {
  clubProfileQuery,
  ClubProfileQueryResult,
  ClubRole,
  useJoinClubMutation,
  useRemoveFromClubMutation,
} from '../queries'
import { InviteMemberModal } from './InviteMemberModal'
import { Members } from './Members'

const nameTooLong = (str: string) => {
  const strSplit = str.split(' ')
  let longestWord = 0

  for (let i = 0; i < strSplit.length; i++) {
    if (strSplit[i].length > longestWord) {
      longestWord = strSplit[i].length
    }
  }
  return longestWord > 8
}

const Header = ({ club }: { club: ClubProfileQueryResult['club'] }) => {
  const t = useT()

  const navigate = useNavigate()

  const viewerCanEdit = club.viewerCanEdit

  const showAddLogo = viewerCanEdit && !club.logoUrl && club.viewerIsMember

  const showLogoContainer = club.logoUrl || showAddLogo

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px;
      `}
    >
      <Flex
        column
        horizontal="center"
        css={[
          css`
            grid-column: 1 / 3;
            padding: 16px;
            background-color: ${club.backgroundColor};
            border-radius: 20px;
            position: relative;
            margin-top: ${130}px;
            padding-top: ${showLogoContainer ? 50 : 16}px;

            @media (max-width: 599px) {
              margin-top: 180px;
            }
          `,
        ]}
      >
        {club.viewerCanEdit && (
          <Flex
            horizontal="flex-end"
            gap={8}
            column
            css={css`
              position: absolute;
              top: ${-130}px;
              right: 0px;
              @media (max-width: 599px) {
                top: -180px;
              }
            `}
          >
            {!club.backgroundImageUrl && club.viewerIsMember && (
              <IconButton
                rounded
                iconBottomMargin={-4}
                backgroundColor={hexOpacity(colors.grey300, 0.8)}
                onClick={() => {
                  navigate(`/clubs/${club.id}/edit`)
                }}
                text={t('Add background image')}
                icon={<BiImageAdd />}
              />
            )}
            <IconButton
              rounded
              backgroundColor={hexOpacity(colors.grey300, 0.8)}
              onClick={() => {
                navigate(`/clubs/${club.id}/edit`)
              }}
              text={t('Settings')}
              icon={<BiCog />}
            />
          </Flex>
        )}

        {showLogoContainer && (
          <div
            css={css`
              position: absolute;
              top: -70px;
            `}
          >
            {club.logoUrl && (
              <ClubLogo
                size={100}
                url={club.logoUrl}
                shape={club.logoShape}
                verified={club.verified}
              />
            )}
            {showAddLogo && (
              <button
                onClick={() => {
                  navigate(`/clubs/${club.slug}/edit`)
                }}
                css={[
                  buttonReset,
                  css`
                    height: 100px;
                    width: 100px;
                    background-color: ${colors.grey300};
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 6px;
                  `,
                ]}
              >
                <ShieldPlus size={32} />
                <TextNew
                  weight={700}
                  size={13}
                  color={colors.white}
                  textAlign="center"
                  css={[
                    prettyLetterSpacing,
                    css`
                      max-width: 68px;
                      margin: 0 auto;
                      padding: 0;
                    `,
                  ]}
                >
                  {t('Add logo')}
                </TextNew>
              </button>
            )}
          </div>
        )}
        <Heading
          level={1}
          textAlign="center"
          color={club.textColor}
          wide
          css={css`
            font-weight: 800;
            font-size: 42px;
            text-transform: uppercase;
            @media (max-width: 599px) {
              font-size: ${nameTooLong(club.name) ? '32px' : '42px'};
            }
          `}
        >
          {club.name}
        </Heading>

        <div
          css={[
            css`
              grid-column: 1 / 3;
              display: grid;
              grid-gap: 20px;
            `,
          ]}
        >
          {club.description && (
            <Text
              textAlign="center"
              color={club.textColor}
              size="small"
              css={css`
                padding: 0 30px;
              `}
            >
              {club.description}
            </Text>
          )}

          {!club.description && viewerCanEdit && club.viewerIsMember && (
            <button
              css={[
                buttonReset,
                css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 6px 16px;
                `,
              ]}
              onClick={() => {
                navigate(`/clubs/${club.id}/edit`)
              }}
            >
              <TextNew color={club.textColor}>
                {t(`Write a text about your club!`)}
              </TextNew>
              <PiPencilBold size={20} color={club.textColor} />
            </button>
          )}
        </div>
        <Spacer height={20} />
        <Buttons club={club} />
        <Spacer height={40} />
        <div
          css={[
            css`
              grid-column: 1 / 3;
              display: grid;
            `,
          ]}
        >
          <ModalTrigger
            button={({ openModal }) => (
              <IconButton
                iconLeft
                rounded
                backgroundColor={club.backgroundColor}
                textColor={club.textColor}
                onClick={openModal}
                text={
                  <TextNew
                    size="tiny"
                    color={club.textColor}
                    css={[
                      css`
                        text-transform: uppercase;
                      `,
                      prettyLetterSpacing,
                    ]}
                  >
                    {t('{{count}} member', { count: club.membersCount })}
                  </TextNew>
                }
                icon={<GroupMembersIcon color={club.textColor} />}
              />
            )}
            modal={({ closeModal }) => (
              <ClubMembersModal club={club} closeModal={closeModal} />
            )}
          />
        </div>
      </Flex>
    </div>
  )
}

const Buttons = ({ club }: { club: ClubProfileQueryResult['club'] }) => {
  const { authUser } = useAuthContext()
  const t = useT()
  const [removeFromClub, { loading: removeFromClubLoading }] =
    useRemoveFromClubMutation()
  const [joinClub, { error: joinClubError, loading: joinClubLoading }] =
    useJoinClubMutation()

  const trackEvent = useGetTrackEvent()

  const apolloClient = useApolloClient()
  const buttonVariant = colorIsLight(club.backgroundColor) ? 'black' : 'white'

  return (
    <Flex column horizontal="center">
      <Flex css={margin.top()} gap="medium" vertical="center">
        {club.viewerIsMember && club.viewerRole !== 'admin' && (
          <ModalTrigger
            button={({ openModal }) => (
              <SecondaryButton
                rounded
                disabled={removeFromClubLoading}
                onClick={openModal}
                variant={buttonVariant}
                css={css`
                  min-height: 48px;
                `}
              >
                {t('Leave')}
              </SecondaryButton>
            )}
            modal={({ closeModal }) => (
              <Modal heading={t('Leave club')} closeModal={closeModal}>
                <Flex column horizontal="flex-start">
                  <Text>
                    {t('Are you sure you want to leave {{clubName}}?', {
                      clubName: club.name,
                    })}
                  </Text>

                  <PrimaryButton
                    rounded
                    css={[
                      margin.top(),
                      css`
                        white-space: nowrap;
                      `,
                    ]}
                    disabled={removeFromClubLoading}
                    onClick={() => {
                      removeFromClub({
                        variables: {
                          input: {
                            clubId: club.id,
                            userId: authUser?.uid!,
                          },
                        },
                        refetchQueries: [
                          {
                            query: clubProfileQuery,
                            variables: { slug: club.slug },
                          },
                        ],
                      }).then(() => {
                        closeModal()
                      })
                    }}
                  >
                    {t('Leave club')}
                  </PrimaryButton>
                </Flex>
              </Modal>
            )}
          />
        )}

        {club.viewerIsMember && (
          <ModalTrigger
            button={({ openModal }) => (
              <IconButton
                onClick={openModal}
                variant={buttonVariant}
                borderColor={club.textColor}
                rounded
                text={t('Invite')}
                icon={<FiSend size={20} />}
              />
            )}
            modal={({ closeModal }) => (
              <InviteMemberModal club={club} closeModal={closeModal} />
            )}
          />
        )}

        {!club.viewerIsMember && club.viewerCanJoin && (
          <IconButton
            signUpRequired
            rounded
            onClick={() => {
              joinClub({
                variables: {
                  input: { clubId: club.id },
                },
                refetchQueries: [
                  {
                    query: clubProfileQuery,
                    variables: { slug: club.id },
                  },
                ],
              })
                .then(() => {
                  apolloClient.refetchQueries({
                    include: [userClubMembershipsQuery],
                  })
                  trackEvent(AnalyticsEventType.ClubJoined, {
                    clubId: club.id,
                    type: 'club-page',
                  })
                })
                .catch((e) => console.error('Failed to join via url', e))
            }}
            disabled={!!(joinClubLoading || joinClubError)}
            text={t('Join')}
            icon={<FiPlus size={20} />}
            backgroundColor={
              colorIsLight(club.backgroundColor) ? colors.black : colors.white
            }
            textColor={
              colorIsLight(club.backgroundColor) ? colors.white : colors.black
            }
          />
        )}
      </Flex>

      {joinClubError && <Text>{t('Something went wrong')}</Text>}
    </Flex>
  )
}

export const ClubTopContentV2 = ({
  club,
}: {
  club: ClubProfileQueryResult['club']
}) => {
  return (
    <Flex column horizontal="center">
      <Flex
        column
        css={css`
          width: 100%;
          max-width: 590px;
        `}
      >
        <Header club={club} />
      </Flex>
    </Flex>
  )
}

const ClubMembersModal = ({
  closeModal,
  club,
}: {
  closeModal: () => void
  club: {
    id: string
    slug: string
    viewerRole: ClubRole | null
    membersCount: number
  }
}) => {
  return (
    <Modal
      heading="Members"
      closeModal={closeModal}
      backgroundColor={colors.grey200}
      borderRadius={'20px'}
    >
      <Members
        clubId={club.id}
        clubSlug={club.slug}
        viewerRole={club.viewerRole}
        membersCount={club.membersCount}
        maxWidth={'800px'}
        memberBatchSize={15}
        textColor={'black'}
      />
    </Modal>
  )
}
