/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { useT } from '../../lib/i18n/useT'
import { pulseAnimation } from '../../styles/animations'
import theme, { colors } from '../../styles/theme'
import { PrimaryButton } from '../Button'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'

export const ChallengeButton = ({
  instanceId,
  className,
}: {
  instanceId: string
  className?: string
}) => {
  const navigate = useNavigate()
  const t = useT()

  const hasClickedChallenge = window.localStorage.getItem('hasClickedChallenge')

  return (
    <PrimaryButton
      rounded
      onClick={() => {
        navigate(`/challenge/${instanceId}`)
        if (!hasClickedChallenge) {
          window.localStorage.setItem('hasClickedChallenge', 'true')
        }
      }}
      css={css`
        min-height: 48px;
        margin: 0 16px;
        background-color: ${colors.purple500};
        width: fit-content;
        margin: 0 auto;
        box-shadow: ${theme.boxShadows.default};
        position: relative;
        margin-top: 12px;
      `}
      className={className}
    >
      <div
        css={[
          css`
            position: absolute;
            top: -20px;
            left: -20px;
            background-color: ${colors.purple200};
            padding: 4px 16px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 16px;
            z-index: 1;
            box-shadow: ${theme.boxShadows.default};
          `,
          !hasClickedChallenge &&
            css`
              animation: ${pulseAnimation} 1500ms infinite;
            `,
        ]}
      >
        <TextNew
          color={colors.black}
          condensed
          italic
          size={16}
          css={css`
            white-space: nowrap;
          `}
        >
          {t('New feature!')}
        </TextNew>
      </div>
      <Flex horizontal="center" gap="small" vertical="center">
        <TextNew size={28}>💥</TextNew>
        <TextNew italic condensed color={colors.white} weight={600} size={24}>
          {t('Challenge a friend')}
        </TextNew>
        <TextNew size={28}>💥</TextNew>
      </Flex>
    </PrimaryButton>
  )
}
