/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BadgeComponent } from '../../components/BadgeComponent'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { HeadingNew } from '../../components/HeadingNew'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { boxShadows, colors, hexOpacity, hexToRgba } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useNow } from '../../utils/useNow'
import {
  calculateTimeRemaining,
  calculateTimeRemainingClock,
  colorIsLight,
  datePlus,
  timeRemainingToStringClock,
} from '../Admin/Utils'
import {
  Badge,
  LeagueQuizMinimal,
  SpecialQuizQueryResults,
} from '../League/queries'
import { SpecialLeaderboard } from './SpecialLeaderboard'

export const DeadlineAndPlayers = ({
  hideDeadline = false,
  color,
  currentQuiz,
  participantCount,
  live,
}: {
  hideDeadline?: boolean
  color: string
  currentQuiz?: LeagueQuizMinimal
  participantCount?: number
  live?: number
}) => {
  const _timeRemaining = () => {
    if (!currentQuiz) return ''
    const todaysQuiz = currentQuiz
    let endDate = datePlus(new Date(), { hours: 23, minutes: 59, seconds: 59 })
    if (todaysQuiz && todaysQuiz.availableTo) {
      endDate = new Date(todaysQuiz.availableTo)
    }
    return timeRemainingToStringClock(
      calculateTimeRemaining(new Date(), endDate)
    )
  }

  const [timeRemaining, setTimeRemaining] = useState(_timeRemaining())

  useEffect(() => {
    setTimeRemaining(_timeRemaining())
  }, [])

  useInterval(() => {
    setTimeRemaining(_timeRemaining())
  }, 1000)
  const t = useT()
  return (
    <>
      <Flex
        css={[
          css`
            position: absolute;
            left: 15px;
            bottom: 20px;
          `,
          hideDeadline &&
            css`
              display: none;
            `,
        ]}
        column
      >
        <TextNew size={6} strong italic uppercase color={color}>
          {t(`Deadline`)}
        </TextNew>
        <TextNew size={26} italic condensed color={color}>
          {timeRemaining}
        </TextNew>
      </Flex>
      <Flex
        css={css`
          position: absolute;
          right: 15px;
          bottom: 20px;
        `}
        horizontal="flex-end"
        column
      >
        <TextNew
          size={8} //PageContentNew: 10
          strong
          italic
          uppercase
          color={color}
        >
          {participantCount ?? 0} {t(`Players`)}
        </TextNew>
        <TextNew
          size={8} //PageContentNew: 10
          strong
          italic
          uppercase
          color={color}
        >
          {live ?? 0} Live
        </TextNew>
      </Flex>
    </>
  )
}

export const GradientOverlay = (props: {
  gradient: string
  borderRadius?: number
}) => (
  <Flex
    css={[
      css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: ${props.borderRadius ?? 15}px;
      `,
      props.gradient,
    ]}
  />
)

export const BlackOverlay = (props: {
  overlayDarker?: boolean
  gradientStartColor?: string
  gradient?: boolean
  gradientDarker?: boolean
}) => (
  <Flex
    css={[
      css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        z-index: -1;
        border-radius: 10px;
      `,
      props.gradient
        ? css`
            background: linear-gradient(
              180deg,
              ${props.gradientStartColor},
              rgba(0, 0, 0, ${props.gradientDarker ? '0.61' : '0'}) 100%
            );
          `
        : `background: ${hexOpacity(
            colors.black,
            props.overlayDarker ? 0.85 : 0.66
          )}`,
    ]}
  />
)

// Had to be its own object (and not a background of the parent flex)
// Else it would either:
// * block the button click
// * visually block the black overlay
export const Banner = (props: { imageURL: string }) => (
  <Flex
    css={css`
      background: url(${props.imageURL});
      opacity: 0.5;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      position: absolute;
      z-index: -1;
    `}
  />
)

const Timer = (props: { nextQuizDate: Date }) => {
  const now = useNow({ updateFrequencyInMs: 1000 })
  const remaining = calculateTimeRemainingClock(now, props.nextQuizDate)

  return (
    <Heading
      level={3}
      italic
      condensed
      css={css`
        margin-top: -3px;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      `}
      color={'white'}
    >
      {remaining}
    </Heading>
  )
}

const TextTimer = (props: { nextQuizDate: Date; textColor: string }) => {
  const t = useT()
  return (
    <Flex column vertical="center" horizontal="center">
      <Text
        css={css`
          white-space: nowrap;
          text-transform: uppercase;
          text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
          font-weight: 300;
        `}
        size="small"
        color={props.textColor}
      >
        {t(`Next quiz in`)}
      </Text>
      <Timer nextQuizDate={props.nextQuizDate} />
    </Flex>
  )
}

const NoActiveQuiz = ({ textColor }: { textColor: string }) => {
  const t = useT()
  return (
    <HeadingNew
      color={textColor}
      italic
      condensed
      weight={500}
      level={3}
      looksLikeLevel={5}
    >
      {t('No active quizzes')}
    </HeadingNew>
  )
}

export const BadgeBanner = (props: {
  numQuizzesPlayed: number
  badge: Badge
  earnedBadge?: Badge
}) => {
  const { numQuizzesPlayed, badge, earnedBadge } = props

  const criteriaPlayed = badge.criteria.quizzesCompleted

  const progress = (100 * numQuizzesPlayed) / criteriaPlayed

  return (
    <Flex
      css={css`
        max-width: 350px;
        padding: 10px 15px;
        position: relative;
        z-index: 1;
        width: 100%;
      `}
      horizontal="center"
      gap="large"
    >
      <BlackOverlay overlayDarker />

      <Flex column vertical="center" gap="tiny" grow>
        {!earnedBadge && (
          <Flex
            css={css`
              background-color: ${badge.progressBackgroundColor};
              border-radius: 50px;
              height: 14px;
            `}
            vertical="center"
          >
            <Flex
              css={css`
                background-color: ${badge.progressForegroundColor};
                border-radius: 50px;
                margin: 4px;
                height: 8px;
                width: calc(${progress}% - 8px);
              `}
            />
          </Flex>
        )}
        <Text
          color="white"
          size="tiny"
          textAlign="center"
          wide
          css={css`
            font-size: 11px;
            font-weight: 300;
            line-height: 1.2;
          `}
        >
          {earnedBadge
            ? earnedBadge.earnedMessage
            : badge.requirementMessage.replace(
                '{{count}}',
                `${criteriaPlayed}`
              )}
        </Text>
      </Flex>

      <BadgeComponent badge={badge} size={56} />
    </Flex>
  )
}

const BadgeBannerTransparent = (props: {
  numQuizzesPlayed: number
  badge: Badge
  textColor: string
}) => {
  const t = useT()

  const { numQuizzesPlayed, badge, textColor } = props

  const criteriaPlayed = badge.criteria.quizzesCompleted

  const progress = (100 * numQuizzesPlayed) / criteriaPlayed

  return (
    <Flex
      css={css`
        max-width: 350px;
        position: relative;
        z-index: 1;
        width: 100%;
      `}
      horizontal="center"
      vertical="center"
      gap="small"
    >
      <TextNew
        color={textColor}
        size={10}
        css={css`
          white-space: nowrap;
        `}
        semiCondensed
        italic
        spacingEm={0.03}
      >
        {t(`Claim the badge`)}
      </TextNew>
      <Flex
        css={css`
          background-color: ${badge.progressBackgroundColor};
          border-radius: 50px;
          height: 5px;
          width: 200px;
        `}
        vertical="center"
      >
        <Flex
          css={[
            css`
              background-color: ${badge.progressForegroundColor};
              border-radius: 50px;
              margin: 1px;
              height: 4px;
              width: calc(${progress}% - 2px);
            `,
            progress !== 100 &&
              css`
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              `,
          ]}
        />
      </Flex>

      <TextNew
        color={textColor}
        size={10}
        css={css`
          white-space: nowrap;
        `}
        semiCondensed
        italic
      >
        {numQuizzesPlayed} / {criteriaPlayed}
      </TextNew>

      <BadgeComponent
        css={css`
          margin-left: 10px;
          margin-bottom: -10px;
        `}
        badge={badge}
        size={34}
      />
    </Flex>
  )
}

export const SpecialQuiz = (props: {
  leaguePageQueryResult: SpecialQuizQueryResults
}) => {
  const { leaguePageQueryResult } = props

  const t = useT()

  const isAseanCup = leaguePageQueryResult.league.slug === 'asean-cup'

  const league = leaguePageQueryResult?.league

  const { latestStartableQuiz, upcommingQuiz } = leaguePageQueryResult.league

  const nextQuizDate = upcommingQuiz
    ? new Date(upcommingQuiz.availableFrom)
    : undefined

  const badge = league.badges.length > 0 ? league.badges[0] : undefined

  const StartButton = () => (
    <PrimaryButton
      variant="white"
      css={css`
        border-radius: 99px;
        padding: 0px 40px;
        box-shadow: ${boxShadows.default};
      `}
    >
      <TextNew
        size={24}
        condensed
        italic
        strong
        css={css`
          white-space: nowrap;
        `}
      >
        {t(`Play`)}
      </TextNew>
    </PrimaryButton>
  )

  const CallToAction = ({ textColor }: { textColor: string }) => {
    const canStart =
      latestStartableQuiz &&
      (latestStartableQuiz.viewerCanStartQuiz ||
        (!latestStartableQuiz.viewerData.hasCompletedQuiz &&
          latestStartableQuiz.status === 'active'))

    if (canStart) {
      return <StartButton />
    }

    if (nextQuizDate) {
      return <TextTimer nextQuizDate={nextQuizDate} textColor={textColor} />
    }

    return <NoActiveQuiz textColor={textColor} />
  }

  const leagueColor = league.color || colors.grey500
  const textColor = colorIsLight(leagueColor) ? colors.black : colors.white

  return (
    <Flex column>
      <Link
        css={css`
          z-index: 2;
          text-decoration: none;
        `}
        aria-label={t('To the series page')}
        to={`/series/${league.slug}`}
      >
        <Flex
          css={css`
            height: 250px;
            position: relative;

            padding: 20px 15px;

            border-radius: 15px;
            filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.4));
          `}
          column
          horizontal="center"
          gap="medium"
        >
          {isAseanCup && (
            <img
              src={'/images/thailand_shirts_no_bg.png'}
              alt="thailand shirts"
              css={css`
                position: absolute;
                max-height: 160px;
                left: 48px;

                bottom: -0px;
                z-index: 0;
                opacity: 1;

                @media (max-width: 720px) {
                  max-height: 120px;
                  left: 8px;
                }
              `}
            />
          )}

          {isAseanCup && (
            <Flex
              css={css`
                position: absolute;
                left: 15px;
                bottom: 20px;
              `}
              horizontal="flex-end"
              column
            >
              <TextNew
                //PageContentNew: 10
                strong
                italic
                uppercase
                shadow
                color={colors.white}
                css={css`
                  font-size: 14px;

                  @media (max-width: 720px) {
                    font-size: 8px;
                  }
                `}
              >
                {'Win this signed Thailand kit!'}
              </TextNew>
            </Flex>
          )}

          <GradientOverlay
            gradient={`background-color: ${
              league.imageUrl ? colors.black : leagueColor
            }`}
          />
          {league.imageUrl && (
            <>
              <Banner imageURL={league.imageUrl} />
              <GradientOverlay
                gradient={`background: linear-gradient(171deg, ${hexToRgba(
                  leagueColor,
                  0.7
                )} 26.92%, ${hexToRgba(leagueColor, 0)} 105.01%);`}
              />
            </>
          )}
          {badge && (
            <BadgeBannerTransparent
              textColor={textColor}
              badge={badge}
              numQuizzesPlayed={league.quizzesPlayed}
            />
          )}
          <HeadingNew
            css={css`
              margin-top: 10px;
              max-width: 400px;
              display: flex;
              justify-content: center;
              text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
              z-index: 1;
              font-size: 55px;

              @media (max-width: 720px) {
                font-size: 40px;
              }
            `}
            italic
            weight={600}
            condensed
            level={2}
            looksLikeLevel={4}
            color={isAseanCup ? colors.white : textColor}
            textAlign="center"
          >
            {league.title}
          </HeadingNew>
          <Flex column horizontal="center">
            <Flex
              css={css`
                height: 40px;
                margin: 15px;
                z-index: 1;
              `}
            >
              <CallToAction
                textColor={league.imageUrl ? colors.white : textColor}
              />
            </Flex>
          </Flex>
          <DeadlineAndPlayers
            hideDeadline
            color={league.imageUrl ? colors.white : textColor}
            live={leaguePageQueryResult.league.live}
            participantCount={leaguePageQueryResult.league.participantCount}
          />
        </Flex>
      </Link>

      {league.showClubsLeaderboard && (
        <SpecialLeaderboard
          slug={league.slug}
          clubsLeaderboard={league.clubsLeaderboard}
          leagueColor={leagueColor}
        />
      )}
    </Flex>
  )
}
