/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { AndroidOrIosButton } from '../../components/StoreButtons'
import { Text } from '../../components/Text'
import { FcQuizLogo, Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { getIconAndTitle } from '../../utils/helperFunctions'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { EmbedTrackingComponent } from './EmbedTrackingComponent'
import { EmbedErrorPage, EmbedLoadingPage } from './SubPages'
import { useLeagueQuizQuery, usePublicLeagueQuery } from './queries'
import { PublicLeague } from './types'

interface QueryParams {
  domain?: string
  quizType: string
}

const getSearchParams = (quizType: string, embedDomain?: string | null) => {
  let params: QueryParams = {
    quizType: quizType,
  }

  if (embedDomain) {
    params.domain = embedDomain
  }

  const validParams: Record<string, string> = {}
  Object.keys(params).forEach((key) => {
    if (params[key as keyof QueryParams]) {
      validParams[key] = params[key as keyof QueryParams]!
    }
  })

  return validParams
}

const Content = ({
  league,
  quizId,
  embedDomain,
}: {
  league: PublicLeague
  quizId: string
  embedDomain: string | null
}) => {
  const navigate = useNavigate()
  const { authUser, signInAsAnonymous } = useAuthContext()
  const [loading, setLoading] = useState<boolean>(false)
  const t = useT()
  const [startLeagueQuizMutation] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const trackEvent = useGetTrackEvent()

  const quizQuery = useLeagueQuizQuery(quizId)
  // if user is logged in and has an active or completed quiz instance, redirect to instance
  useEffect(() => {
    const currentQuiz = quizQuery.data?.leagueQuiz

    if (!!authUser && currentQuiz?.viewerCanStartQuiz === false) {
      if (currentQuiz.viewerData?.activeQuizInstance) {
        const activeQuizId = currentQuiz.viewerData?.activeQuizInstance.id
        navigate({
          pathname: `/embed/quiz/${activeQuizId}/${league.id}`,
          search: `?${createSearchParams(
            getSearchParams(currentQuiz.quizType ?? 'text', embedDomain)
          )}`,
        })
      }
      if (currentQuiz?.viewerData?.hasCompletedQuiz) {
        const completedQuizId =
          currentQuiz.viewerData?.result?.leagueQuizInstanceId
        navigate({
          pathname: `/embed/quiz/${completedQuizId}/${league.id}}`,
          search: `?${createSearchParams(
            getSearchParams(currentQuiz.quizType ?? 'text', embedDomain)
          )}`,
        })
      }
    }
  }, [authUser, quizQuery.data?.leagueQuiz?.viewerCanStartQuiz])

  if (loading || quizQuery.loading) {
    return <EmbedLoadingPage />
  }

  if (!quizQuery.data?.leagueQuiz) {
    return <EmbedErrorPage />
  }

  const currentQuiz = quizQuery.data.leagueQuiz!

  const onPlayClick = async () => {
    if (!currentQuiz) return
    if (!authUser) {
      await signInAsAnonymous()
    }

    if (currentQuiz.quizType === 'careerPath') {
      startCareerPathQuizMutation({
        variables: { input: { leagueQuizId: currentQuiz.id } },
      }).then((result) => {
        const quizInstanceId = result.data?.startCareerPathQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: currentQuiz.id,
            leagueId: league.id,
            domain: embedDomain,
            isCareerPath: true,
            isEmbed: true,
          })
          navigate({
            pathname: `/embed/quiz/${quizInstanceId}/${league.id}}`,
            search: `?${createSearchParams(
              getSearchParams(currentQuiz.quizType ?? 'text', embedDomain)
            )}`,
          })
          setLoading(false)
        }
      })
      return
    }

    startLeagueQuizMutation({
      variables: { input: { leagueQuizId: currentQuiz.id } },
    })
      .then((result) => {
        const quizInstanceId = result.data?.startLeagueQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: currentQuiz.id,
            leagueId: league.id,
            domain: embedDomain,
            isEmbed: true,
          })
          navigate({
            pathname: `/embed/quiz/${quizInstanceId}/${league.id}}`,
            search: `?${createSearchParams(
              getSearchParams(currentQuiz.quizType ?? 'text', embedDomain)
            )}`,
          })
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error('error', error)
        setLoading(false)
      })
  }

  const { title: leagueTitle } = getIconAndTitle(league?.title)
  const backgroundImage = currentQuiz.imageUrl

  return (
    <Flex
      vertical="center"
      column
      css={[
        css`
          background-color: ${colors.grey100};
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid ${colors.grey200};
          height: 100vh;
          width: 100%;
        `,
        backgroundImage &&
          css`
            background-image: url(${backgroundImage});
            background-size: cover;
            background-position: center;
          `,
      ]}
    >
      <Flex
        column
        vertical="space-between"
        horizontal="center"
        css={css`
          width: 100%;
          height: 100%;
          min-height: 264px;
          z-index: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          padding-top: 48px;
        `}
      >
        <FcQuizLogo
          height={13}
          color={backgroundImage ? colors.white : colors.black}
        />
        <Flex
          column
          css={css`
            display: flex;
            padding: 30px 25px;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: auto auto;
            max-width: 275px;
            border-radius: 4px;
            background-color: white;
          `}
        >
          <Text
            size="medium"
            css={css`
              text-align: center;
            `}
          >
            {leagueTitle}
          </Text>
          <Spacer height={16} />
          <Heading
            level={3}
            looksLikeLevel={5}
            css={css`
              text-align: center;
              line-height: 1.2;
              font-size: 24px;
              padding-top: 15px;
            `}
          >
            {currentQuiz?.embedPromotionalText ?? currentQuiz?.title}
          </Heading>
          <Spacer height={32} />
          <EmbedTrackingComponent
            eventType={AnalyticsEventType.EmbedLandingpage}
            eventData={{
              quizId: currentQuiz.id,
              leagueId: league.id,
              embedDomain,
            }}
          />
          {currentQuiz ? (
            <PrimaryButton
              disabled={loading}
              loading={loading}
              onClick={() => {
                setLoading(true)
                onPlayClick()
              }}
              css={[
                css`
                  white-space: nowrap;
                  max-width: 300px;
                  width: 100%;
                  margin: 0 auto;
                  height: 48px;
                  padding: 0;
                `,
              ]}
            >
              {t('Play quiz')}
            </PrimaryButton>
          ) : (
            <a
              href={`/series/${league.id}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
            >
              <PrimaryButton
                css={css`
                  background-color: black;
                  color: white;
                  width: fit-content;
                  padding: 5px 20px;
                  margin: 10px auto;
                  margin-bottom: 0;
                `}
              >
                {t('Play in the app!')}
              </PrimaryButton>
            </a>
          )}
        </Flex>
        <Flex
          horizontal="space-between"
          css={css`
            width: 100%;

            z-index: 1;
            height: 82px;
            display: flex;
            align-items: center;
            padding: 0 20px;
          `}
        >
          <a href="/" target="blank" rel="noreferrer">
            <Logo
              color={backgroundImage ? colors.white : colors.black}
              size={40}
            />
          </a>
          <AndroidOrIosButton />
        </Flex>
      </Flex>
    </Flex>
  )
}

// Used when linking directly to either a league or a league quiz
const LeagueEmbedLandingPage = () => {
  const { loading } = useAuthContext()

  // leagueId is required
  const leagueId = useParams<'leagueId'>().leagueId!
  const publicLeagueQuery = usePublicLeagueQuery(leagueId)

  // quizId is optional, will use the leagues current quiz if not provided
  const quizId = useParams<'quizId'>().quizId

  const [searchParams] = useSearchParams()
  const embedDomain = searchParams.get('domain')

  if (loading || publicLeagueQuery.loading) {
    return <EmbedLoadingPage />
  }

  // league should have a current quiz or quizId should be provided
  if (
    !publicLeagueQuery.data ||
    !publicLeagueQuery.data.publicLeague ||
    (publicLeagueQuery.data?.publicLeague?.currentQuiz === null && !quizId)
  ) {
    return <EmbedErrorPage />
  }

  // if quizId is not provided, use current quiz
  const embedQuizId =
    quizId === 'current'
      ? publicLeagueQuery.data.publicLeague?.currentQuiz?.id
      : quizId

  // if quizId is not provided and there is no current quiz, show error
  if (!embedQuizId) {
    return <EmbedErrorPage />
  }

  return (
    <Content
      league={publicLeagueQuery.data.publicLeague}
      quizId={embedQuizId}
      embedDomain={embedDomain}
    />
  )
}
export default LeagueEmbedLandingPage
