import { useEffect, useState } from 'react'
import { AnalyticsEventType, useGetTrackEvent } from '../lib/analytics'

export const ExternalCampaignTracker = () => {
  const trackEvent = useGetTrackEvent()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const campaign = urlParams.get('campaign')

    if (campaign) {
      trackEvent(AnalyticsEventType.ExternalCampaign, {
        externalCampaign: campaign,
      })
      window.sessionStorage.setItem('externalCampaign', campaign)
    }
  }, [])

  return null
}

export const useExternalCampaign = () => {
  const [currentCampaign, setCurrentCampaign] = useState<string | undefined>()

  useEffect(() => {
    const campaign = window.sessionStorage.getItem('externalCampaign')
    if (campaign) {
      setCurrentCampaign(campaign)
    }
  }, [])

  return currentCampaign
}
