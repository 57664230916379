/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import 'quill/dist/quill.snow.css'
import { useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'
import {
  useAuthenticLeagueSyncMutation,
  useAuthenticPlayerSyncMutation,
  useAuthenticTeamSyncMutation,
  useDataSyncStatusQuery,
} from './queries'
import { Header } from '../../components/Header'
import { TextNew } from '../../components/TextNew'

export const LEAGUES = {
  premierLeague: 39,
  laLiga: 140,
  serieA: 135,
  bundesliga: 78,
  ligue1: 61,
  eliteserien: 103,
  eredivise: 88,
  mls: 253,
  saudiProLeague: 307,
  allSvenskan: 113,
  superLiga: 119,
  brazilSerieA: 71,
  championship: 40,
  primeiraLiga: 94,
}

export enum Status {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

const Content = () => {
  return (
    <div
      css={[
        margin.top('large'),
        css`
          width: 100%;
        `,
      ]}
    >
      <AuthenticDataSyncComponent />
    </div>
  )
}

function AuthenticDataSyncComponent() {
  const { data: dataSyncStatus } = useDataSyncStatusQuery()
  const [
    authenticLeagueSyncMutation,
    { loading: syncLeaguesLoading, data: leaguesData },
  ] = useAuthenticLeagueSyncMutation()

  const [
    authenticTeamSyncMutation,
    { loading: syncTeamsLoading, data: teamsData },
  ] = useAuthenticTeamSyncMutation()

  const [
    authenticPlayerSyncMutation,
    { loading: syncPlayersLoading, data: playersData },
  ] = useAuthenticPlayerSyncMutation()

  const [selectedLeagues, setSelectedLeagues] = useState<number[]>([])
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  )

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value))
  }

  return (
    <Flex column gap={32}>
      <Text
        strong
        css={css`
          margin-top: 32px;
        `}
      >
        Authentic data sync
      </Text>

      {/* Year Selection Dropdown */}
      <Flex column gap={8}>
        <TextNew>Select Year for Data Sync</TextNew>
        <TextNew size={'small'} color={colors.grey400}>
          For leagues that operate with 24/25 season formats, 2024 means the
          2024/2025 season.
        </TextNew>
        <select
          value={selectedYear}
          onChange={handleYearChange}
          css={css`
            padding: 8px;
            border: 1px solid ${colors.grey400};
            border-radius: 4px;
            max-width: 150px;
          `}
        >
          {[...Array(10)].map((_, i) => {
            const year = new Date().getFullYear() - i
            return (
              <option key={year} value={year}>
                {year}
              </option>
            )
          })}
        </select>
      </Flex>
      <Flex column gap={8}>
        <Text italic>
          Authentic Leagues{' '}
          {dataSyncStatus?.getDataSyncStatus.leagues.status ===
            Status.IN_PROGRESS && 'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Leagues synced:{' '}
              {dataSyncStatus?.getDataSyncStatus.leagues.objectsAdded || 0} /{' '}
              {''}
              {dataSyncStatus?.getDataSyncStatus.leagues.totalObjects || 0}
            </Text>
          </Flex>

          {(syncLeaguesLoading ||
            dataSyncStatus?.getDataSyncStatus.leagues.status ===
              Status.IN_PROGRESS) && <Loader size={32} />}
          <Flex column>
            <PrimaryButton onClick={() => authenticLeagueSyncMutation()}>
              Sync authentic leagues
            </PrimaryButton>
            {leaguesData?.addLeagues && (
              <Text color={colors.grey400}>
                {leaguesData.addLeagues.message}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex column gap={8}>
        <Text italic>
          Authentic Clubs{' '}
          {(syncTeamsLoading ||
            dataSyncStatus?.getDataSyncStatus.teams.status ===
              Status.IN_PROGRESS) &&
            'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.teams?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.teams?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.teams?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Clubs synced:{' '}
              {dataSyncStatus?.getDataSyncStatus?.teams?.objectsAdded || 0} /{' '}
              {dataSyncStatus?.getDataSyncStatus?.teams?.totalObjects || 0}
            </Text>
          </Flex>

          <Flex column={false} vertical={'center'} gap={12}>
            {(syncTeamsLoading ||
              dataSyncStatus?.getDataSyncStatus.teams.status ===
                Status.IN_PROGRESS) && <Loader size={32} />}
            <Flex
              column
              css={css`
                max-width: 300px;
              `}
            >
              <PrimaryButton onClick={() => authenticTeamSyncMutation()}>
                Sync authentic clubs
              </PrimaryButton>
              {teamsData?.addTeams && (
                <Text color={colors.grey400}>{teamsData.addTeams.message}</Text>
              )}
              {(syncTeamsLoading ||
                dataSyncStatus?.getDataSyncStatus.teams.status ===
                  Status.IN_PROGRESS) && (
                <Text
                  css={css`
                    word-break: break-all;
                  `}
                >
                  This could take a while, go for a walk or something...
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* Sync Buttons */}
      <Flex column gap={8}>
        <Text italic>
          Authentic Players{' '}
          {(syncPlayersLoading ||
            dataSyncStatus?.getDataSyncStatus.players.status ===
              Status.IN_PROGRESS) &&
            'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.players?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.players?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.players?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Players synced:{' '}
              {dataSyncStatus?.getDataSyncStatus?.players?.objectsAdded || 0} /{' '}
              {dataSyncStatus?.getDataSyncStatus?.players?.totalObjects || 0}
            </Text>
          </Flex>

          <Flex column={false} vertical={'center'} gap={12}>
            {(syncPlayersLoading ||
              dataSyncStatus?.getDataSyncStatus.players.status ===
                Status.IN_PROGRESS) && <Loader size={32} />}
            <Flex
              column
              css={css`
                max-width: 300px;
              `}
            >
              <PrimaryButton
                onClick={() =>
                  authenticPlayerSyncMutation({
                    variables: {
                      leagues: selectedLeagues,
                      year: selectedYear, // Pass the selected year to the query
                    },
                  })
                }
              >
                Sync authentic players
              </PrimaryButton>
              {playersData?.addPlayers && (
                <Text color={colors.grey400}>
                  {playersData.addPlayers.message}
                </Text>
              )}
              {(syncPlayersLoading ||
                dataSyncStatus?.getDataSyncStatus.players.status ===
                  Status.IN_PROGRESS) && (
                <Text
                  css={css`
                    word-break: break-all;
                  `}
                >
                  This could take a while, go for a walk or something...
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* List of all leagues with checkbox for league selection */}
      <Flex column gap={8}>
        <Text italic>Select leagues to sync players from</Text>
        <Flex wrap gap={8}>
          {Object.entries(LEAGUES).map(([league, id]) => (
            <Flex column={false} key={league} gap={8}>
              <input
                type="checkbox"
                id={league}
                name={league}
                value={league}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedLeagues([...selectedLeagues, id])
                  } else {
                    setSelectedLeagues(selectedLeagues.filter((l) => l !== id))
                  }
                }}
              />
              <Text>{league}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export const AdminQuestionsPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />
        <PageContent grow>
          <AdminNavbar activePage="questions" />

          <Content />

          <Spacer height={'huge'} />
        </PageContent>
      </AdminAccessControlComponent>
    </Layout>
  )
}
