/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { FaFireFlameCurved } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import UnknownPlayer from '../../assets/UnknownPlayer.svg'
import { Button } from '../../components/Button'
import { TextNew } from '../../components/TextNew'
import { ShiftingAvatar } from '../../components/careerPath/ShiftingAvatar'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { colors, hexToRgba } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { setTimeoutRetry } from '../../utils/util'
import {
  calculateTimeRemaining,
  datePlus,
  timeRemainingToStringClock,
} from '../Admin/Utils'
import { AvatarContextProvider } from '../Avatar/AvatarPage'
import {
  DailyCareerPathQueryResult,
  LeagueStreakQueryResult,
  useSetHasSeenStreak,
} from '../League/queries'
import { useStartCareerPathQuizMutation } from '../LeagueQuiz/queries'
import { ShimmerComponent } from './ShimmerComponent'
import { AvatarAnimated } from './careerPathStreak/AvatarAnimated'
import { ReactComponent as AvatarCheckmark } from './careerPathStreak/AvatarCheckmark.svg'
import GriezmannAvatar from './careerPathStreak/GriezmannAvatar.svg'
import HaalandAvatar from './careerPathStreak/HaalandAvatar.svg'
import KanteAvatar from './careerPathStreak/KanteAvatar.svg'
import MessiAvatar from './careerPathStreak/MessiAvatar.svg'
import RonaldoAvatar from './careerPathStreak/RonaldoAvatar.svg'
import SalahAvatar from './careerPathStreak/SalahAvatar.svg'
import SimonsAvatar from './careerPathStreak/SimonsAvatar.svg'

const DAILY_CAREER_PATH_SLUG = 'the-daily-career-path'

const coinAnimationDuration = (data: LeagueStreakQueryResult | undefined) =>
  2500 + 250 * displayedStreak(data)

const displayedStreak = (data: LeagueStreakQueryResult | undefined) => {
  const streak = data?.league?.streak ?? 0

  if (streak == 0 || streak % 7 !== 0) return streak % 7

  return data?.league?.viewerHasPlayedToday ? 7 : 0
}

const avatars = [
  SimonsAvatar,
  KanteAvatar,
  GriezmannAvatar,
  SalahAvatar,
  HaalandAvatar,
  RonaldoAvatar,
  MessiAvatar,
]

export const getNextAvailableQuiz = (
  data: DailyCareerPathQueryResult | undefined
) => {
  return data?.league?.latestStartableQuiz
}

export const DailyCareerPathStreak = ({
  data,
  lightText = false,
}: {
  data: LeagueStreakQueryResult
  lightText?: boolean
}) => {
  const leagueSlug = 'the-daily-career-path'
  const t = useT()
  const hasSeenStreak = data.league?.hasSeenStreak ?? true
  const [setHasSeenStreak] = useSetHasSeenStreak()

  useEffect(() => {
    if (hasSeenStreak) return
    setTimeout(() => {
      setHasSeenStreak({ variables: { leagueIdOrSlug: leagueSlug } })
    }, coinAnimationDuration(data))

    const updateCoinsAnimation = (delayedMs: number) => {
      const viewerCoins = document.getElementById('viewerCoins')
      if (!viewerCoins || !viewerCoins.textContent) return
      const actualCoins = parseInt(viewerCoins.textContent)
      const previousCoins = actualCoins - displayedStreak(data)
      viewerCoins.textContent = `${previousCoins}`

      setTimeout(() => {
        viewerCoins.textContent = `${actualCoins}`
      }, coinAnimationDuration(data) - delayedMs)
    }
    setTimeoutRetry(
      () => {
        const viewerCoins = document.getElementById('viewerCoins')
        const viewerCoinsMissing = !viewerCoins || !viewerCoins.textContent
        return !viewerCoinsMissing
      },
      updateCoinsAnimation,
      1000
    )
  }, [])

  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7].map((it) => {
        if (it > displayedStreak(data)) {
          return (
            <Flex
              key={it}
              column
              horizontal="center"
              gap="tiny"
              vertical="center"
            >
              <img
                css={css`
                  width: 40px;
                  height: 40px;
                  z-index: 1;
                  @media (max-width: 399px) {
                    height: 11vw;
                    width: 11vw;
                  }
                `}
                src={UnknownPlayer}
              />

              <TextNew
                size={10}
                color={`${
                  lightText
                    ? 'rgba(255, 255, 255, 0.90)'
                    : 'rgba(0, 0, 0, 0.50)'
                }`}
                css={css`
                  @media (max-width: 399px) {
                    font-size: 2.5vw;
                  }
                `}
              >
                {t(`Day`)} {it}
              </TextNew>
            </Flex>
          )
        } else {
          if (hasSeenStreak) {
            return (
              <Flex
                key={it}
                column
                horizontal="center"
                gap="tiny"
                vertical="center"
                css={css`
                  position: relative;
                `}
              >
                <img
                  css={css`
                    width: 40px;
                    height: 40px;
                    position: relative;
                    z-index: 1;
                    @media (max-width: 399px) {
                      height: 11vw;
                      width: 11vw;
                    }
                  `}
                  key={it}
                  src={avatars[it - 1]}
                />
                <AvatarCheckmark
                  css={css`
                    z-index: 2;
                    position: absolute;
                    height: 17px;
                    right: -2px;
                    bottom: 16px;
                    transition-duration: 1s;
                  `}
                />

                <TextNew
                  size={10}
                  color={`${
                    lightText
                      ? 'rgba(255, 255, 255, 0.90)'
                      : 'rgba(0, 0, 0, 0.50)'
                  }`}
                  css={css`
                    @media (max-width: 399px) {
                      font-size: 2.5vw;
                    }
                  `}
                >
                  {t(`Day`)} {it}
                </TextNew>
              </Flex>
            )
          }
          return (
            <Flex
              key={it}
              column
              horizontal="center"
              gap="tiny"
              vertical="center"
              css={css`
                position: relative;
              `}
            >
              <AvatarAnimated
                key={it}
                index={it}
                length={displayedStreak(data)}
              />
              <TextNew
                size={10}
                color={`${
                  lightText
                    ? 'rgba(255, 255, 255, 0.90)'
                    : 'rgba(0, 0, 0, 0.50)'
                }`}
                css={css`
                  @media (max-width: 399px) {
                    font-size: 2.5vw;
                  }
                `}
              >
                {t(`Day`)} {it}
              </TextNew>
            </Flex>
          )
        }
      })}
    </>
  )
}

const DailyCareerPathQuizActive = ({
  data,
}: {
  data: DailyCareerPathQueryResult
}) => {
  const t = useT()
  const navigate = useNavigate()
  const trackEvent = useGetTrackEvent()

  const { authUser, signInAsAnonymous } = useAuthContext()
  const viewerQuery = useViewerQuery()
  const [startCareerPathQuizMutation, { loading }] =
    useStartCareerPathQuizMutation()

  const userCanEdit =
    viewerQuery.data?.viewer?.isAdmin ||
    viewerQuery.data?.viewer?.isTranslater ||
    data.league?.viewerIsOwner

  useEffect(() => {
    setTimeout(() => {
      window.localStorage.setItem('hideWelcomeText', 'true')
    }, 15000)
  }, [])

  const play = async (e: any) => {
    if (loading) return
    if (!authUser) {
      await signInAsAnonymous()
    }

    const nextAvailableQuiz = getNextAvailableQuiz(data)

    const quizId = nextAvailableQuiz?.id

    if (!quizId) {
      console.log('No quiz found')
      return
    }

    if (nextAvailableQuiz.quizType === 'careerPath') {
      startCareerPathQuizMutation({
        variables: { input: { leagueQuizId: quizId } },
      }).then((result) => {
        const quizInstanceId = result.data?.startCareerPathQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId,
            leagueId: data.league?.id,
            leagueSlug: DAILY_CAREER_PATH_SLUG,
            isCareerPath: true,
            isEmbed: false,
          })
          navigate(
            `/play-quiz/${quizInstanceId}?quizType=${nextAvailableQuiz.quizType}`
          )
        }
      })
    }
    e.stopPropagation()
  }

  return (
    <Flex column>
      <button
        onClick={play}
        css={[
          buttonReset,
          css`
            cursor: pointer;
          `,
        ]}
        aria-label={'Play the daily career path quiz'}
      >
        <Flex
          css={css`
            height: 250px;
            position: relative;

            padding: 20px 15px;

            border-radius: 10px;
            background-color: ${colors.dailyCareerPathGreen};
            background: linear-gradient(
              180deg,
              ${hexToRgba('#136969', 1)} 0%,
              ${hexToRgba('#0C5252', 0.4)} 100%
            );
          `}
          column
          horizontal="center"
          gap="medium"
          vertical="space-between"
        >
          <Flex
            css={css`
              width: 100%;
              max-width: 350px;
              @media (max-width: 399px) {
                gap: 1px;
              }
            `}
            horizontal="space-between"
            gap="small"
            vertical="center"
          >
            <Flex
              css={css`
                position: absolute;
                left: 15px;
                top: 20px;
              `}
              vertical={'center'}
              column={false}
              gap={'tiny'}
            >
              <FaFireFlameCurved size={12} color={colors.white} />
              <TextNew size={8} strong italic uppercase color={colors.white}>
                {t(`Streak`)}: {data.league?.streak ?? 0}
              </TextNew>
            </Flex>
            <Flex
              css={css`
                position: absolute;
                right: 15px;
                top: 20px;
              `}
              horizontal="flex-end"
              column
            >
              <TextNew size={8} strong italic uppercase color={colors.white}>
                {data.league?.participantCount ?? 0} {t(`Players`)}
              </TextNew>
              <TextNew size={8} strong italic uppercase color={colors.white}>
                {data.league?.live ?? 0} Live
              </TextNew>
            </Flex>
          </Flex>

          <Flex
            horizontal={'center'}
            css={css`
              position: relative;
              overflow-y: visible;
              height: 70px;
              top: -75px;
            `}
          >
            <AvatarContextProvider>
              <ShiftingAvatar />
            </AvatarContextProvider>
          </Flex>

          <TextNew
            css={css`
              font-size: 45px;
              text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.45);
            `}
            italic
            condensed
            color={colors.white}
          >
            {t(`Who Am I?`)}
          </TextNew>

          <Flex
            css={css`
              border-radius: 99px;
              padding: 10px 40px;
              background-color: ${colors.yellow400};
              border: 0;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            `}
          >
            <TextNew
              size={24}
              condensed
              italic
              strong
              css={css`
                white-space: nowrap;
                text-transform: capitalize;
              `}
            >
              {t(`Play quiz`)}
            </TextNew>
          </Flex>
        </Flex>
      </button>
      {userCanEdit && (
        <button
          css={buttonReset}
          onClick={() => {
            const dailySlug = 'the-daily-career-path'
            navigate(`/league/${dailySlug}`)
          }}
        >
          <EditIndicator />
        </button>
      )}
    </Flex>
  )
}

const DailyQuizWaiting = ({
  data,
  timeRemaining,
}: {
  data: DailyCareerPathQueryResult
  timeRemaining: string
}) => {
  const t = useT()
  const { authUser, signInAsAnonymous } = useAuthContext()
  const trackEvent = useGetTrackEvent()

  const viewerQuery = useViewerQuery()

  const navigate = useNavigate()

  const [startCareerPathQuizMutation, { loading }] =
    useStartCareerPathQuizMutation()

  const userCanEdit =
    viewerQuery.data?.viewer?.isAdmin ||
    viewerQuery.data?.viewer?.isTranslater ||
    data.league?.viewerIsOwner

  const handleClickComponent = async (e: any) => {
    if (loading) return
    if (data.league?.latestStartableQuiz) {
      if (!authUser) {
        await signInAsAnonymous()
      }

      const nextAvailableQuiz = getNextAvailableQuiz(data)

      const quizId = nextAvailableQuiz?.id

      if (!quizId) {
        console.log('No quiz found')
        return
      }

      if (nextAvailableQuiz.quizType === 'careerPath') {
        startCareerPathQuizMutation({
          variables: { input: { leagueQuizId: quizId } },
        }).then((result) => {
          const quizInstanceId = result.data?.startCareerPathQuiz.id
          if (quizInstanceId) {
            trackEvent(AnalyticsEventType.StartQuiz, {
              quizId,
              leagueId: data.league?.id,
              leagueSlug: DAILY_CAREER_PATH_SLUG,
              isCareerPath: true,
              isEmbed: false,
            })
            navigate(
              `/play-quiz/${quizInstanceId}?quizType=${nextAvailableQuiz.quizType}`
            )
          }
        })
      }
      e.stopPropagation()
    } else {
      const dailySlug = 'the-daily-career-path'
      navigate(`/league/${dailySlug}`)
    }
  }

  return (
    <Button
      css={[
        buttonReset,
        css`
          width: 100%;
        `,
      ]}
      disabled={!userCanEdit && !data.league?.latestStartableQuiz}
      onClick={handleClickComponent}
    >
      <Flex
        css={css`
          height: 120px;
          position: relative;
          padding: 12px 15px;
          border-radius: 10px;
          background-color: ${colors.dailyCareerPathGreen};
          background: linear-gradient(
            180deg,
            ${hexToRgba('#136969', 1)} 0%,
            ${hexToRgba('#0C5252', 0.8)} 100%
          );
          @media (min-width: 720px) {
            height: 80px;
          }
        `}
        column
      >
        <Flex
          css={css`
            width: 100%;
            height: 100%;
            @media (max-width: 720px) {
              justify-content: space-between;
            }
          `}
          column
          horizontal="center"
          vertical="center"
        >
          <Flex
            css={css`
              width: 100%;
              max-width: 350px;

              @media (min-width: 720px) {
                position: absolute;
              }
              @media (max-width: 399px) {
                gap: 1px;
              }
            `}
            horizontal="space-between"
            gap="small"
            vertical="flex-start"
          >
            <DailyCareerPathStreak data={data} lightText={true} />
          </Flex>
          <Flex
            css={css`
              width: 100%;
            `}
            vertical="center"
            horizontal="space-between"
          >
            <TextNew
              css={css`
                font-size: 25px;
                text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.45);
              `}
              italic
              condensed
              color={colors.white}
            >
              {t(`Who Am I?`)}
            </TextNew>
            {data.league?.latestStartableQuiz ? (
              <Flex
                css={css`
                  background-color: ${colors.yellow400};
                  border-radius: 99px;
                  padding: 4px 30px;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                `}
                horizontal={'center'}
                vertical={'center'}
                gap={'tiny'}
              >
                <TextNew size={18} italic strong condensed color={colors.black}>
                  {t('Play')}
                </TextNew>
                <FaArrowRight
                  size={12}
                  color={colors.black}
                  css={css`
                    margin-top: 2px;
                  `}
                />
              </Flex>
            ) : (
              <Flex
                css={css`
                  @media (min-width: 720px) {
                    flex-direction: column;
                    justify-items: center;
                    gap: 0px;
                  }
                `}
                vertical="flex-end"
                gap="small"
              >
                <TextNew
                  css={css`
                    margin-bottom: 2px;
                    text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.45);
                    @media (min-width: 720px) {
                      margin-bottom: -2px;
                    }
                  `}
                  size={10}
                  strong
                  italic
                  uppercase
                  color={'rgba(255, 255, 255, 0.90)'}
                >
                  {t(`Next game in`)}
                </TextNew>
                {
                  <TextNew
                    size={26}
                    italic
                    condensed
                    color={'rgba(255, 255, 255, 0.90)'}
                    css={css`
                      text-shadow: 1px 3px 0px rgba(0, 0, 0, 0.45);
                    `}
                  >
                    {timeRemaining}
                  </TextNew>
                }
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {userCanEdit && <EditIndicator />}
    </Button>
  )
}

const EditIndicator = () => {
  const t = useT()
  return (
    <Flex
      horizontal="center"
      css={css`
        margin: 0 auto;
        background-color: ${colors.dailyCareerPathGreen};
        width: fit-content;
        padding: 8px 30px;
        border-radius: 0 0 10px 10px;
      `}
    >
      <TextNew size={12} italic color={colors.white}>
        {t(`Edit`)}
      </TextNew>
    </Flex>
  )
}

export const DailyCareerPathQuiz = ({
  data,
  loading,
  className,
}: {
  data?: DailyCareerPathQueryResult
  loading?: boolean
  className?: string
}) => {
  const _timeRemaining = () => {
    const nextAvailableQuiz = getNextAvailableQuiz(data)
    let endDate = datePlus(new Date(), { hours: 23, minutes: 59, seconds: 59 })
    if (!nextAvailableQuiz) {
      // If there is no quiz today, set the end date to tomorrow at 00:00
      // Create a new date object for tomorrow, timezone CET
      let tomorrow = new Date()

      // Increment the date by one day
      tomorrow.setDate(new Date().getDate() + 1)

      // Set the time to 00:00:00
      tomorrow.setHours(0, 0, 0, 0)

      endDate = tomorrow
    }

    // Convert now and endDate to CET
    const nowCET = moment().tz('Europe/Oslo')

    // Perform the calculation in moment and convert to Date if needed
    const nowDate = new Date()
    nowDate.setHours(nowCET.hours(), nowCET.minutes(), nowCET.seconds())

    return timeRemainingToStringClock(calculateTimeRemaining(nowDate, endDate))
  }

  const [timeRemaining, setTimeRemaining] = useState(_timeRemaining())

  useEffect(() => {
    setTimeRemaining(_timeRemaining())
  }, [])

  useInterval(() => {
    setTimeRemaining(_timeRemaining())
  }, 1000)

  if (loading) {
    return (
      <ShimmerComponent
        backgroundColor={colors.dailyCareerPathGreen}
        css={css`
          border-radius: 10px;
        `}
        className={className}
      />
    )
  }

  if (!data) {
    return null
  }

  const nextAvailableQuiz = getNextAvailableQuiz(data)

  const isWaiting =
    !nextAvailableQuiz || !data.league || data.league?.viewerHasPlayedToday

  return (
    <div className={className}>
      {isWaiting ? (
        <DailyQuizWaiting data={data} timeRemaining={timeRemaining} />
      ) : (
        <DailyCareerPathQuizActive data={data} />
      )}
    </div>
  )
}
