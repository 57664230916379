/** @jsxImportSource @emotion/react */

import { gql, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import {
  LeagueQuizCard,
  LeagueQuizMinimalWithLeague,
} from '../Challenge/components/LeagueQuizCard'
import { ChallengeGroup } from '../Challenge/queries'
import { User } from '../Profile/queries'
import { ChallengeMatchListComponent } from './components/ChallengeMatchListComponent'

type ChallengeMatchStatus =
  | 'pendingAll'
  | 'pendingViewer'
  | 'pendingOther'
  | 'activeViewer'
  | 'completed'

export type ChallengeMatch = {
  id: string
  challengeGroup: ChallengeGroup
  leagueQuiz: LeagueQuizMinimalWithLeague
  homeUser: User
  awayUser: User
  status: ChallengeMatchStatus
}

export const challengeMatchFragment = gql`
  fragment ChallengeMatchFragment on ChallengeMatch {
    id
    challengeGroup {
      id
    }
    leagueQuiz {
      id
      title
      league {
        id
        title
        frequency
        viewerIsSubscribed
      }
      imageUrl
      participantCount
      questionsCount
    }
    homeUser {
      id
      slug
      username
      displayName
      avatarData
    }
    awayUser {
      id
      slug
      username
      displayName
      avatarData
    }
    status
  }
`

//challengeMatch(id: ID!): ChallengeMatch
const challengeMatchQuery = gql`
  query challengeMatch($id: ID!) {
    challengeMatch(id: $id) {
      ...ChallengeMatchFragment
    }
  }
  ${challengeMatchFragment}
`

const useChallengeMatchQuery = (id: string) => {
  return useQuery<{ challengeMatch: ChallengeMatch }>(challengeMatchQuery, {
    variables: {
      id,
    },
  })
}

const Content = ({ challengeMatchId }: { challengeMatchId: string }) => {
  const matchQuery = useChallengeMatchQuery(challengeMatchId)

  if (matchQuery.loading) {
    return <Loader center />
  }

  if (!matchQuery.data?.challengeMatch) {
    return (
      <DefaultError sentryErrorMessage="ViewChallengeMatchPage | challengeMatch not found" />
    )
  }

  const match = matchQuery.data.challengeMatch

  return (
    <Flex column grow css={[margin.vertical('huge')]} gap={'huge'}>
      <LeagueQuizCard quiz={match.leagueQuiz} />
      <ChallengeMatchListComponent challengeMatch={match} />
    </Flex>
  )
}

export const ViewChallengeMatchPage = () => {
  const challengeMatchId = useParams<{ challengeMatchId: string }>()
    .challengeMatchId

  if (!challengeMatchId) {
    return (
      <DefaultError sentryErrorMessage="ViewChallengeMatchPage | useParams could not find challengeMatchId" />
    )
  }

  return (
    <Layout
      backgroundColor={colors.grey100}
      css={css`
        height: 100%;
      `}
    >
      <PageContent>
        <Content challengeMatchId={challengeMatchId} />
      </PageContent>
    </Layout>
  )
}
