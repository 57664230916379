/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { QuizType } from '../../views/Generate/GeneratePage'
import { Badge } from '../../views/League/queries'
import {
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  CareerPathQuizInstance,
  CompletedCareerPathQuestion,
  CompletedQuestion,
  LeagueQuizInstance,
  LeagueQuizInstanceStatus,
  useCheckAchievedBadgesMutation,
  useNextCareerPathQuestionMutation,
  useNextQuestionMutation,
} from '../../views/LeagueQuiz/queries'
import { userProfileQuery } from '../../views/Profile/queries'
import { ActiveCareerPathQuestion } from '../careerPath/ActiveCareerPathQuestion'
import { FinishedCareerPathDaily } from '../careerPath/FinishedCareerPathDaily'
import { LastCompletedCareerPathQuestion } from '../careerPath/LastCompletedCareerPathQuestion'
import { ReadQuestionScreen } from './ActiveQuestion'
import FinishedEmbedQuiz from './embed/FinishedEmbedQuiz'
import { FinishedQuizDaily } from './FinishedQuizDaily'
import { FinishedQuizV2 } from './FinishedQuizV2'
import { LastCompletedQuestion } from './LastCompletedQuestion'
import { QuizSplash } from './QuizSplash'
import { QuizStartScreen } from './QuizStartScreen'

export const LeagueQuiz = ({
  leagueQuizInstance,
  colorTheme = 'red',
  clubLeagueSubscriptionId,
  isEmbed,
}: {
  leagueQuizInstance: LeagueQuizInstance | CareerPathQuizInstance
  colorTheme?: 'white' | 'red' | 'green'
  clubLeagueSubscriptionId?: string
  isEmbed: boolean
}) => {
  const leagueQuiz = leagueQuizInstance.leagueQuiz
  const completedQuestions = leagueQuizInstance.completedQuestions
  const activeQuestion = leagueQuizInstance.activeQuestion

  const trackEvent = useGetTrackEvent()

  const [nextQuestionMutation, { loading: nextQuestionLoading }] =
    useNextQuestionMutation()

  const [
    nextCareerPathQuestionMutation,
    { loading: nextCareerPathQuestionLoading },
  ] = useNextCareerPathQuestionMutation()

  const [badgesMutation] = useCheckAchievedBadgesMutation()
  const [newBadges, setNewBadges] = useState<Badge[]>([])

  const client = useApolloClient()

  const [finished, setFinished] = useState<boolean>(
    leagueQuizInstance.status === LeagueQuizInstanceStatus.completed
  )

  // This determines if the play count animation should be played | default: false
  const [playCountAnimation, setPlayCountAnimation] = useState<boolean>(false)

  const { quizState, setQuizState, setIsExpandedAdActive, clubId } =
    useLeagueQuizContext()
  const allQuestionsAnswered =
    completedQuestions.length === leagueQuiz.questionsCount

  const lastCompletedQuestion =
    !activeQuestion && completedQuestions.length > 0
      ? completedQuestions[completedQuestions.length - 1]
      : null

  useEffect(() => {
    if (!activeQuestion && !lastCompletedQuestion) {
      setIsExpandedAdActive(false)
      setQuizState(QuizState.START_SCREEN)
    } else {
      setQuizState(QuizState.ACTIVE_QUIZ)
    }
  }, [activeQuestion, lastCompletedQuestion])

  // If the quiz is completed immediately, show the finished screen
  useEffect(() => {
    if (leagueQuizInstance.status === LeagueQuizInstanceStatus.completed) {
      setQuizState(QuizState.FINISHED)
    }
  }, [])

  useEffect(() => {
    if (finished) {
      badgesMutation({
        variables: {
          input: { leagueQuizInstanceId: leagueQuizInstance.id },
        },
      }).then((r) => {
        if (r) {
          setNewBadges(r.data?.checkBadgeAchieved || [])
        }

        client.refetchQueries({ include: ['active', userProfileQuery] })
      })
    }
  }, [finished, badgesMutation, leagueQuizInstance.id])

  const nextQuestion = useCallback(() => {
    return nextQuestionMutation({
      variables: {
        input: { leagueQuizInstanceId: leagueQuizInstance.id },
      },
    })
  }, [leagueQuizInstance.id, nextQuestionMutation])

  const nextCareerPathQuestion = useCallback(() => {
    return nextCareerPathQuestionMutation({
      variables: {
        input: { leagueQuizInstanceId: leagueQuizInstance.id },
      },
    })
  }, [leagueQuizInstance.id, nextCareerPathQuestionMutation])

  const triggerCalculation = () => {
    setFinished(true)
    setIsExpandedAdActive(true)
    setPlayCountAnimation(true)
    setQuizState(QuizState.FINISHED)
  }

  const isDaily = leagueQuiz.league.slug == 'the-daily'

  const isDailyCareerPath = leagueQuiz.league.slug == 'the-daily-career-path'

  useEffect(() => {
    if (
      allQuestionsAnswered &&
      isDailyCareerPath &&
      leagueQuizInstance.status !== 'completed'
    ) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId: leagueQuiz.league.id,
        leagueQuizId: leagueQuiz.id,
        isEmbed,
        isCareerPath: true,
        clubId,
      })
    }
  }, [
    allQuestionsAnswered,
    leagueQuiz.id,
    leagueQuiz.league.id,
    isDailyCareerPath,
  ])

  if (quizState === QuizState.START_SCREEN) {
    return (
      <QuizStartScreen
        leagueQuiz={leagueQuizInstance.leagueQuiz}
        isEmbed={isEmbed}
      />
    )
  } else if (!activeQuestion && !lastCompletedQuestion) {
    const nextQuestionCallback =
      leagueQuizInstance.quizType === QuizType.CAREERPATH
        ? nextCareerPathQuestion
        : nextQuestion
    return (
      <QuizSplash
        nextQuestion={nextQuestionCallback}
        colorTheme={colorTheme}
        nextQuestionLoading={
          leagueQuizInstance.quizType === QuizType.CAREERPATH
            ? nextCareerPathQuestionLoading
            : nextQuestionLoading
        }
      />
    )
  } else if (finished && isEmbed) {
    return (
      <FinishedEmbedQuiz
        leagueQuizInstance={leagueQuizInstance}
        colorTheme={colorTheme}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        playAnimation={playCountAnimation}
        isCareerPath={leagueQuizInstance.quizType === QuizType.CAREERPATH}
      />
    )
  } else if (
    finished &&
    isDaily &&
    leagueQuizInstance.quizType !== QuizType.CAREERPATH
  ) {
    return (
      <FinishedQuizDaily
        leagueQuizInstance={leagueQuizInstance as LeagueQuizInstance}
        playAnimation={playCountAnimation}
        newBadges={newBadges}
      />
    )
  } else if (
    finished &&
    isDailyCareerPath &&
    leagueQuizInstance.quizType === QuizType.CAREERPATH
  ) {
    return (
      <FinishedCareerPathDaily
        leagueQuizInstance={leagueQuizInstance as CareerPathQuizInstance}
        playAnimation={playCountAnimation}
        newBadges={newBadges}
      />
    )
  } else if (finished) {
    return (
      <FinishedQuizV2
        leagueQuizInstance={leagueQuizInstance}
        playAnimation={playCountAnimation}
        newBadges={newBadges}
      />
    )
  } else if (activeQuestion) {
    if (
      leagueQuizInstance.quizType === QuizType.CAREERPATH &&
      'team' in activeQuestion
    ) {
      return (
        <ActiveCareerPathQuestion
          leagueQuiz={leagueQuiz}
          numCompletedQuestions={
            completedQuestions.length + (activeQuestion ? 1 : 0)
          }
          activeQuestion={activeQuestion}
          quizInstanceId={leagueQuizInstance.id}
          isEmbed={isEmbed}
        />
      )
    } else if ('text' in activeQuestion) {
      return (
        <ReadQuestionScreen
          key={activeQuestion.id}
          leagueQuiz={leagueQuiz}
          numCompletedQuestions={
            completedQuestions.length + (activeQuestion ? 1 : 0)
          }
          activeQuestion={activeQuestion}
          quizInstanceId={leagueQuizInstance.id}
          colorTheme={colorTheme}
          isEmbed={isEmbed}
        />
      )
    }
  } else if (lastCompletedQuestion) {
    if ('team' in lastCompletedQuestion) {
      if (isDailyCareerPath && allQuestionsAnswered) {
        triggerCalculation()
      }
      return (
        <LastCompletedCareerPathQuestion
          leagueQuiz={leagueQuiz}
          completedQuestions={
            completedQuestions as CompletedCareerPathQuestion[]
          }
          lastCompletedQuestion={lastCompletedQuestion}
          allQuestionsAnswered={allQuestionsAnswered}
          triggerCalculation={triggerCalculation}
          nextQuestionLoading={nextCareerPathQuestionLoading}
          nextQuestion={nextCareerPathQuestion}
          isEmbed={isEmbed}
        />
      )
    } else if ('text' in lastCompletedQuestion) {
      return (
        <LastCompletedQuestion
          leagueQuiz={leagueQuiz}
          completedQuestions={completedQuestions as CompletedQuestion[]}
          lastCompletedQuestion={lastCompletedQuestion}
          allQuestionsAnswered={allQuestionsAnswered}
          triggerCalculation={triggerCalculation}
          nextQuestionLoading={nextQuestionLoading}
          nextQuestion={nextQuestion}
          colorTheme={colorTheme}
          isEmbed={isEmbed}
          clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        />
      )
    }
  }
  return null
}
