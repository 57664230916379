import { Selector } from '../Selector'

import { ReactComponent as ChestTIL } from './ChestTIL.svg'
import { ReactComponent as ChestThai } from './ChestThai.svg'

const Chest0 = () => <></>

export const chestStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    premium: boolean
  }
} = {
  ChestA: { svg: <Chest0 />, premium: false },
  ChestB: { svg: <Chest0 />, premium: false },
  ChestC: { svg: <Chest0 />, premium: false },
  ChestD: { svg: <Chest0 />, premium: false },
  ChestE: { svg: <Chest0 />, premium: false },
  ChestF: { svg: <Chest0 />, premium: false },
  ChestG: { svg: <Chest0 />, premium: false },
  ChestH: { svg: <Chest0 />, premium: false },
  ChestI: { svg: <Chest0 />, premium: false },
  ChestJ: { svg: <Chest0 />, premium: false },
  ChestK: { svg: <Chest0 />, premium: false },
  ChestL: { svg: <Chest0 />, premium: false },
  ChestM: { svg: <Chest0 />, premium: false },
  ChestN: { svg: <Chest0 />, premium: false },
  ChestO: { svg: <Chest0 />, premium: false },
  ChestP: { svg: <Chest0 />, premium: false },
  ChestTIL: { svg: <ChestTIL />, premium: false }, // It is premium, but this check is unused anyway. Check options/chest for the place where the check is used
  ChestThai: { svg: <ChestThai />, premium: false }, // It is premium, but this check is unused anyway. Check options/chest for the place where the check is used
}

export const Chest = () => {
  return <Selector selectedOptionKey={'chest'} styles={chestStyles} />
}
