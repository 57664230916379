/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { RecommendedLeagueQuiz } from '../../views/Home/queries'
import { ShareQuizButton } from '../../views/League/LeagueQuizItem'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton, SecondaryButton } from '../Button'
import { Heading } from '../Heading'
import { Flex } from '../layout/Flex'

export const PlayMoreQuizComponent = ({
  quiz,
  isEmbed = false,
  hideShareButton = false,
}: {
  quiz: RecommendedLeagueQuiz
  isEmbed?: boolean
  hideShareButton?: boolean
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const trackEvent = useGetTrackEvent()
  const t = useT()

  const [startLeagueQuizMutation] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const { authUser, signInAsAnonymous } = useAuthContext()

  const StartOrContinueQuiz = () => {
    return quiz.viewerCanStartQuiz ? (
      <PrimaryButton
        css={css`
          flex-grow: 1;
        `}
        disabled={loading}
        onClick={async () => {
          setLoading(true)
          if (!authUser) {
            await signInAsAnonymous()
          }
          if (isEmbed) {
            trackEvent(AnalyticsEventType.EmbedPlayMoreInEmbedQuiz, {
              type: `${quiz.league.title} - ${quiz.title}`,
            })
            navigate(`/embed/league/${quiz.league.id}/${quiz.id}`, {
              replace: true,
            })
            return
          }

          // If quiz is a career path quiz, start career path quiz
          if (quiz.quizType === 'careerPath') {
            startCareerPathQuizMutation({
              variables: { input: { leagueQuizId: quiz.id } },
            }).then((result) => {
              const quizInstanceId = result.data?.startCareerPathQuiz.id
              if (quizInstanceId) {
                trackEvent(AnalyticsEventType.StartQuiz, {
                  quizId: quiz.id,
                  leagueId: quiz.league.id,
                  isCareerPath: true,
                  isEmbed,
                })
                navigate(
                  `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`
                )
              }
            })
            return
          }

          startLeagueQuizMutation({
            variables: { input: { leagueQuizId: quiz.id } },
          }).then((result) => {
            const quizInstanceId = result.data?.startLeagueQuiz.id
            if (quizInstanceId) {
              trackEvent(AnalyticsEventType.StartQuiz, {
                quizId: quiz.id,
                leagueId: quiz.league.id,
                isEmbed,
              })
              trackEvent(AnalyticsEventType.PlayMoreQuiz, {
                type: `${quiz.league.title} - ${quiz.title}`,
              })
              navigate(`/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`)
            }
          })
        }}
      >
        {t('Start')}
      </PrimaryButton>
    ) : quiz.viewerData.activeQuizInstance?.id ? (
      <PrimaryButton
        css={css`
          flex-grow: 1;
        `}
        onClick={() =>
          //Continue quiz, so dont track start quiz
          navigate(`/play-quiz/${quiz.viewerData.activeQuizInstance?.id}`)
        }
      >
        {t('Continue quiz')}
      </PrimaryButton>
    ) : quiz.viewerData.hasCompletedQuiz && quiz.viewerData.result ? (
      <SecondaryButton
        css={css`
          flex-grow: 1;
        `}
        onClick={() =>
          navigate(`/play-quiz/${quiz.viewerData.result?.leagueQuizInstanceId}`)
        }
      >
        {t('Results')}
      </SecondaryButton>
    ) : null
  }

  return (
    <Flex
      column
      vertical="space-between"
      key={quiz.id}
      css={css`
        flex-shrink: 0;
        border-radius: 8px;
        width: 260px;
        height: ${isEmbed ? 140 : 190}px;
        padding: 8px 12px;
        background-color: ${colors.white};
      `}
    >
      <div>
        <Heading level={isEmbed ? 6 : 5}>{quiz.league.title}</Heading>
        <Heading level={isEmbed ? 5 : 4}>{quiz.title}</Heading>
      </div>
      <Flex vertical="center" gap="small">
        <StartOrContinueQuiz />
        {!hideShareButton && (
          <ShareQuizButton
            slug={quiz.slug}
            quizId={quiz.id}
            leagueId={quiz.league.id}
            userFinishedInstanceId={
              quiz.viewerData.result?.leagueQuizInstanceId
            }
            quizType={quiz.quizType}
          />
        )}
      </Flex>
    </Flex>
  )
}
