import { useEffect, useState } from 'react'

export const useIsEmbed = () => {
  const [isEmbed, setIsEmbed] = useState(false)

  useEffect(() => {
    setIsEmbed(window.location.pathname.includes('embed'))
  }, [window.location.pathname])

  return isEmbed
}
