/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { PoweredByFcQuiz } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import DEFAULT_BACKGROUND from '../../components/leagueQuiz/assets/football-background.webp'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'

export const EmbedErrorPage = ({
  reason,
  clubId,
}: {
  reason?: string
  clubId?: string
}) => {
  const t = useT()

  const trackEvent = useGetTrackEvent()

  trackEvent(AnalyticsEventType.EmbedError, { reason: reason, clubId: clubId })
  return (
    <Flex
      column
      horizontal="center"
      vertical="space-between"
      css={css`
        height: 100vh;
        padding-bottom: 20px;
      `}
    >
      <Text size="tiny" css={margin.top('huge')}>
        {t('Something went wrong')}...
      </Text>
      <a
        href="/"
        target="_blank"
        rel="noreferrer"
        css={css`
          text-align: center;
        `}
      >
        <PrimaryButton
          css={css`
            background-color: black;
            color: white;
            width: fit-content;
            padding: 5px 20px;
            margin: 10px auto;
          `}
        >
          {t('Play in the app!')}
        </PrimaryButton>
      </a>
      <PoweredByFcQuiz />
    </Flex>
  )
}

export const EmbedLoadingPage = () => {
  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={[
        css`
          margin: 80px auto;
        `,
      ]}
    >
      <Loader variant="normal" />
    </Flex>
  )
}

export const Background = ({
  backgroundImageUrl,
}: {
  backgroundImageUrl?: string
}) => {
  const displayImage = backgroundImageUrl ?? DEFAULT_BACKGROUND

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 0;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 1px;
          background-image: url(${displayImage});
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          z-index: -10;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(rgba(0, 0, 0, 0.2), #000);
        `}
      />
    </div>
  )
}
