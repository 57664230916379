/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { rootWebAppUrl } from '../../config/config'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { getCurrentLocale, Locale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import { ChallengeNavItem } from '../../views/Challenge/ChallengeGroupPage'
import { LanguagePicker } from '../../views/Clubs/ClubProfilePage/EmbedCodeModal'
import { PrimaryButton, SecondaryButton } from '../Button'
import { ChallengeButton } from '../challenge/ChallengeButton'
import { Heading } from '../Heading'
import { Flex } from '../layout/Flex'
import { Modal } from '../modal/Modal'
import { FcQuizQrCode } from '../QrCodeComponent'
import { ShareLinkComponent } from '../ShareLinkComponent'
import { Text } from '../Text'
import { ErrorMessage, TextInput } from '../TextInput'
import { TextNew } from '../TextNew'

export const ShareQuizModal = ({
  slug,
  quizId,
  leagueId,
  closeModal,
  userFinishedInstanceId,
  quizType = 'text',
}: {
  slug: string
  quizId: string
  leagueId: string
  closeModal: () => void
  userFinishedInstanceId?: string
  quizType?: string
}) => {
  const t = useT()

  const [selectedTab, setSelectedTab] = useState<
    'link' | 'embed' | 'challenge'
  >('link')

  const shareUrl = `${window.location.origin}/quiz/${slug}`

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), 3000)
    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Modal
      heading="Share"
      hideHeading
      closeModal={closeModal}
      styling={css`
        background-color: ${colors.white};
        text-align: center;
      `}
      textColor={colors.black}
    >
      <Flex
        column
        css={css`
          text-align: center;
          max-width: 590px;
          margin: 0 auto;
          min-height: 400px;
        `}
      >
        <Flex gap="medium">
          <ChallengeNavItem
            title={t('Share')}
            selected={selectedTab === 'link'}
            onClick={() => setSelectedTab('link')}
          />
          {userFinishedInstanceId && quizType != 'careerPath' && (
            <ChallengeNavItem
              title={t('Challenge a friend')}
              selected={selectedTab === 'challenge'}
              onClick={() => setSelectedTab('challenge')}
            />
          )}
          <ChallengeNavItem
            title={'Embed'}
            selected={selectedTab === 'embed'}
            onClick={() => setSelectedTab('embed')}
          />
        </Flex>
        <Spacer height="large" />
        {selectedTab === 'link' && (
          <Flex
            column
            horizontal="center"
            gap="medium"
            css={css`
              margin: 0 auto;
              max-width: 300px;
            `}
          >
            <div
              css={css`
                width: 240px;
              `}
            >
              <FcQuizQrCode url={shareUrl} />
            </div>
            <ShareLinkComponent url={shareUrl} />
          </Flex>
        )}

        {userFinishedInstanceId && selectedTab === 'challenge' && (
          <>
            <Spacer height={80} />
            <ChallengeButton instanceId={userFinishedInstanceId} />
          </>
        )}

        {selectedTab === 'embed' && (
          <EmbedSubPage
            leagueId={leagueId}
            quizId={quizId}
            copied={copied}
            setCopied={setCopied}
            quizType={quizType}
          />
        )}
      </Flex>
    </Modal>
  )
}

const EmbedSubPage = ({
  leagueId,
  quizId,
  copied,
  setCopied,
  quizType = 'text',
}: {
  leagueId: string
  quizId?: string
  copied: boolean
  setCopied: (copied: boolean) => void
  quizType?: string
}) => {
  const t = useT()

  const trackEvent = useGetTrackEvent()

  const [userEmbedDomain, setUserEmbedDomain] = useState<string | null>(
    window.localStorage.getItem('userEmbedDomain')
  )

  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), 3000)
    return () => clearTimeout(timeout)
  }, [copied])

  const language = getCurrentLocale()
  const [locale, onLocale] = useState<Locale>(language ?? 'no')

  const getEmbedPageLink = () => {
    return `${rootWebAppUrl()}/${locale}/embed/league/${leagueId}/${
      quizId ?? 'current'
    }?quizType=${quizType}${
      userEmbedDomain ? `&domain=${userEmbedDomain}` : ''
    }`
  }

  const embedCode = `
<iframe 
  src="${getEmbedPageLink()}"
  width="100%"
  height="550"
  frameBorder="0">
</iframe>`

  // only hostname, ie fcquiz.app
  const validUrl = (str: string) => {
    return (
      str.includes('.') &&
      !str.includes(' ') &&
      !str.includes('http') &&
      !str.includes('/') &&
      !str.includes('www')
    )
  }

  const form = useForm({
    initialValues: {
      domain: userEmbedDomain ?? '',
    },

    onSubmit: async ({ values, setSubmitError }) => {
      if (!validUrl(values.domain)) {
        setSubmitError(
          'The hostname is invalid, do not include http://, www. spaces or /'
        )
        return
      }

      console.log('values', values)
      window.localStorage.setItem('userEmbedDomain', values.domain)
      setUserEmbedDomain(values.domain)
    },
  })

  if (!userEmbedDomain) {
    return (
      <Flex column>
        <Heading level={5} css={margin.vertical()}>
          {t('Where will the quiz be embedded?')}
        </Heading>
        <Text size="small" css={margin.vertical()}>
          {t(
            'For the quiz to work as intended, we need to understand where you plan to use it.'
          )}
        </Text>
        <form
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
          onSubmit={(event) => {
            event.preventDefault()
            form.submitForm()
          }}
        >
          <TextInput
            id="email"
            name="email"
            label={
              <Text
                size="small"
                css={
                  (margin.bottom('tiny'),
                  css`
                    text-align: left;
                  `)
                }
              >
                {t('Website domain')}
              </Text>
            }
            placeholder="example.com"
            autoComplete="username"
            type="text"
            value={form.values.domain}
            onValue={(value) => form.setValue('domain', value.trim())}
            error={form.submitAttempted && form.fieldErrors.domain}
            css={margin.top('medium')}
          />
          <PrimaryButton
            variant="black"
            type="submit"
            loading={form.submitting}
          >
            {t('Save')}
          </PrimaryButton>
          {form.submitError && (
            <div
              css={[
                margin.top(),
                css`
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                `,
              ]}
            >
              <ErrorMessage>{form.submitError}</ErrorMessage>
              <Text size="tiny" css={margin.vertical()}>
                {
                  'If the embed will be used in a Android or iOS app, please provide the website of the app owner.'
                }
              </Text>
            </div>
          )}
        </form>
      </Flex>
    )
  }

  return (
    <>
      <Flex css={margin.vertical()}>
        <LanguagePicker locale={locale} onLocale={onLocale} />
      </Flex>
      <Flex vertical="center" horizontal="space-between">
        <Heading level={5} css={margin.vertical()}>
          {t('Code')}
        </Heading>

        <Flex horizontal="center" gap="small">
          {copied && <Text size="small">{t('Copied!')}</Text>}
          <SecondaryButton
            size="small"
            onClick={() => {
              trackEvent(AnalyticsEventType.DirectEmbedCodeCopied, {
                leagueId,
                quizId,
                domain: userEmbedDomain,
              })
              navigator.clipboard.writeText(embedCode)
              setCopied(true)
            }}
          >
            {t('Copy embed code')}
          </SecondaryButton>
        </Flex>
      </Flex>
      <pre
        css={css`
          background: ${colors.grey100};
          padding: 16px;
          font-family: monospace;
          border-radius: 2px;
          font-size: 14px;
          overflow: auto;
          text-align: left;
        `}
      >
        {embedCode}
      </pre>

      <Spacer height="large" />

      <Flex horizontal="space-between" vertical="center">
        <TextNew size={'small'}>{`Your domain: ${userEmbedDomain}`}</TextNew>
        <SecondaryButton
          size="small"
          onClick={() => {
            window.localStorage.removeItem('userEmbedDomain')
            setUserEmbedDomain(null)
          }}
        >
          {t('Edit')}
        </SecondaryButton>
      </Flex>
      <Spacer height="large" />
      <Flex column vertical="center" horizontal="space-between">
        <Heading
          level={5}
          css={[
            margin.vertical(),
            css`
              text-align: left;
            `,
          ]}
        >
          {t('Preview')}
        </Heading>
        <iframe
          src={getEmbedPageLink()}
          width="100%"
          height="550"
          frameBorder="0"
        />
      </Flex>
    </>
  )
}
