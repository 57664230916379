/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { NavItem } from '../Home/Navbar'

export const AdminNavbar = ({
  activePage,
  className,
}: {
  activePage:
    | 'index'
    | 'email'
    | 'push'
    | 'clubs'
    | 'leagues'
    | 'questions'
    | 'analytics'
    | 'liveQuiz'
    | 'challenge-leagues'
    | 'loadtest'
    | 'advertisers'
    | 'predictionQuiz'
    | 'quiz'
    | 'questions'
    | 'timeless'
    | 'customerSupport'
  className?: string
}) => {
  const t = useT()

  return (
    <Flex wrap className={className} gap="large">
      <NavItem
        to="/admin/users"
        title={t('Users')}
        active={activePage === 'index'}
      />
      <NavItem
        to="/admin/email"
        title={t('Email')}
        active={activePage === 'email'}
      />
      <NavItem to="/admin/push" title="Push" active={activePage === 'push'} />

      <NavItem
        to="/admin/clubs"
        title="Clubs"
        active={activePage === 'clubs'}
      />
      <NavItem to="/ad-test" title="Ad test" active={false} />
      <NavItem
        to="/admin/analytics"
        title="Analytics"
        active={activePage === 'analytics'}
      />
      <NavItem
        to="/admin/live-quiz"
        title="Live Quiz"
        active={activePage === 'liveQuiz'}
      />
      <NavItem
        to="/admin/prediction-quiz"
        title="Prediction Quiz"
        active={activePage === 'predictionQuiz'}
      />
      <NavItem to="/admin/quiz" title="Quiz" active={activePage === 'quiz'} />
      <NavItem
        to="/admin/questions"
        title="Sync"
        active={activePage === 'questions'}
      />
      <NavItem
        to="/admin/timeless"
        title="Timeless"
        active={activePage === 'timeless'}
      />
      <NavItem
        to="/admin/customerSupport"
        title="Customer Support"
        active={activePage === 'customerSupport'}
      />
    </Flex>
  )
}
