/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { HeadingNew } from '../../components/HeadingNew'
import { ClubLogo } from '../../components/Picture'
import { AndroidOrIosButton } from '../../components/StoreButtons'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { useExistingQuizSession } from '../../components/clientSideQuiz/ClientSideQuiz'
import { ClientSideQuizPage } from '../../components/clientSideQuiz/ClientSideQuizPage'
import { FcQuizLogo, Logo, PoweredByFcQuiz } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useCurrentLocale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import {
  makeSanityImageUrl,
  useActiveCampaignForLeague,
  useCurrentQuizForLeague,
} from '../../lib/sanity/sanity'
import {
  CampaignImageType,
  SanityLeagueQuiz,
  SanityLeagueQuizCampaign,
} from '../../lib/sanity/types'
import { Spacer } from '../../styles/margin'
import { boxShadows, colors } from '../../styles/theme'
import { getIconAndTitle } from '../../utils/helperFunctions'
import { colorIsLight } from '../Admin/Utils'
import { useLeagueQuizContext } from '../LeagueQuiz/LeagueQuizContext'
import {
  useStartCareerPathQuizMutation,
  useViewerLeagueQuizInstance,
} from '../LeagueQuiz/queries'
import { EmbedTrackingComponent } from './EmbedTrackingComponent'
import { EmbedErrorPage, EmbedLoadingPage } from './SubPages'
import { usePublicClubLeagueQuery } from './queries'
import { PublicClubInfo } from './types'

interface QueryParams {
  domain?: string
  quizType: string
}

const getSearchParams = (quizType: string, embedDomain?: string | null) => {
  let params: QueryParams = {
    quizType: quizType,
  }

  if (embedDomain) {
    params.domain = embedDomain
  }

  const validParams: Record<string, string> = {}
  Object.keys(params).forEach((key) => {
    if (params[key as keyof QueryParams]) {
      validParams[key] = params[key as keyof QueryParams]!
    }
  })

  return validParams
}

const Loader = ({
  club,
  clubLeagueSubscriptionId,
  leagueId,
  quizId,
}: {
  club: PublicClubInfo
  clubLeagueSubscriptionId: string
  leagueId: string
  quizId?: string
}) => {
  const navigate = useNavigate()
  const { authUser, signInAsAnonymous } = useAuthContext()
  const [loading, setLoading] = useState<boolean>(false)

  const currentLocale = useCurrentLocale()

  const { data: currentQuiz } = useCurrentQuizForLeague(
    currentLocale,
    leagueId,
    quizId
  )

  const { data: campaign } = useActiveCampaignForLeague(leagueId)

  const session = useExistingQuizSession(currentQuiz?.id)

  const [activeQuizSession, setActiveQuizSession] = useState<boolean>(false)
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const viewerInstanceQuery = useViewerLeagueQuizInstance({
    quizId: currentQuiz?.id || '',
    skip: currentQuiz === undefined || authUser === null,
  })

  const trackEvent = useGetTrackEvent()

  useEffect(() => {
    if (session) {
      setActiveQuizSession(true)
    }
  }, [session])

  useEffect(() => {
    const instance = viewerInstanceQuery.data?.viewerLeagueQuizInstance

    if (!currentQuiz) {
      return
    }

    if (instance) {
      navigate(
        `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${instance.id}${
          currentQuiz.quizType === 'careerPath' ? '?quizType=careerPath' : ''
        }`
      )
    }
  }, [viewerInstanceQuery.data])

  const onPlayClick = async () => {
    if (!currentQuiz) return
    if (!authUser) {
      await signInAsAnonymous()
    }

    // CareerPath quizzes can not be played in client side
    // (yet(?))
    if (currentQuiz.quizType === 'careerPath') {
      startCareerPathQuizMutation({
        variables: { input: { leagueQuizId: currentQuiz.id } },
      }).then((result) => {
        const quizInstanceId = result.data?.startCareerPathQuiz.id
        if (quizInstanceId) {
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: currentQuiz.id,
            leagueId: currentQuiz.league.id,
            isCareerPath: true,
            isEmbed: true,
            clubLeagueSubscriptionId,
            clubId: club.id,
          })
          navigate({
            pathname: `/embed/club-league-quiz/${clubLeagueSubscriptionId}/${quizInstanceId}`,
            search: `?${createSearchParams(
              getSearchParams(currentQuiz.quizType ?? 'text')
            )}`,
          })
          setLoading(false)
        }
      })
      return
    }

    trackEvent(AnalyticsEventType.StartQuiz, {
      quizId: currentQuiz.id,
      leagueId: currentQuiz.league.id,
      clubLeagueSubscriptionId,
      isEmbed: true,
      isCareerPath: false,
      clubId: club.id,
    })

    setActiveQuizSession(true)
  }

  if (currentQuiz && activeQuizSession) {
    return (
      <ClientSideQuizPage
        quiz={currentQuiz}
        campaign={campaign}
        isEmbed
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        clubId={club.id}
      />
    )
  }

  if (campaign) {
    return (
      <ContentWithAd
        publicClub={club}
        currentQuiz={currentQuiz}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        onPlayClick={onPlayClick}
        loading={loading}
        setLoading={setLoading}
        campaign={campaign}
      />
    )
  }

  return (
    <Content
      publicClub={club}
      currentQuiz={currentQuiz}
      clubLeagueSubscriptionId={clubLeagueSubscriptionId}
      onPlayClick={onPlayClick}
      loading={loading}
      setLoading={setLoading}
    />
  )
}

const Content = ({
  publicClub,
  currentQuiz,
  clubLeagueSubscriptionId,
  onPlayClick,
  loading,
  setLoading,
}: {
  publicClub: PublicClubInfo
  currentQuiz: SanityLeagueQuiz | undefined
  clubLeagueSubscriptionId: string
  onPlayClick: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
}) => {
  const t = useT()

  const { icon: leagueIcon, title: leagueTitle } = getIconAndTitle(
    currentQuiz?.league?.title
  )

  const backgroundImageUrl =
    currentQuiz?.imageUrl || publicClub.backgroundImageUrl

  return (
    <Flex
      column
      grow
      css={[
        css`
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          background-color: white;
          padding-bottom: 20px;
        `,
        backgroundImageUrl &&
          css`
            background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
              url(${backgroundImageUrl});
            background-size: cover;
            background-position: center;
          `,
      ]}
    >
      <Flex
        column
        vertical="space-between"
        horizontal="center"
        grow
        css={css`
          width: 100%;
          height: 100%;
          min-height: 264px;
          z-index: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          padding-top: 40px;
        `}
      >
        <FcQuizLogo
          height={13}
          color={backgroundImageUrl ? colors.white : colors.black}
        />
        <Flex
          column
          css={css`
            display: flex;
            padding: 30px 25px;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin: 0 auto;
            background-color: ${publicClub.backgroundColor};
            max-width: 275px;
            border-radius: 16px;
            box-shadow: ${boxShadows.leagueQuizItem};
            @media (max-width: 400px) {
              padding: 8px 8px 16px;
            }
          `}
        >
          <p
            css={css`
              font-size: 30px;
              padding-bottom: 10px;
            `}
          >
            {leagueIcon ?? null}
          </p>
          <Text
            size="small"
            color={colorIsLight(publicClub.backgroundColor) ? 'black' : 'white'}
            css={css`
              text-align: center;
            `}
          >
            {currentQuiz?.league?.title ?? leagueTitle}
          </Text>
          <Heading
            level={3}
            looksLikeLevel={5}
            color={colorIsLight(publicClub.backgroundColor) ? 'black' : 'white'}
            css={css`
              text-align: center;
              line-height: 1.2;
              font-size: 20px;
              padding-top: 15px;
            `}
          >
            {currentQuiz?.embedPromotionalText ?? currentQuiz?.title}
          </Heading>
          <Spacer height={16} />
          <EmbedTrackingComponent
            eventType={AnalyticsEventType.EmbedLandingpage}
            eventData={{
              clubLeagueSubscriptionId,
              clubId: publicClub.id,
              leagueId: currentQuiz?.league?.id,
              quizId: currentQuiz?.id,
              campaignId: undefined,
            }}
          />
          {currentQuiz && currentQuiz.league ? (
            <PrimaryButton
              disabled={loading}
              loading={loading}
              variant={
                colorIsLight(publicClub.backgroundColor) ? 'black' : 'white'
              }
              onClick={() => {
                setLoading(true)
                onPlayClick()
              }}
              rounded
              css={css`
                width: fit-content;
                padding: 8px 40px;
                margin: 0 auto;
                min-height: 48px;
                min-width: 218px;
              `}
            >
              <TextNew
                color={
                  colorIsLight(publicClub.backgroundColor) ? 'white' : 'black'
                }
                condensed
                italic
                strong
                size="large"
                css={css`
                  font-size: 25px;
                  line-height: 36px;
                `}
              >
                {t(`Play quiz`)}
              </TextNew>
            </PrimaryButton>
          ) : (
            <a
              href={`/clubs/${publicClub.id}?clubLeagueSubscriptionId=${clubLeagueSubscriptionId}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
            >
              <PrimaryButton
                css={css`
                  background-color: black;
                  color: white;
                  width: fit-content;
                  padding: 5px 20px;
                  margin: 10px auto;
                  margin-bottom: 0;
                `}
              >
                {t('Play in the app!')}
              </PrimaryButton>
            </a>
          )}
        </Flex>
        <Flex
          horizontal="space-between"
          css={css`
            margin-top: 10px;
            width: 100%;
            background-color: ${publicClub.backgroundColor};
            height: 82px;
            display: flex;
            align-items: center;
            padding: 0 20px;
          `}
        >
          <Flex>
            <ClubLogo
              size={40}
              url={publicClub.logoUrl}
              shape={publicClub.logoShape}
            />
            <Logo
              color={
                colorIsLight(publicClub.backgroundColor) ? 'black' : 'white'
              }
              size={40}
              css={css`
                margin-left: 10px;
              `}
            />
          </Flex>
          <AndroidOrIosButton />
        </Flex>
      </Flex>
      <PoweredByFcQuiz />
    </Flex>
  )
}

const ContentWithAd = ({
  publicClub,
  currentQuiz,
  clubLeagueSubscriptionId,
  onPlayClick,
  loading,
  setLoading,
  campaign,
}: {
  publicClub: PublicClubInfo
  currentQuiz: SanityLeagueQuiz | undefined
  clubLeagueSubscriptionId: string
  onPlayClick: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
  campaign: SanityLeagueQuizCampaign
}) => {
  const t = useT()

  const { showMobileImages } = useLeagueQuizContext()

  const { title: leagueTitle } = getIconAndTitle(currentQuiz?.league?.title)

  const backgroundImageUrl =
    currentQuiz?.imageUrl || publicClub.backgroundImageUrl

  const titleImage = showMobileImages
    ? campaign.titleScreenImageMobileUrl ?? campaign.titleScreenImageUrl
    : campaign.titleScreenImageUrl ?? campaign.titleScreenImageMobileUrl

  return (
    <Flex
      column
      grow
      css={[
        css`
          position: relative;
          width: 100%;
          height: 100vh;
          align-items: center;
        `,
        backgroundImageUrl &&
          css`
            background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
              url(${backgroundImageUrl});
            background-size: cover;
            background-position: center;
          `,
      ]}
    >
      <Flex
        horizontal="flex-end"
        css={css`
          width: 100%;
          padding: 20px;
        `}
      >
        <FcQuizLogo
          height={15}
          width={69}
          color={backgroundImageUrl ? colors.white : colors.black}
        />
      </Flex>

      <div
        css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          padding: 16px;
        `}
      >
        <ClubLogo
          url={publicClub.logoUrl}
          shape={publicClub.logoShape}
          size={40}
        />
      </div>

      <Flex
        column
        vertical="center"
        grow
        css={css`
          padding: 0 16px;
        `}
      >
        <Flex
          column
          vertical="center"
          horizontal="center"
          css={css`
            max-width: 380px;
            background-color: ${publicClub.backgroundColor};
            border-radius: 16px;
            padding: 16px 16px 32px;

            @media (max-width: 400px) {
              padding: 8px 8px 16px;
            }

            box-shadow: ${boxShadows.leagueQuizItem};
          `}
        >
          <Flex
            column
            vertical="center"
            css={css`
              padding: 8px 16px 12px;
            `}
          >
            <HeadingNew
              level={3}
              italic
              condensed
              color={publicClub.textColor}
              css={css`
                text-align: center;
                line-height: 0.8;
                font-size: 39px;
                line-height: 30px;
                letter-spacing: 0em;
              `}
            >
              {currentQuiz?.title ?? leagueTitle}
            </HeadingNew>
          </Flex>

          {currentQuiz?.embedPromotionalText && (
            <TextNew
              strong
              color={publicClub.textColor}
              css={css`
                letter-spacing: 0.01em;
                text-align: center;
                font-size: 20px;
                line-height: 30px;
                margin: 4px 32px;
                padding: 12px 16px;
                max-width: 500px;
                border-radius: 4px;
              `}
            >
              {currentQuiz?.embedPromotionalText}
            </TextNew>
          )}
          <Spacer height={20} />
          <EmbedTrackingComponent
            eventType={AnalyticsEventType.EmbedLandingpage}
            eventData={{
              clubLeagueSubscriptionId,
              clubId: publicClub.id,
              leagueId: currentQuiz?.league?.id,
              quizId: currentQuiz?.id,
              campaignId: campaign.id,
            }}
          />
          {currentQuiz && currentQuiz.league ? (
            <PrimaryButton
              disabled={loading}
              loading={loading}
              rounded
              variant={
                colorIsLight(publicClub.backgroundColor) ? 'black' : 'white'
              }
              onClick={() => {
                setLoading(true)
                onPlayClick()
              }}
              css={css`
                width: fit-content;
                padding: 8px 40px;
                margin: 0 auto;

                min-height: 48px;
                min-width: 218px;
              `}
            >
              <TextNew
                color={
                  colorIsLight(publicClub.backgroundColor) ? 'white' : 'black'
                }
                condensed
                italic
                strong
                size="large"
                css={css`
                  font-size: 25px;
                  line-height: 36px;
                `}
              >
                {t(`Play quiz`)}
              </TextNew>
            </PrimaryButton>
          ) : (
            <a
              href={`/clubs/${publicClub.id}?clubLeagueSubscriptionId=${clubLeagueSubscriptionId}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
            >
              <PrimaryButton
                css={css`
                  width: fit-content;
                  padding: 8px 40px;
                  margin: 0 auto;
                `}
              >
                <Text
                  color={colors.white}
                  extraCondensed
                  italic
                  strong
                  size="large"
                  css={css`
                    letter-spacing: 0.01em;
                  `}
                >
                  {t('Play in the app!')}
                </Text>
              </PrimaryButton>
            </a>
          )}
        </Flex>
      </Flex>

      {titleImage && (
        <>
          <Flex
            css={css`
              width: 100%;
              max-height: 174px;
              background-color: ${campaign.titleScreenBackgroundColor};
              background-image: ${campaign?.backgroundImageUrl
                ? `url(${campaign.backgroundImageUrl})`
                : 'none'};
              background-size: cover;
              background-position: center;
              overflow: hidden;
            `}
          >
            <img
              src={makeSanityImageUrl({
                uri: titleImage,
                type: showMobileImages
                  ? CampaignImageType.titleImageMobile
                  : CampaignImageType.titleImage,
              })}
              alt="Ad"
              css={css`
                width: 100%;
                height: 100%;
                object-fit: contain;
              `}
            />
          </Flex>
        </>
      )}
    </Flex>
  )
}

const ClubLeagueSubLandingPageV2 = () => {
  const { loading } = useAuthContext()

  const clubLeagueSubscriptionId =
    useParams<'clubLeagueSubscriptionId'>().clubLeagueSubscriptionId!

  const quizId = useParams<'quizId'>().quizId

  const publicClubLeagueQuery = usePublicClubLeagueQuery(
    clubLeagueSubscriptionId
  )

  if (loading || publicClubLeagueQuery.loading) {
    return <EmbedLoadingPage />
  }

  const club = publicClubLeagueQuery.data?.clubLeagueSubscription?.club
  const league = publicClubLeagueQuery.data?.clubLeagueSubscription?.league

  if (!club || !league) {
    const reason = `Could not find clubId or leagueId for clubLeagueSubscriptionId: ${clubLeagueSubscriptionId}`
    return <EmbedErrorPage reason={reason} clubId={club?.id} />
  }

  return (
    <Loader
      club={club}
      clubLeagueSubscriptionId={clubLeagueSubscriptionId}
      leagueId={league?.id}
      quizId={quizId}
    />
  )
}
export default ClubLeagueSubLandingPageV2
