/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../../constants'
import { useAuthContext } from '../../../lib/AuthContext'
import {
  AnalyticsEventType,
  useGetTrackEvent,
  useTrackEvent,
} from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../../styles/animations'
import { Spacer } from '../../../styles/margin'
import theme, { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { QuizType } from '../../../views/Generate/GeneratePage'
import { useLeagueQuizContext } from '../../../views/LeagueQuiz/LeagueQuizContext'
import {
  CareerPathQuizInstance,
  LeagueQuizInstance,
  useLeagueQuizFinished,
} from '../../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../../Button'
import { Divider } from '../../Divider'
import { Text } from '../../Text'
import { Flex } from '../../layout/Flex'
import { Page } from '../Page'
import { QuizScoreComponent } from '../QuizScoreComponent'
import { TopPlayers } from '../TopPlayers'

interface Props {
  leagueQuizInstance: LeagueQuizInstance | CareerPathQuizInstance
  colorTheme: 'red' | 'white' | 'green'
  clubLeagueSubscriptionId?: string
  playAnimation?: boolean
  isCareerPath?: boolean
}

const FinishedEmbedQuiz = (props: Props) => {
  const {
    leagueQuizInstance,
    colorTheme,
    clubLeagueSubscriptionId,
    playAnimation = false,
    isCareerPath = false,
  } = props
  const t = useT()
  const trackEvent = useGetTrackEvent()

  const { signInAsAnonymous, authUser } = useAuthContext()

  const leagueQuizFinished = useLeagueQuizFinished({
    quizId: leagueQuizInstance.leagueQuiz.id,
    limit: 3,
  })

  useTrackEvent(AnalyticsEventType.EmbedFinishedQuiz, {
    clubLeagueSubscriptionId,
    quizId: leagueQuizInstance.leagueQuiz.id,
  })

  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)
  const { setIsExpandedAdActive, videoAdActive, hasSanityCampaign } =
    useLeagueQuizContext()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (
      hasSanityCampaign &&
      remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS
    ) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const backgroundColor =
    colorTheme === 'red' ? theme.colors.red500 : theme.colors.grey100

  // const fadeInDelaytime = initialRemaining + 600

  const recommendedQuiz =
    leagueQuizFinished.data?.leagueQuiz?.league.recommendedQuiz
  const imageUrl =
    recommendedQuiz?.league.leagueCardImageUrl ??
    recommendedQuiz?.league.imageUrl

  const navigate = useNavigate()

  const showResults = isCareerPath || 'timeBonus' in leagueQuizInstance.result

  const entries = leagueQuizFinished.data?.leagueQuiz?.leaderboard ?? []
  const friendEntries = leagueQuizFinished.data?.leagueQuiz?.friendsLeaderboard

  const hasFriendEntries = friendEntries && friendEntries.length > 0

  return (
    <>
      <Page>
        <Flex
          grow
          column
          horizontal="center"
          css={css`
            background-color: ${backgroundColor};
            overflow-x: hidden;
            width: 100%;
          `}
        >
          <Flex
            column
            horizontal="center"
            grow
            css={css`
              width: 100%;
              flex-shrink: 0;
              margin: 0 auto;
            `}
          >
            {showResults && (
              <QuizScoreComponent
                questionsCount={leagueQuizInstance.leagueQuiz.questionsCount}
                result={leagueQuizInstance.result}
                remaining={remaining}
                totalCalculationTime={initialRemaining}
                playAnimation={playAnimation}
              />
            )}
            {!videoAdActive && remaining <= 2000 && (
              <Flex
                column
                css={css`
                  overflow-x: auto;
                  opacity: 0;
                  animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                    cubic-bezier(0.4, 0, 0.15, 1);
                  animation-delay: 600ms;
                  animation-fill-mode: forwards;
                  width: 100%;
                `}
              >
                {/* <a
              href={`/challenge/${leagueQuizInstance.id}`}
              target="_blank"
              rel="noreferrer"
              css={css`
                text-align: center;
              `}
              onClick={() => {
                trackEvent('Embed | Challenge a friend')
              }}
            >
              <PrimaryButton
                css={css`
                  background-color: black;
                  color: white;
                  width: fit-content;
                  padding: 5px 20px;
                  opacity: 0;
                  animation: ${growAndFade} 600ms cubic-bezier(0.4, 0, 0.15, 1);
                  animation-delay: ${fadeInDelaytime}ms;
                  animation-fill-mode: forwards;
                  margin-top: 8px;
                `}
              >
                {t('Challenge a friend')}
              </PrimaryButton>
            </a> */}
                <Spacer height={16} />

                <Flex vertical="center">
                  <Divider />
                  <Spacer width={8} />
                  <Text
                    size={'small'}
                    wide
                    strong
                    italic
                    color={colors.grey400}
                  >
                    {t(`Want to play more?`)}
                  </Text>
                  <Spacer width={8} />
                  <Divider />
                </Flex>
                <Spacer height={12} />
                <Flex
                  css={css`
                    width: 100%;
                    height: 90px;
                    position: relative;

                    * {
                      flex-shrink: 1;
                      min-width: 0;
                    }
                    span {
                      display: inline-block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  `}
                  gap="medium"
                >
                  {recommendedQuiz && (
                    <>
                      {imageUrl && (
                        <Flex
                          column
                          vertical="space-between"
                          css={[
                            css`
                              height: 100%;
                              width: 120px;
                              border-radius: 10px;
                              background: url(${imageUrl});
                              background-size: cover;
                              background-position: center center;
                            `,
                          ]}
                        />
                      )}
                      <Flex
                        css={css`
                          flex: 1;
                        `}
                        column
                        horizontal="center"
                      >
                        <Flex
                          css={css`
                            width: ${imageUrl ? 100 : 60}%;
                          `}
                          column
                          gap="small"
                          vertical="space-between"
                          horizontal={imageUrl ? 'space-between' : 'center'}
                        >
                          <Text wide italic>
                            {recommendedQuiz.league.title}
                          </Text>
                          <PrimaryButton
                            css={css`
                              padding: 10px;
                              width: 100%;
                            `}
                            variant="lightGreen"
                            onClick={async () => {
                              if (!authUser) {
                                await signInAsAnonymous()
                              }
                              trackEvent(
                                AnalyticsEventType.EmbedPlayMoreInEmbedQuiz,
                                {
                                  type: `${recommendedQuiz.league.title} - ${recommendedQuiz.title}`,
                                }
                              )
                              navigate(
                                `/embed/league/${recommendedQuiz.league.id}/${recommendedQuiz.id}`,
                                {
                                  replace: true,
                                }
                              )
                            }}
                          >
                            <Text color="#095218" strong>
                              {t(`Play next quiz`)}
                            </Text>
                          </PrimaryButton>
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Flex>

                <Spacer height={16} />
                {leagueQuizInstance.quizType === QuizType.TEXT && (
                  <TopPlayers
                    quizId={leagueQuizInstance.leagueQuiz.id}
                    leagueSlug={leagueQuizInstance.leagueQuiz.league.slug}
                    entries={hasFriendEntries ? friendEntries : entries}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Page>
    </>
  )
}
export default FinishedEmbedQuiz
