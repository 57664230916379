/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { analytics } from '../lib/analytics'
import {
  useAuthContext,
  useViewerCountryQuery,
  useViewerQuery,
} from '../lib/AuthContext'
import { setCurrentLocale, useCurrentLocale } from '../lib/i18n/locale'
import { getLocaleFromCountry } from './util'
import { router } from '../router'

export const EnsureCorrectLocale = () => {
  const { authUser } = useAuthContext()
  const viewerLocaleQuery = useViewerQuery({ skip: !authUser })

  const viewerLocale = viewerLocaleQuery.data?.viewer?.locale

  const viewerCountryQuery = useViewerCountryQuery()

  const viewerCountry = viewerCountryQuery.data?.viewerCountry

  const currentLocale = useCurrentLocale()
  analytics.setLocale(currentLocale)

  useEffect(() => {
    if (viewerCountry && !viewerLocale && !viewerLocaleQuery.loading) {
      const localeFromCountry = getLocaleFromCountry(viewerCountry)
      if (localeFromCountry) {
        setCurrentLocale(localeFromCountry)
        analytics.setLocale(localeFromCountry)
      }
    }

    if (viewerLocale && currentLocale !== viewerLocale) {
      setCurrentLocale(viewerLocale)
      analytics.setLocale(viewerLocale)
    }
  }, [
    currentLocale,
    viewerLocale,
    router,
    viewerCountry,
    viewerLocaleQuery.loading,
  ])

  return null
}
