/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { AnalyticsEventType, eventsToTrackInDb } from '../../lib/analytics'
import { useViewerQuery } from '../../lib/AuthContext'
import { Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { LineChartV2 } from './LineChartComponentV2'
import { AdminNavbar } from './Navbar'
import { useAnalyticsLabelsQuery, useGetEventsQueryV2 } from './queries'

export type AnalyticsTimeRange = {
  startDate: Date
  endDate: Date
}

const stringToAnalyticsGroupBy = (str?: string | null): AnalyticsParameter => {
  switch (str) {
    case 'clubId':
      return 'clubId'
    case 'leagueId':
      return 'leagueId'
    case 'quizId':
      return 'quizId'
    case 'clubLeagueSubscriptionId':
      return 'clubLeagueSubscriptionId'
    case 'campaignId':
      return 'campaignId'
    case 'embedDomain':
      return 'embedDomain'
    case 'locale':
      return 'locale'
    default:
      return 'none'
  }
}

export type AnalyticsParameter =
  | 'clubId'
  | 'leagueId'
  | 'quizId'
  | 'clubLeagueSubscriptionId'
  | 'campaignId'
  | 'embedDomain'
  | 'locale'
  | 'type'
  | 'isEmbed'
  | 'isLive'
  | 'isChallenge'
  | 'leagueQuizId'
  | 'trainingQuizId'
  | 'isCareerPath'
  | 'isNativeApp'
  | 'isGenerated'
  | 'categoryId'
  | 'itemId'
  | 'externalCampaign'
  | 'country'
  | 'none'

export type AnalyticsFilter = {
  parameter: AnalyticsParameter
  value: string | undefined
}

export const DatePicker = ({
  selectedTimeRange,
  setSelectedTimeRange,
  textColor = colors.black,
}: {
  selectedTimeRange: AnalyticsTimeRange
  setSelectedTimeRange: (timeRange: AnalyticsTimeRange) => void
  textColor?: string
}) => {
  return (
    <Flex
      gap="medium"
      horizontal="space-between"
      css={css`
        flex-direction: row;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      `}
    >
      <Flex
        vertical="center"
        gap="small"
        css={css`
          justify-content: flex-start;

          @media (max-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        <TextNew color={textColor}>Start Date</TextNew>
        <input
          type="date"
          value={selectedTimeRange.startDate.toISOString().split('T')[0]}
          onChange={(e) => {
            setSelectedTimeRange({
              ...selectedTimeRange,
              startDate: new Date(e.target.value),
            })
          }}
          max={selectedTimeRange.endDate.toISOString().split('T')[0]}
        />
      </Flex>

      <Flex
        vertical="center"
        gap="small"
        css={css`
          justify-content: flex-start;

          @media (max-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        <TextNew color={textColor}>End Date</TextNew>
        <input
          type="date"
          value={selectedTimeRange.endDate.toISOString().split('T')[0]}
          onChange={(e) => {
            const endDate = new Date(e.target.value)
            // set time to end of day
            // this is necessary because the date picker sets the time to 00:00:00
            endDate.setHours(23, 59, 59, 999)
            setSelectedTimeRange({
              ...selectedTimeRange,
              endDate,
            })
          }}
          min={selectedTimeRange.startDate.toISOString().split('T')[0]}
          max={new Date().toISOString().split('T')[0]}
        />
      </Flex>
    </Flex>
  )
}

const eventTypeLabel = (type: AnalyticsEventType) => {
  switch (type) {
    case AnalyticsEventType.AdCampaignClicked:
      return 'Campaign Click'
    case AnalyticsEventType.AdCampaignShown:
      return 'Campaign Shown Active'
    case AnalyticsEventType.AdCampaignFinishedShown:
      return 'Campaign Shown Finished'
    case AnalyticsEventType.AdCampaignExpanded:
      return 'Campaign Expanded'
    default:
      return type
  }
}

export type GetEventsFrequency = 'hour' | 'day'

const Content = ({
  event,
  groupBy: groupByParam,
  start: startParam,
  end: endParam,
  frequency: frequencyParam,
}: {
  event?: string | null
  groupBy?: string | null
  start?: string | null
  end?: string | null
  frequency?: GetEventsFrequency | null
}) => {
  const viewerQuery = useViewerQuery()

  const analyticsLabelsQuery = useAnalyticsLabelsQuery()

  const [groupBy, setGroupBy] = useState<AnalyticsParameter>(
    stringToAnalyticsGroupBy(groupByParam) || 'none'
  )

  const [frequency, setFrequency] = useState<GetEventsFrequency>(
    frequencyParam || 'day'
  )

  const [filterBy, setFilterBy] = useState<AnalyticsFilter[]>([])

  const [selectedEventType, setSelectedEventType] = useState<
    string | undefined
  >(event !== null ? event : undefined)

  const intervalStartDate = startParam ? new Date(startParam) : new Date()

  if (!startParam) {
    intervalStartDate.setDate(intervalStartDate.getDate() - 7)
    const offset = -new Date().getTimezoneOffset() / 60
    intervalStartDate.setHours(offset, 0, 0, 0)
  }

  const endParamEndOfDay = endParam ? new Date(endParam) : new Date()
  endParamEndOfDay.setHours(23, 59, 59, 999)

  const [selectedTimeRange, setSelectedTimeRange] =
    useState<AnalyticsTimeRange>({
      startDate: intervalStartDate,
      endDate: endParamEndOfDay,
    })

  const eventsQuery = useGetEventsQueryV2({
    eventType: selectedEventType,
    startDate: selectedTimeRange.startDate.toISOString(),
    endDate: selectedTimeRange.endDate.toISOString(),
    groupBy,
    filterBy,
    frequency,
  })

  // set search params
  useEffect(() => {
    const searchParams = new URLSearchParams()
    if (selectedEventType) {
      searchParams.set('event', selectedEventType)
    }
    searchParams.set('groupBy', groupBy)

    searchParams.set(
      'start',
      selectedTimeRange.startDate.toISOString().split('T')[0]
    )
    searchParams.set(
      'end',
      selectedTimeRange.endDate.toISOString().split('T')[0]
    )

    searchParams.set('frequency', frequency)

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }, [selectedEventType, groupBy, selectedTimeRange, frequency])

  // useEffect(() => {
  //   if (frequency === 'hour') {
  //     // set start to start of end date
  //     setSelectedTimeRange({
  //       ...selectedTimeRange,
  //       startDate: new Date(
  //         selectedTimeRange.endDate.getFullYear(),
  //         selectedTimeRange.endDate.getMonth(),
  //         selectedTimeRange.endDate.getDate(),
  //         0,
  //         0,
  //         0
  //       ),
  //     })
  //   }
  // }, [frequency])

  if (viewerQuery.loading) {
    return <Loader center />
  }

  const viewer = viewerQuery.data?.viewer
  const viewerIsAdmin = viewer?.isAdmin

  if (!viewerIsAdmin || !viewer) {
    return (
      <div>
        <HeadingNew level={1} looksLikeLevel={3}>
          Access Denied
        </HeadingNew>

        <TextNew>You do not have permission to access this page.</TextNew>
      </div>
    )
  }

  const eventTypes = eventsToTrackInDb

  const events = eventsQuery.data

  const analyticsLabels = analyticsLabelsQuery.data?.getAnalyticsLabels || []

  return (
    <Flex column>
      <PageContent grow gap={'large'}>
        <HeadingNew level={1} looksLikeLevel={3}>
          Events
        </HeadingNew>

        {eventTypes.length > 0 && (
          <Flex wrap gap={'small'}>
            {eventTypes.map((eventType) => {
              const selected = selectedEventType === eventType
              return (
                <button
                  key={eventType}
                  css={[
                    buttonReset,
                    css`
                      background-color: ${selected
                        ? colors.yellow400
                        : colors.yellow300};
                      padding: 8px 12px;
                      border-radius: 8px;
                    `,
                  ]}
                  onClick={() => {
                    setSelectedEventType(selected ? undefined : eventType)
                  }}
                >
                  <TextNew underline={selected}>
                    {eventTypeLabel(eventType)}
                  </TextNew>
                </button>
              )
            })}
          </Flex>
        )}

        <DatePicker
          selectedTimeRange={selectedTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
        />

        {eventsQuery.loading && <Loader center />}
      </PageContent>
      {selectedEventType && (
        <Flex
          css={css`
            padding: 32px;
            max-width: 1440px;
            width: 100%;
            margin: 0 auto;

            @media (max-width: 700px) {
              padding: 16px;
            }
            @media (max-width: 500px) {
              padding: 8px;
            }
          `}
        >
          <LineChartV2
            datasets={events}
            timeRange={selectedTimeRange}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            analyticsLabels={analyticsLabels}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            setFrequency={setFrequency}
          />
        </Flex>
      )}
      <Spacer height={80} />
    </Flex>
  )
}

export const AdminAnalyticsPage = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const event = searchParams.get('event')
  const groupBy = searchParams.get('groupBy')
  const start = searchParams.get('start')
  const end = searchParams.get('end')
  const frequency = searchParams.get('frequency')

  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="analytics" />
        </PageContent>
        <Content
          event={event}
          groupBy={groupBy}
          start={start}
          end={end}
          frequency={frequency as GetEventsFrequency | null}
        />
      </AdminAccessControlComponent>
    </Layout>
  )
}
