/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../constants'
import {
  AnalyticsEventType,
  useGetTrackEvent,
  useTrackEvent,
} from '../../lib/analytics'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  LeagueQuizInstanceResult,
  useLeagueQuizFinished,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../Button'
import { Divider } from '../Divider'

import { Flex } from '../layout/Flex'
import { Page } from '../leagueQuiz/Page'
import { QuizScoreComponent } from '../leagueQuiz/QuizScoreComponent'
import { TopPlayers } from '../leagueQuiz/TopPlayers'
import { TextNew } from '../TextNew'

interface Props {
  clubLeagueSubscriptionId?: string
  playAnimation: boolean
  quizId: string
  result: LeagueQuizInstanceResult
  questionsCount: number
  leagueSlug?: string
  hasCampaign?: boolean
}

const FinishedQuiz = (props: Props) => {
  const {
    clubLeagueSubscriptionId,
    playAnimation,
    quizId,
    result,
    questionsCount,
    leagueSlug,
    hasCampaign = false,
  } = props

  const t = useT()

  const { signInAsAnonymous, authUser } = useAuthContext()

  const leagueQuizFinished = useLeagueQuizFinished({ quizId, limit: 3 })

  const trackEvent = useGetTrackEvent()

  useTrackEvent(AnalyticsEventType.EmbedFinishedQuiz, {
    clubLeagueSubscriptionId,
    quizId,
  })

  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(props.playAnimation)
  const { setIsExpandedAdActive, videoAdActive } = useLeagueQuizContext()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation && hasCampaign) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (
      hasCampaign &&
      remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS
    ) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const backgroundColor = colors.grey100

  // const fadeInDelaytime = initialRemaining + 600

  const recommendedQuiz =
    leagueQuizFinished.data?.leagueQuiz?.league.recommendedQuiz
  const imageUrl =
    recommendedQuiz?.league.leagueCardImageUrl ??
    recommendedQuiz?.league.imageUrl

  const navigate = useNavigate()

  const entries = leagueQuizFinished.data?.leagueQuiz?.leaderboard ?? []
  const friendEntries =
    leagueQuizFinished.data?.leagueQuiz?.friendsLeaderboard ?? []
  const hasFriendEntries = friendEntries.length > 0

  return (
    <Page>
      <Flex
        grow
        column
        horizontal="center"
        css={css`
          background-color: ${backgroundColor};
          overflow-x: hidden;
          width: 100%;
        `}
      >
        <Flex
          column
          horizontal="center"
          grow
          css={css`
            width: 100%;
            flex-shrink: 0;
            margin: 0 auto;
          `}
        >
          <QuizScoreComponent
            result={result}
            remaining={remaining}
            totalCalculationTime={initialRemaining}
            playAnimation={playAnimation}
            questionsCount={questionsCount}
          />

          {!videoAdActive && remaining <= 2000 && (
            <Flex
              column
              css={css`
                overflow-x: auto;
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `}
            >
              <Spacer height={16} />

              <Flex vertical="center">
                <Divider />
                <Spacer width={8} />
                <TextNew
                  size={'small'}
                  wide
                  strong
                  italic
                  color={colors.grey400}
                >
                  {t(`Want to play more?`)}
                </TextNew>
                <Spacer width={8} />
                <Divider />
              </Flex>
              <Spacer height={12} />
              <Flex
                css={css`
                  width: 100%;
                  height: 90px;
                  position: relative;

                  * {
                    flex-shrink: 1;
                    min-width: 0;
                  }
                  span {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                `}
                gap="medium"
              >
                {recommendedQuiz && (
                  <>
                    {imageUrl && (
                      <Flex
                        column
                        vertical="space-between"
                        css={[
                          css`
                            height: 100%;
                            width: 120px;
                            border-radius: 10px;
                            background: url(${imageUrl});
                            background-size: cover;
                            background-position: center center;
                          `,
                        ]}
                      />
                    )}
                    <Flex
                      css={css`
                        flex: 1;
                      `}
                      column
                      horizontal="center"
                    >
                      <Flex
                        css={css`
                          width: ${imageUrl ? 100 : 60}%;
                        `}
                        column
                        gap="small"
                        vertical="space-between"
                        horizontal={imageUrl ? 'space-between' : 'center'}
                      >
                        <TextNew wide italic>
                          {recommendedQuiz.league.title}
                        </TextNew>
                        <PrimaryButton
                          css={css`
                            padding: 10px;
                            width: 100%;
                          `}
                          variant="lightGreen"
                          onClick={async () => {
                            if (!authUser) {
                              await signInAsAnonymous()
                            }
                            trackEvent(
                              AnalyticsEventType.EmbedPlayMoreInEmbedQuiz,
                              {
                                type: `${recommendedQuiz.league.title} - ${recommendedQuiz.title}`,
                              }
                            )
                            navigate(
                              `/embed/league/${recommendedQuiz.league.id}/${recommendedQuiz.id}`,
                              {
                                replace: true,
                              }
                            )
                          }}
                        >
                          <TextNew color="#095218" strong>
                            {t(`Play next quiz`)}
                          </TextNew>
                        </PrimaryButton>
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>

              <Spacer height={16} />

              <TopPlayers
                quizId={quizId}
                leagueSlug={leagueSlug}
                entries={hasFriendEntries ? friendEntries : entries}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Page>
  )
}
export default FinishedQuiz
