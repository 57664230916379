/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { Text } from '../../../components/Text'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'

import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { useSubscribeToLeague } from '../../League/queries'

export type LeagueQuizMinimalWithLeague = {
  id: string
  title: string
  league: {
    id: string
    title: string
    frequency?: string
    viewerIsSubscribed?: boolean
  }
  imageUrl?: string
  participantCount: number
  questionsCount: number
}

export const LeagueQuizCard = ({
  quiz,
  showButton,
  onFollow,
}: {
  quiz: LeagueQuizMinimalWithLeague
  showButton?: boolean
  onFollow?: () => void
}) => {
  const t = useT()

  const [subscribeToLeague] = useSubscribeToLeague()
  const navigate = useNavigate()

  return (
    <Flex
      column
      vertical="center"
      gap="small"
      css={css`
        background-color: ${colors.yellow100};
        padding: 12px 16px;
        border-radius: 12px;
      `}
    >
      <Flex gap="small" vertical="center">
        <Text
          extraCondensed
          css={css`
            font-size: 16px;
            line-height: 16px;
            font-style: italic;
          `}
        >
          {quiz.league.title}
        </Text>
        {quiz.league.frequency && (
          <Text
            extraCondensed
            css={css`
              font-size: 16px;
              line-height: 16px;
              font-style: italic;
              background-color: ${colors.grey100};
              padding: 2px 8px;
              border-radius: 16px;
            `}
          >
            {quiz.league.frequency}
          </Text>
        )}
      </Flex>
      <Heading
        level={3}
        extraCondensed
        css={css`
          font-weight: 700;
          font-size: 34px;
          line-height: 34px;
          font-style: italic;
        `}
      >
        {quiz.title}
      </Heading>
      <Flex vertical="center" horizontal="space-between">
        <Flex column>
          <Text
            extraCondensed
            css={css`
              font-size: 16px;
              line-height: 16px;
              font-style: italic;
            `}
          >
            {t('{{count}} question_other', {
              count: quiz.questionsCount,
            })}
          </Text>
          <Text
            extraCondensed
            css={css`
              font-size: 16px;
              line-height: 16px;
              font-style: italic;
            `}
          >
            {t('{{count}} player_other', {
              count: quiz.participantCount,
            })}
          </Text>
        </Flex>
        {showButton && (
          <>
            {quiz.league.viewerIsSubscribed ? (
              <PrimaryButton
                onClick={() => {
                  navigate(`/series/${quiz.league.id}`)
                }}
              >
                {t('To the series page')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={async () => {
                  subscribeToLeague({
                    variables: {
                      leagueId: quiz.league.id,
                    },
                  })
                  if (onFollow) {
                    onFollow()
                  }
                }}
              >
                {t('Follow')}
              </PrimaryButton>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
