/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { Flex } from './layout/Flex'
import { TextNew } from './TextNew'

type Props = {
  label: string
  backgroundColor: string
  color: string
  style?: SerializedStyles
  clickable?: boolean
} & React.HTMLAttributes<HTMLDivElement>
export const Chip = ({
  label,
  backgroundColor,
  color,
  style,
  clickable,
  onClick,
  children,
  className,
}: Props) => {
  return (
    <Flex
      vertical="center"
      onClick={clickable ? onClick : undefined}
      css={[
        css`
          padding: 2px 12px 4px;
          border-radius: 100px;
          background-color: ${backgroundColor};
          cursor: ${clickable ? 'pointer' : 'default'};
        `,
        style,
      ]}
      className={className}
    >
      {children}
      <TextNew color={color} size="small">
        {label}
      </TextNew>
    </Flex>
  )
}
