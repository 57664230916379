/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useEffect } from 'react'
import { PrimaryButton } from '../../components/Button'
import { QuestionImage } from '../../components/QuestionImage'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { AlternativeButton } from '../../components/leagueQuiz/AlternativeButton'
import { Page } from '../../components/leagueQuiz/Page'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { ChallengeCompletedQuestion } from '../ChallengeMatch/queries'
import { LeagueQuiz as LeagueQuizType } from '../LeagueQuiz/queries'
import { User } from '../Profile/queries'

export const ChallengeLastCompletedQuestion: FC<{
  leagueQuiz: LeagueQuizType
  lastCompletedQuestion: ChallengeCompletedQuestion
  allQuestionsAnswered: boolean
  triggerCalculation: () => void
  nextQuestionLoading: boolean
  nextQuestion: () => void
  colorTheme: 'red' | 'white' | 'green'
  challengeUser: User
}> = ({
  leagueQuiz,
  lastCompletedQuestion,
  allQuestionsAnswered,
  triggerCalculation,
  nextQuestionLoading,
  nextQuestion,
  colorTheme,
  challengeUser,
}) => {
  const t = useT()

  const trackEvent = useGetTrackEvent()

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId: leagueQuiz.league.id,
        leagueQuizId: leagueQuiz.id,
        isChallenge: true,
      })
    }
  }, [allQuestionsAnswered, leagueQuiz.id, leagueQuiz.league.id])

  const backgroundColor =
    colorTheme === 'red'
      ? theme.colors.red500
      : colorTheme === 'green'
      ? theme.colors.green600
      : theme.colors.grey100

  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  // Only show global stats outside of challenge quizzes

  return (
    <Page backgroundColor={backgroundColor} dense>
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Spacer height="small" />
        <Text
          key={lastCompletedQuestion.text}
          color={textColor}
          css={margin.vertical('medium')}
          textAlign="center"
          size="medium"
        >
          {lastCompletedQuestion.text}
        </Text>
        {lastCompletedQuestion.imageUrl && (
          <QuestionImage imageSrc={lastCompletedQuestion.imageUrl} />
        )}
        {!lastCompletedQuestion.alternatives.some(
          (alternative) => alternative.selectedAlternative
        ) && (
          <Text color={colors.red300} css={margin.bottom()} textAlign="center">
            {t('Unfortunately, the time ran out!')}
          </Text>
        )}
        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <AlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.selectedAlternative}
              correct={alternative.isCorrectAlternative}
              challengeUser={
                alternative.challengeUserSelected ? challengeUser : undefined
              }
              showGlobalStats={false}
            >
              {alternative.text}
            </AlternativeButton>
          ))}

          <Flex
            column
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${fadeIn} 600ms ease-out;
                animation-delay: 2000ms;
                animation-fill-mode: forwards;
              `,
            ]}
          >
            {allQuestionsAnswered ? (
              <PrimaryButton
                onClick={() => triggerCalculation()}
                css={css`
                  background-color: 'black';
                  height: 50px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Finish')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                disabled={nextQuestionLoading}
                loading={nextQuestionLoading}
                onClick={nextQuestion}
                css={css`
                  background-color: black;
                  color: white;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Next')}
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Page>
  )
}
