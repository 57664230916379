/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { AnalyticsEventType, useGetTrackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import theme, { colors } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { useScreenWidth } from '../../Challenge/DisplayPage/util'
import { useStartChallengeMatchQuizMutation } from '../queries'
import { ChallengeMatch } from '../ViewChallengeMatchPage'

export const ChallengeMatchListComponent = ({
  challengeMatch,
  showQuizTitle = false,
  className,
}: {
  challengeMatch: ChallengeMatch
  showQuizTitle?: boolean
  className?: string
}) => {
  const screenWith = useScreenWidth()

  const t = useT()

  const navigate = useNavigate()

  const [startChallengeMatchMutation] = useStartChallengeMatchQuizMutation()

  const avtarHeight = screenWith > 800 ? 100 : 88

  const trackEvent = useGetTrackEvent()

  const ActionButton = () => {
    const status = challengeMatch.status
    if (status === 'completed') {
      return (
        <PrimaryButton
          rounded
          onClick={() => {
            navigate(`/challenge-match/${challengeMatch.id}`)
          }}
          css={css`
            background-color: ${colors.white};
            min-height: 48px;
            padding: 0 40px;
            box-shadow: ${theme.boxShadows.default};
          `}
        >
          <TextNew
            color={colors.black}
            italic
            weight={600}
            condensed
            css={[
              css`
                font-size: 24px;
              `,
            ]}
          >
            {t('Results')}
          </TextNew>
        </PrimaryButton>
      )
    }

    return (
      <PrimaryButton
        rounded
        css={css`
          background-color: ${colors.white};
          min-height: 48px;
          padding: 0 40px;
          box-shadow: ${theme.boxShadows.default};
        `}
        onClick={async () => {
          const { data } = await startChallengeMatchMutation({
            variables: {
              input: {
                opponentId: challengeMatch.homeUser.id,
                quizId: challengeMatch.leagueQuiz.id,
                challengeMatchId: challengeMatch.id,
              },
            },
          })
          if (!data || !data.startChallengeMatchQuiz) {
            return
          }

          const challengeMatchId = data.startChallengeMatchQuiz?.id
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: challengeMatch.leagueQuiz.id,
            leagueId: challengeMatch.leagueQuiz.league.id,
            isChallenge: true,
            isEmbed: false,
          })
          navigate(`/challenge-match/${challengeMatchId}`)
        }}
      >
        <TextNew
          color={colors.black}
          italic
          weight={600}
          condensed
          css={[
            css`
              font-size: 24px;
            `,
          ]}
        >
          {status === 'activeViewer' ? t('Continue') : t('Accept')}
        </TextNew>
      </PrimaryButton>
    )
  }

  return (
    <Flex
      horizontal="space-between"
      vertical="flex-end"
      css={css`
        background-color: ${colors.black};
        border-radius: 12px;
        position: relative;
        height: 64px;
        padding: 0 16px;

        box-shadow: ${theme.boxShadows.default};

        @media (max-width: 800px) {
          padding: 0 12px;
        }

        @media (max-width: 500px) {
          padding: 0 8px;
        }
      `}
      className={className}
    >
      {showQuizTitle && (
        <Flex
          css={css`
            position: absolute;
            top: -24px;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${colors.yellow400};
            padding: 6px 16px;
            border-radius: 16px;
          `}
        >
          <TextNew
            color={colors.black}
            condensed
            size={18}
            css={css`
              text-transform: capitalize;
            `}
          >
            {challengeMatch.leagueQuiz.title}
          </TextNew>
        </Flex>
      )}
      <UserAvatar
        height={avtarHeight}
        avatarData={challengeMatch.homeUser.avatarData}
      />

      <Flex
        horizontal="space-between"
        vertical="center"
        gap={8}
        css={css`
          height: 100%;
          width: 100%;
          padding: 0 16px;
          margin-bottom: 6px;

          @media (max-width: 800px) {
            padding: 0 12px;
          }

          @media (max-width: 500px) {
            padding: 0 8px;
          }
        `}
      >
        <Playername
          name={challengeMatch.homeUser.displayName}
          textAlign="left"
        />
        <TextNew
          color={colors.yellow500}
          italic
          weight={700}
          size={32}
          textAlign="center"
          condensed
          css={css`
            font-size: 32px;
            flex: 1;

            @media (max-width: 800px) {
              font-size: 28px;
            }

            @media (max-width: 500px) {
              font-size: 24px;
            }
          `}
        >
          VS
        </TextNew>
        <Playername
          name={challengeMatch.awayUser.displayName}
          textAlign="right"
        />
      </Flex>

      <UserAvatar
        height={avtarHeight}
        avatarData={challengeMatch.awayUser.avatarData}
      />
      <div
        css={css`
          position: absolute;
          bottom: -54px;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <ActionButton />
      </div>
    </Flex>
  )
}

const Playername = ({
  name,
  textAlign = 'left',
}: {
  name?: string
  textAlign?: 'left' | 'center' | 'right'
}) => {
  if (!name) {
    return null
  }

  const screenWith = useScreenWidth()

  const nameSplit = name.split(' ')

  const hasLongWord = nameSplit.some((word) => word.length > 10)

  const shouldElipsis =
    (nameSplit.length === 1 || hasLongWord) &&
    name.length > 10 &&
    screenWith < 500

  return (
    <TextNew
      color={colors.white}
      italic
      weight={600}
      condensed
      textAlign={textAlign}
      css={[
        css`
          flex: 3;
          font-size: 32px;

          @media (max-width: 800px) {
            font-size: 24px;
          }

          @media (max-width: 500px) {
            font-size: 18px;
          }
        `,
      ]}
    >
      {shouldElipsis ? `${name.slice(0, 10)}...` : name}
    </TextNew>
  )
}
