import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorCode, hasErrorCode } from '../lib/apollo/apiError'
import {
  useAuthContext,
  useCreateCustomTokenMutation,
} from '../lib/AuthContext'

export const Tv2LoginListener = () => {
  const [createCustomTokenMutation] = useCreateCustomTokenMutation()

  const { authUser, setLoading } = useAuthContext()

  const navigate = useNavigate()

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      if (authUser && !authUser.isAnonymous) return

      createAndSigninWithCustomToken().then(() => {
        if (authUser) {
          console.log('signed in with tv2')

          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      })
    }

    async function createAndSigninWithCustomToken() {
      try {
        setLoading(true)
        const token = await getAccessTokenSilently()
        const createTokenReq = await createCustomTokenMutation({
          variables: { token },
        })

        const signInToken = createTokenReq.data?.createCustomToken

        if (signInToken) {
          await customTokenSignin(signInToken)
        }
      } catch (error) {
        setLoading(false)
        console.error(error)

        if (hasErrorCode(error, ErrorCode.EMAIL_NOT_VERIFIED)) {
          console.log('email not verified')

          navigate('/verify-email/tv2')
        } else {
          console.log('error signing in with tv2')
        }
      }

      setLoading(false)
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, authUser, navigate])

  const { customTokenSignin } = useAuthContext()
  return null
}
