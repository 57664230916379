/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { PiPlusBold } from 'react-icons/pi'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { UserAvatar } from '../../views/Avatar/UserAvatar'
import { PROFILE_PAGE_FRIEND_AVATAR_SIZE } from '../../views/Profile/UserFriendsComponent'
import {
  FriendStatus,
  useAddFriendMutation,
  useUserProfileQuery,
} from '../../views/Profile/queries'
import { useLibrarySearchQuery } from '../../views/Search/queries'
import { Button, SecondaryButton } from '../Button'
import { Link } from '../Link'
import { Loader } from '../Loader'
import { FramedClubLogo } from '../Picture'
import { ShareLinkComponent } from '../ShareLinkComponent'
import { TextInput } from '../TextInput'
import { TextNew } from '../TextNew'
import { Flex } from '../layout/Flex'
import { Modal, ModalTrigger } from '../modal/Modal'

export const AddFriendsButtonText = ({
  variant = 'white',
}: {
  variant?: 'white' | 'black'
}) => {
  const t = useT()

  const color = variant === 'white' ? colors.white : colors.black

  return (
    <ModalTrigger
      button={({ openModal }) => (
        <SecondaryButton
          onClick={openModal}
          variant={variant}
          signUpRequired
          css={css`
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 8px;
          `}
        >
          <PiPlusBold size={20} color={color} />
          <TextNew
            italic
            size={'small'}
            color={color}
            css={css`
              margin-bottom: 2px;
            `}
          >
            {t('Add friends')}
          </TextNew>
        </SecondaryButton>
      )}
      modal={({ closeModal }) => <AddFriendsModal closeModal={closeModal} />}
    />
  )
}

export const AddFriendsButtonIcon = () => {
  const t = useT()

  return (
    <ModalTrigger
      button={({ openModal }) => (
        <Button
          signUpRequired
          aria-label={t('Add friends')}
          onClick={openModal}
          css={[
            buttonReset,
            css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              flex-direction: column;
              height: 100%;
            `,
          ]}
        >
          <div
            css={css`
              position: absolute;
              bottom: 20px;
            `}
          >
            <PiPlusBold size={24} color={colors.white} />
          </div>
          <UserAvatar
            avatarData={undefined}
            height={PROFILE_PAGE_FRIEND_AVATAR_SIZE}
          />
          <TextNew
            size={'small'}
            italic
            color={colors.white}
            css={css`
              white-space: nowrap;
              text-align: center;
              padding: 4px 4px;
            `}
          >
            {t('Add friend')}
          </TextNew>
        </Button>
      )}
      modal={({ closeModal }) => <AddFriendsModal closeModal={closeModal} />}
    />
  )
}

const AddFriendsModal = ({ closeModal }: { closeModal: () => void }) => {
  const t = useT()
  return (
    <Modal closeModal={closeModal}>
      <TextNew
        condensed
        strong
        italic
        textAlign="center"
        css={css`
          font-size: 40px;
          line-height: 40px;
        `}
      >
        {t('Add friends')}
      </TextNew>
      <Spacer height={24} />
      <SearchUsersComponent />
    </Modal>
  )
}

const SearchUsersComponent = () => {
  const t = useT()
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 650, {
    minLength: 3,
  })

  const { authUser } = useAuthContext()
  const trackEvent = useGetTrackEvent()

  const userProfileQuery = useUserProfileQuery(
    authUser?.uid ?? '',
    authUser === null
  )

  const [addFriendMutation] = useAddFriendMutation()

  const searchQueryResult = useLibrarySearchQuery(debouncedSearchQuery)

  const users = searchQueryResult?.data?.librarySearch.users || []

  const loading = searchQueryResult.loading

  const noResults =
    debouncedSearchQuery.length > 2 && users.length === 0 && !loading

  const narrowScreen = window.innerWidth < 500

  const userSlug = userProfileQuery.data?.user?.slug

  const shareLink = userSlug
    ? `${window.location.origin}/profile/${userSlug}`
    : undefined

  const addFriend = async (userId: string) => {
    await addFriendMutation({
      variables: {
        input: {
          userId,
        },
      },
    })

    trackEvent(AnalyticsEventType.FriendRequest, {
      type: 'Requested',
    })
  }

  return (
    <Flex column>
      <TextInput
        autoFocus
        autoComplete="off"
        autoCorrect="off"
        value={searchQuery}
        onValue={setSearchQuery}
        placeholder={t('Search for friends...')}
        css={css`
          border-radius: 5px;
          border: 1px solid ${colors.black};
          width: 100%;
          z-index: 2;
        `}
      />

      {!loading && users.length > 0 && (
        <Flex column gap={'small'}>
          <Spacer height={16} />
          <TextNew italic textAlign="center" color={colors.grey400}>
            {t('{{count}} player', { count: users.length })}
          </TextNew>
          <Spacer height={4} />
          {users.map((user) => {
            const club = user.mainClubMembership?.club
            const friendShipStatus = user.friendStatus

            const FrienshipButton = () => {
              switch (friendShipStatus) {
                case FriendStatus.notFriend:
                  return (
                    <Button
                      onClick={() => {
                        addFriend(user.id)
                      }}
                      size="small"
                      css={css`
                        white-space: nowrap;
                      `}
                    >
                      {t('Add friend')}
                    </Button>
                  )
                case FriendStatus.requestPending:
                  return (
                    <TextNew
                      size={'small'}
                      italic
                      css={css`
                        white-space: nowrap;
                      `}
                    >
                      {t('Request pending')}
                    </TextNew>
                  )

                case FriendStatus.friend:
                  return <TextNew italic>{t('Friends')}</TextNew>
                case FriendStatus.viewer:
                  return <TextNew italic>{t('You')}</TextNew>
              }
            }
            return (
              <Flex vertical="center" gap={'small'} horizontal="space-between">
                <Link
                  underline={false}
                  to={`/profile/${user.slug}`}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    &:hover {
                      background-color: ${colors.grey100};
                    }
                  `}
                >
                  <UserAvatar avatarData={user.avatarData} height={48} />
                  <Flex column vertical="center">
                    <TextNew>{user.displayName}</TextNew>
                    {club && (
                      <Flex gap={'tiny'}>
                        <FramedClubLogo
                          size={24}
                          url={club.logoUrl}
                          shape={club.logoShape}
                        />
                        <TextNew
                          size={'small'}
                          color={colors.grey400}
                          css={css`
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: ${narrowScreen ? 18 : 25}ch;
                          `}
                        >
                          {club.name}
                        </TextNew>
                      </Flex>
                    )}
                  </Flex>
                </Link>

                <Flex vertical="center">
                  <FrienshipButton />
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      )}

      {searchQueryResult.loading && <Loader center />}

      {noResults && (
        <TextNew
          size={'small'}
          textAlign="center"
          color={colors.grey400}
          css={css`
            margin-top: 10px;
          `}
        >
          {t(`Found no results for: {{query}}`, {
            query: debouncedSearchQuery,
          })}
        </TextNew>
      )}

      {shareLink && (
        <>
          <Spacer height={40} />

          <Flex vertical="center" gap="large">
            <div
              css={css`
                height: 1px;
                width: 100%;
                background-color: ${colors.black};
              `}
            />
            <TextNew
              condensed
              strong
              textAlign="center"
              css={css`
                font-size: 40px;
                line-height: 40px;
                font-style: italic;
                padding-bottom: 8px;
              `}
            >
              {t('or invite').toUpperCase()}
            </TextNew>
            <div
              css={css`
                height: 1px;
                width: 100%;
                background-color: ${colors.black};
              `}
            />
          </Flex>
          <Spacer height={16} />

          <ShareLinkComponent
            url={shareLink}
            useShareDialog
            shareMessage={t("Let's be friends!")}
            shareCta={t('Invite')}
          />
        </>
      )}
    </Flex>
  )
}
