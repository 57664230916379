/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Text } from '../../components/Text'
import { FcQuizLogo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { UserAvatar } from '../Avatar/UserAvatar'
import { UserWithResult } from '../Challenge/components/ChallengeScoreboard'
import { ChallengeMatchData } from '../Challenge/queries'

export const MatchComponent = ({
  firstuserResults,
  secondUserResult,
  backgroundColor = colors.yellow100,
  fcQuizLogo,
  round,
  title,
  className,
}: {
  firstuserResults: UserWithResult
  secondUserResult?: UserWithResult
  backgroundColor?: string
  title?: string
  fcQuizLogo?: boolean
  round?: string
  className?: string
}) => {
  const imageSize = 50

  return (
    <Flex
      column
      horizontal="space-between"
      vertical="flex-end"
      className={className}
      css={css`
        height: 100px;
        background-color: ${backgroundColor};
        padding: 4px 16px;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 16px;
      `}
    >
      {title && !fcQuizLogo && (
        <Text
          textAlign="center"
          condensed
          strong
          css={css`
            font-size: 14px;
            line-height: 12px;
          `}
        >
          {title}
        </Text>
      )}
      {fcQuizLogo && !title && (
        <Flex horizontal="center">
          <FcQuizLogo color="black" />
        </Flex>
      )}
      <Flex horizontal="space-between">
        <UserAvatar
          height={imageSize}
          avatarData={firstuserResults.avatarData}
        />
        <Text
          extraCondensed
          strong
          css={css`
            font-size: 44px;
            line-height: 50px;
            font-style: italic;
          `}
        >{`${firstuserResults.result.correctAnswersCount ?? 0} - ${
          secondUserResult?.result.correctAnswersCount ?? 0
        }`}</Text>
        <UserAvatar
          height={imageSize}
          avatarData={secondUserResult?.avatarData}
        />
      </Flex>
      <Spacer height={2} />
      <Flex
        horizontal="space-between"
        vertical="center"
        css={css`
          padding: 4px 0;
          width: 100%;
        `}
      >
        <Text
          condensed
          strong
          css={css`
            font-size: 15px;
            line-height: 15px;
            max-width: ${round ? 35 : 45}%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
          `}
        >
          {firstuserResults?.displayName}
        </Text>

        {round && (
          <Text
            condensed
            textAlign="center"
            css={css`
              font-size: 12px;
              line-height: 12px;
              padding: 2px 8px;
              border-radius: 4px;
              background-color: ${colors.yellow200};
            `}
          >
            {round}
          </Text>
        )}
        <Text
          condensed
          strong
          textAlign="right"
          css={css`
            font-size: 15px;
            line-height: 15px;
            max-width: ${round ? 35 : 45}%;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
          `}
        >
          {secondUserResult?.displayName}
        </Text>
      </Flex>
    </Flex>
  )
}

export const ChallengeMatchesComponent = ({
  results,
  matches,
  title,
  backgroundColor,
  limit,
  viewerIsOwner,
  viewerId,
}: {
  results: UserWithResult[]
  matches: ChallengeMatchData[]
  title?: string
  backgroundColor?: string
  limit?: number
  viewerIsOwner?: boolean
  viewerId?: string
}) => {
  // if not owner, only show matches where the viewer is involved
  if (!viewerIsOwner && viewerId) {
    matches = matches.filter(
      (match) => match.homeUserId === viewerId || match.awayUserId === viewerId
    )
  }

  if (limit) {
    matches = matches.slice(0, limit)
  }
  return (
    <Flex
      column
      css={css`
        width: 100%;
      `}
    >
      {matches.map((match) => {
        const firstUserResults = results.find(
          (res) => res.userId === match.homeUserId
        )
        const secondUserResults = results.find(
          (res) => res.userId === match.awayUserId
        )

        if (!firstUserResults || !secondUserResults) {
          return null
        }

        return (
          <MatchComponent
            key={`result_${firstUserResults.userId}_vs_${secondUserResults.userId}`}
            firstuserResults={firstUserResults}
            secondUserResult={secondUserResults}
            title={title}
            backgroundColor={backgroundColor}
          />
        )
      })}
    </Flex>
  )
}
