export const colors = {
  grey100: '#F3F3F3',
  grey200: '#D3D3D3',
  grey300: '#B9B9B9',
  grey400: '#808080',
  _grey450: '#555555',
  _grey470: '#333333',
  _grey480: '#2D2D2D',
  grey500: '#202020',
  green100: '#E4FFE5',
  green200: '#C6FFC7',
  green300: '#85F49E',
  green400: '#1EBE41',
  green500: '#034B13',
  green600: '#002408',
  yellow100: '#FFFBE7',
  yellow200: '#FFF2B0',
  yellow300: '#FFE456',
  yellow400: '#FECB17',
  yellow500: '#E0A100',
  orange200: '#FFD4AC',
  orange300: '#FF9B53',
  orange400: '#EE7836',
  orange500: '#7A3300',
  red200: '#FFB8A9',
  red300: '#F38077',
  red400: '#C63C34',
  red500: '#930A02',
  pink200: '#FFDDF6',
  pink300: '#FFB7EC',
  pink400: '#B73E97',
  pink500: '#991D79',
  purple200: '#DDC1FF',
  purple300: '#B693E3',
  purple400: '#8C45E7',
  purple500: '#4C007A',
  blue100: '#E4F2FF',
  blue200: '#C7D6FF',
  blue300: '#7A9FFF',
  blue400: '#3A6DF2',
  blue500: '#003ACF',
  white: '#ffffff',
  black: '#000000',
  blackOp16: 'rgba(0, 0, 0, 0.16)',
  gold: '#FFE26D',
  silver: '#EAEAEA',
  bronze: '#FFC076',
  lightBeige: '#EEEAD2',
  dailyYellow: '#ffe500',
  dailyCareerPathGreen: '#105656',
  danger: '#C63C34',
  signalRed: '#DA0000',
}

export const boxShadows = {
  default: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  default2: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  leagueQuizItem: '0px 2px 8px rgba(0, 0, 0, 0.25)',
}

const theme = {
  colors,
  boxShadows,
}

type opacity =
  | 0
  | 0.05
  | 0.1
  | 0.15
  | 0.2
  | 0.25
  | 0.3
  | 0.33
  | 0.35
  | 0.4
  | 0.45
  | 0.5
  | 0.55
  | 0.6
  | 0.65
  | 0.66
  | 0.7
  | 0.75
  | 0.8
  | 0.85
  | 0.9
  | 0.95
  | 1

/// Ads opacity to a hex color, opacity is a number between 0 and 1
export const hexOpacity = (hex: string, opacity: opacity) => {
  const hexWithOpacity =
    hex +
    Math.round(opacity * 255)
      .toString(16)
      .toUpperCase()

  return hexWithOpacity
}

export const hexToRgba = (hex: string, opacity: opacity) => {
  const sanitizedHex = hex.replace('#', '')
  const r = parseInt(sanitizedHex.substring(0, 2), 16)
  const g = parseInt(sanitizedHex.substring(2, 4), 16)
  const b = parseInt(sanitizedHex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default theme
