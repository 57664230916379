export enum ErrorCode {
  USERNAME_NOT_AVAILABLE = 'USERNAME_NOT_AVAILABLE',
  USERNAME_INVALID = 'USERNAME_INVALID',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  INVALID_INVITATION_CODE = 'INVALID_INVITATION_CODE',
  QUIZ_ALREADY_STARTED = 'QUIZ_ALREADY_STARTED',
  QUIZ_NOT_FOUND = 'QUIZ_NOT_FOUND',
  QUIZ_ALREADY_ADDED = 'QUIZ_ALREADY_ADDED',
  ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
  NOT_FOUND = 'NOT_FOUND',
  MAX_CAPACITY_REACHED = 'MAX_CAPACITY_REACHED',
  SLUG_ALREADY_EXISTS = 'SLUG_ALREADY_EXISTS',
}

// error is Error, ApolloError or GraphQLError
export const getErrorCodes = (error: any) => {
  const errorCodes: ErrorCode[] = []

  try {
    const errors = [
      ...(error?.graphQLErrors ?? []),
      ...(error?.networkError?.result?.errors ?? []),
    ]

    errors.forEach((error: any) => {
      if (error?.extensions?.errorCode) {
        errorCodes.push(error?.extensions?.errorCode)
      }
    })
  } catch (error) {
    console.error('getErrorCodes', error)
  }

  return errorCodes
}

export const hasErrorCode = (error: any, errorCode: ErrorCode) => {
  return getErrorCodes(error).includes(errorCode)
}
