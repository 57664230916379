/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { Loader } from '../../components/Loader'
import { margin } from '../../styles/margin'
import theme from '../../styles/theme'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import { useGetLivePlayQuizInstanceQuery } from '../LiveQuiz/queries'
import { PlayLiveQuiz } from './PlayLiveQuiz'
import { useInterval } from '../../utils/useInterval'
import { useActiveCampaignForLeague } from '../../lib/sanity/sanity'

// poll interval should be in the range of 1500 to 2500 ms
const getPollInterval = () => {
  const interval = Math.floor(Math.random() * 1000) + 1500
  return interval
}

const Content = ({ instanceId }: { instanceId: string }) => {
  const [shouldPoll, setShouldPoll] = useState(true)
  const { setCampaign, setLeagueId, setQuizId, setInstanceId } =
    useLeagueQuizContext()

  const [pollInterval, setPollInterval] = useState<number>(2000)

  const query = useGetLivePlayQuizInstanceQuery(instanceId)

  const [quizSeries, setQuizSeries] = useState<string | undefined>()
  const instanceData = query.data?.getLivePlayQuizInstance
  const { data: campaign } = useActiveCampaignForLeague(quizSeries)

  // For fairness, we want to poll at a random interval
  // Users get a random interval every 10 seconds
  useInterval(() => {
    const newInterval = getPollInterval()

    query.stopPolling()

    query.startPolling(newInterval)

    setPollInterval(newInterval)
  }, 10 * 1000)

  useEffect(() => {
    if (instanceData) {
      setLeagueId(instanceData.leagueQuiz.league.id)
      setQuizId(instanceData?.leagueQuiz.id)
      setInstanceId(instanceData.id)
      setQuizSeries(instanceData.leagueQuiz.league.id)
    }
  }, [instanceData?.leagueQuiz])

  useEffect(() => {
    if (shouldPoll) {
      query.startPolling(pollInterval)
    } else {
      query.stopPolling()
    }
  }, [shouldPoll])

  useEffect(() => {
    if (campaign) {
      setCampaign(campaign)
    }
  }, [campaign, setCampaign])

  if (query.loading) {
    return (
      <Page>
        <Flex horizontal="center" css={margin.top('huge')}>
          <Loader variant="green" />
        </Flex>
      </Page>
    )
  }
  if (!instanceData || query.error) {
    const reason = !instanceData ? 'no instance' : query.error?.message

    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`LiveQuizPage | instanceId: ${instanceId}, ${reason}`}
        />
      </Page>
    )
  }

  return (
    <Flex
      column
      grow
      css={css`
        overflow-x: hidden;
      `}
    >
      <QuizAd
        campaign={campaign}
        isDailyCareerPath={
          instanceData.leagueQuiz.league.slug === 'the-daily-career-path'
        }
        isEmbed={false}
      />

      <PlayLiveQuiz quizInstance={instanceData} setShouldPoll={setShouldPoll} />
    </Flex>
  )
}

const PlayLiveQuizPage = () => {
  const instanceId = useParams<{ instanceId: string }>().instanceId!

  return (
    <LeagueQuizContextProvider>
      <Layout
        hideHeaderPadding
        hideNavbar
        backgroundColor={theme.colors.grey100}
        css={css`
          height: 100%;
        `}
      >
        <Content instanceId={instanceId} />
      </Layout>
    </LeagueQuizContextProvider>
  )
}
export default PlayLiveQuizPage
