/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HideAfterDelay } from '../../components/HideAfterDelay'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { useJoinClubMutation, viewerClubsQuery } from './queries'

export const AcceptInvite = () => {
  const t = useT()
  const trackEvent = useGetTrackEvent()
  const [joinClub, { error, loading, data }] = useJoinClubMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const invitation = searchParams.get('invitation') as string
  const [unauthorized, setUnauthorized] = useState<boolean>(false)
  const { authUser } = useAuthContext()

  const apolloClient = useApolloClient()

  const navigate = useNavigate()

  useEffect(() => {
    if (!authUser || authUser?.isAnonymous) {
      setUnauthorized(true)
      return
    }
    if (typeof invitation === 'string' && invitation) {
      setSearchParams(new URLSearchParams()) // clear search params

      joinClub({
        variables: {
          input: {
            invitationCode: invitation,
          },
        },
      })
        .then((res) => {
          const clubId = res?.data?.joinClub.id
          console.log('clubId', res)
          apolloClient.refetchQueries({ include: [viewerClubsQuery] })
          trackEvent(AnalyticsEventType.ClubJoined, {
            clubId: clubId,
            type: 'invitation-link',
          })
          const clubSlug = res?.data?.joinClub.slug
          navigate(`/clubs/${clubSlug}`, { replace: true })
        })
        .catch((e) => console.error('Failed to join via url', e))
    }
  }, [joinClub, invitation])

  if (loading) {
    return <Loader variant="blue" size={20} css={margin.bottom()} />
  }

  if (typeof invitation === 'string' && invitation && unauthorized) {
    return (
      <>
        <ErrorMessage>{t('Sign up to participate')}</ErrorMessage>

        <ModalTrigger
          forceIsOpen
          button={({ openModal }) => (
            <PrimaryButton
              onClick={openModal}
              css={css`
                height: 50px;
              `}
            >
              <Text size="huge" condensed color="white">
                {t('Sign up / Log in')}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner
              closeModal={closeModal}
              destinationUrl={window.location.href}
            />
          )}
        />
        <Spacer height={20} />
      </>
    )
  }

  if (error) {
    return (
      <HideAfterDelay delayMs={10000}>
        <ErrorMessage>{error.message}</ErrorMessage>
        <Spacer height={20} />
      </HideAfterDelay>
    )
  }

  if (data) {
    return (
      <HideAfterDelay delayMs={5000}>
        <Text color="white" css={margin.bottom()}>
          {t('Invitation accepted')}
        </Text>
      </HideAfterDelay>
    )
  }

  return null
}
