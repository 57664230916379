/** @jsxImportSource @emotion/react */

import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import { useT } from '../../lib/i18n/useT'
import { IconButton } from '../Button'
import { Tv2Icon } from '../icons'

export const Tv2LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  const signinWithTv2 = async () => {
    await loginWithRedirect()
  }

  const t = useT()
  return (
    <IconButton
      noWrap
      onClick={signinWithTv2}
      iconLeft
      rounded
      css={css`
        background-color: #200744;
        min-height: 48px;
        padding-right: 8px;
      `}
      text={t('Log in with your TV 2 account')}
      icon={<Tv2Icon height={22} />}
    />
  )
}
