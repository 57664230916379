/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, PrimaryButton, SecondaryButton } from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { Divider } from '../../components/Divider'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { CheckSmall } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { useAuthContext } from '../../lib/AuthContext'
import { useNotificationContext } from '../../lib/NotificationContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { UserAvatar } from '../Avatar/UserAvatar'
import { ClubItem } from '../Home/ClubItem'
import { FriendRequestList } from './FriendRequestList'
import { UserBadges } from './UserBadges'
import { UserClubMemberships } from './UserClubMemberships'
import { UserFriendsComponent } from './UserFriendsComponent'
import { UserStatsComponent } from './UserStatsComponent'
import {
  FriendStatus,
  useAcceptFriendRequestMutation,
  useAddFriendMutation,
  useRejectFriendRequestMutation,
  useRemoveFriendMutation,
  userProfileQuery,
  useUserProfileQuery,
} from './queries'
import { VerifyEmailComponent } from '../../components/VerifyEmailComponent'

const RemoveFriendModal = ({
  closeModal,
  friendId,
  friendName,
}: {
  closeModal: () => void
  friendId: string
  friendName?: string
}) => {
  const [removeFriend] = useRemoveFriendMutation()
  const t = useT()

  return (
    <Modal closeModal={closeModal}>
      <Flex column gap="medium">
        <Text>
          {t(`Do you want to unfriend {{username}}?`, {
            username: friendName,
          })}
        </Text>
        <PrimaryButton
          css={css`
            min-height: 48px;
          `}
          variant="black"
          onClick={async () => {
            await removeFriend({
              variables: {
                input: {
                  userId: friendId,
                },
              },
            })
            closeModal()
          }}
        >
          <Text color={colors.white}>{t(`Remove friend`)}</Text>
        </PrimaryButton>
        <SecondaryButton
          css={css`
            min-height: 48px;
          `}
          variant="black"
          onClick={closeModal}
        >
          <Text>{t(`Cancel`)}</Text>
        </SecondaryButton>
      </Flex>
    </Modal>
  )
}
const CancelRequestModal = ({
  closeModal,
  friendId,
  friendName,
}: {
  closeModal: () => void
  friendId: string
  friendName?: string
}) => {
  const [removeFriend] = useRemoveFriendMutation()
  const t = useT()

  return (
    <Modal closeModal={closeModal}>
      <Flex column gap="medium">
        <Text>
          {t(`Do you want to cancel the friend request to {{username}}?`, {
            username: friendName,
          })}
        </Text>
        <PrimaryButton
          css={css`
            min-height: 48px;
          `}
          variant="black"
          onClick={async () => {
            await removeFriend({
              variables: {
                input: {
                  userId: friendId,
                },
              },
            })
            closeModal()
          }}
        >
          <Text color={colors.white}>{t(`Cancel request`)}</Text>
        </PrimaryButton>
        <SecondaryButton
          css={css`
            min-height: 48px;
          `}
          variant="black"
          onClick={closeModal}
        >
          <Text>{t(`Back`)}</Text>
        </SecondaryButton>
      </Flex>
    </Modal>
  )
}

const Content = ({ userSlug }: { userSlug: string }) => {
  const apolloClient = useApolloClient()
  const trackEvent = useGetTrackEvent()
  const t = useT()
  const navigate = useNavigate()
  const { authUser } = useAuthContext()
  const authUserId = authUser?.uid
  const { isNativeApp } = useNativeApp()

  const [addFriendLoading, setAddFriendLoading] = useState(false)

  const query = useUserProfileQuery(userSlug)
  const { unseenNotifications, refreshNotifications } = useNotificationContext()

  const [addFriend] = useAddFriendMutation()
  const [acceptFriendRequest] = useAcceptFriendRequestMutation()
  const [rejectFriendRequest] = useRejectFriendRequestMutation()

  useEffect(() => {
    if (query.data?.user) {
      if (query.data.user.id != authUser?.uid) {
        document.title = `fcQuiz | ${query.data.user.displayName}`
      }

      // update url if needed | slugs are prettier than ids
      if (window.location.pathname !== `/profile/${query.data.user.slug}`) {
        navigate(`/profile/${query.data.user.slug}`, { replace: true })
      }
    }
  }, [query.loading, query.data])

  if (query.loading && !query.data) {
    return (
      <Flex horizontal="center" css={margin.top('huge')}>
        <Loader />
      </Flex>
    )
  }
  if (!query.data?.user || query.error) {
    const reason = !query.data?.user ? 'no user data' : query.error?.message
    return (
      <DefaultError
        css={margin.top('huge')}
        sentryErrorMessage={`ProfilePage | userSlug: ${userSlug}, ${reason}`}
      />
    )
  }

  const user = query.data.user
  const isViewer = user.id === authUserId

  const friendRequests = user.friendRequests ?? []

  // Allows user to cancel friend request if they have already sent one
  const hasFriendRequestFromUser =
    !isViewer && friendRequests.some((request) => request.id === authUserId)

  const FriendStatusButton = () => {
    if (addFriendLoading) {
      return (
        <PrimaryButton signUpRequired variant="white">
          <Flex vertical="center" gap="small">
            <Text>{t(`Loading`) + '...'}</Text>
          </Flex>
        </PrimaryButton>
      )
    }

    switch (user.friendStatus) {
      case FriendStatus.friend:
        return (
          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton variant="white" onClick={openModal}>
                <Flex vertical="center" gap="small">
                  <CheckSmall />
                  <Text>{t(`Friends`)}</Text>
                </Flex>
              </PrimaryButton>
            )}
            modal={({ closeModal }) => (
              <RemoveFriendModal
                closeModal={closeModal}
                friendId={user.id}
                friendName={user.displayName || user.username}
              />
            )}
          />
        )
      case FriendStatus.notFriend:
        return (
          <PrimaryButton
            signUpRequired
            variant="white"
            onClick={async () => {
              setAddFriendLoading(true)
              await addFriend({
                variables: {
                  input: {
                    userId: user.id,
                  },
                },
              })
              trackEvent(AnalyticsEventType.FriendRequest, {
                type: 'Requested',
              })

              await apolloClient.refetchQueries({ include: [userProfileQuery] })

              setAddFriendLoading(false)
            }}
          >
            <Flex vertical="center" gap="small">
              <BiPlus />
              <Text>{t(`Add friend`)}</Text>
            </Flex>
          </PrimaryButton>
        )
      case FriendStatus.requestPending:
        if (!hasFriendRequestFromUser) {
          return (
            <Flex gap="medium" wrap>
              <PrimaryButton
                variant="white"
                onClick={() => {
                  acceptFriendRequest({
                    variables: {
                      input: {
                        userId: user.id,
                      },
                    },
                  })
                  trackEvent(AnalyticsEventType.FriendRequest, {
                    type: 'Accepted',
                  })
                  refreshNotifications()
                }}
              >
                {t('Accept')}
              </PrimaryButton>
              <SecondaryButton
                variant="white"
                onClick={() => {
                  rejectFriendRequest({
                    variables: {
                      input: {
                        userId: user.id,
                      },
                    },
                  })
                  trackEvent(AnalyticsEventType.FriendRequest, {
                    type: 'Rejected',
                  })
                }}
              >
                {t('Ignore')}
              </SecondaryButton>
            </Flex>
          )
        }

        return (
          <ModalTrigger
            button={({ openModal }) => (
              <SecondaryButton variant="white" onClick={openModal}>
                <Flex vertical="center" gap="small">
                  <Text color={colors.white}>{t(`Request pending`)}</Text>
                </Flex>
              </SecondaryButton>
            )}
            modal={({ closeModal }) => (
              <CancelRequestModal
                closeModal={closeModal}
                friendId={user.id}
                friendName={user.displayName || user.username}
              />
            )}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <PageContent>
        <Flex column horizontal="center">
          {isViewer && (
            <PrimaryButton
              variant="grey"
              onClick={() => {
                navigate('/profile/edit')
              }}
              css={css`
                padding: 10px 10px;
                display: flex;
                position: absolute;
                right: 30px;
              `}
            >
              <img src="/Cog.svg" />
            </PrimaryButton>
          )}
          <Button
            resetStyling
            disabled={!isViewer}
            onClick={
              isViewer
                ? () => {
                    navigate('/profile/avatar')
                  }
                : undefined
            }
          >
            <UserAvatar
              clickable={false}
              avatarData={user.avatarData}
              showExport={!isNativeApp}
            />
          </Button>
          <Flex
            column
            css={css`
              width: 100%;
            `}
            horizontal="center"
          >
            <Heading
              level={1}
              looksLikeLevel={2}
              color="white"
              css={css`
                font-size: 30px;
                ${margin.top()}
              `}
              italic
              wide
            >
              {user.displayName}
            </Heading>
            {user.displayName != user.username && (
              <Text
                size="small"
                color={colors.grey300}
                css={css`
                  text-align: center;
                `}
              >
                {`@${user.username}`}
              </Text>
            )}
            {user.mainClubMembership && (
              <ClubItem
                club={user.mainClubMembership.club}
                variant="green"
                size="huge"
              />
            )}

            <Spacer height={8} />
            {user.stats && (
              <UserStatsComponent
                stats={user.stats}
                css={margin.vertical('large')}
              />
            )}

            {isViewer && (
              <>
                <VerifyEmailComponent
                  css={[
                    css`
                      max-width: 500px;
                      margin: 0 auto 24px auto;
                      border: 1px solid ${colors.white};
                    `,
                  ]}
                />
                <Flex gap={1} vertical="center">
                  <object
                    css={css`
                      width: 30px;
                    `}
                    data={'/Coin.svg'}
                  />
                  <TextNew
                    css={css`
                      margin-top: -2px;
                    `}
                    size={'huge'}
                    id="viewerCoins"
                    color={'white'}
                  >
                    {user.coins ?? 0}
                  </TextNew>
                </Flex>
                <Spacer height={8} />
              </>
            )}
            <Flex gap="small">
              <FriendStatusButton />
            </Flex>
          </Flex>
          {isViewer && (
            <>
              <Spacer height={8} />
              <Flex
                css={[
                  css`
                    width: 100%;
                  `,
                ]}
                gap="medium"
              >
                <PrimaryButton
                  variant="white"
                  onClick={() => {
                    navigate('/profile/avatar')
                  }}
                  css={css`
                    flex: 1;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                  `}
                >
                  <Flex vertical="center" gap="small">
                    <img
                      src="/PencilSimple.svg"
                      css={css`
                        filter: invert(1);
                      `}
                    />
                    <TextNew size={'small'} strong>
                      {t(`Edit Avatar`)}
                    </TextNew>
                  </Flex>
                </PrimaryButton>
                <PrimaryButton
                  variant="white"
                  onClick={() => {
                    navigate(`/profile/notifications`)
                  }}
                  css={css`
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                  `}
                >
                  {unseenNotifications > 0 && (
                    <Flex
                      css={css`
                        width: 20px;
                        height: 20px;
                        border-radius: 999px;
                        background-color: ${colors.signalRed};
                        position: absolute;
                        right: -10px;
                        top: -10px;
                      `}
                      vertical="center"
                      horizontal="center"
                    >
                      <TextNew size="small" color="white">
                        {unseenNotifications}
                      </TextNew>
                    </Flex>
                  )}
                  <Flex vertical="center" gap="small">
                    <img
                      css={css`
                        width: 30px;
                      `}
                      src={'/Bell.svg'}
                    />
                    <TextNew size={'small'} strong>
                      {t(`Notifications`)}
                    </TextNew>
                  </Flex>
                </PrimaryButton>
              </Flex>
            </>
          )}
        </Flex>
      </PageContent>
      <Spacer height={20} />

      {isViewer && friendRequests.length > 0 && (
        <PageContent>
          <FriendRequestList friendRequests={friendRequests} />
        </PageContent>
      )}

      <PageContent css={margin.vertical('large')}>
        <UserFriendsComponent
          friends={user.friends}
          showAddFriendsButton={isViewer}
        />
      </PageContent>

      <Flex horizontal="center">
        <Divider
          color={colors.grey500}
          css={css`
            max-width: ${720}px;
          `}
        />
      </Flex>

      <PageContent css={margin.vertical('large')}>
        <UserClubMemberships userSlug={userSlug} isViewer={isViewer} />
      </PageContent>

      <Flex horizontal="center">
        <Divider
          color={colors.grey500}
          css={css`
            max-width: ${720}px;
          `}
        />
      </Flex>

      <Spacer height={16} />
      <PageContent>
        <UserBadges badges={user.badges} />
      </PageContent>
    </>
  )
}

export const ProfilePage = () => {
  const t = useT()
  const userSlug = useParams<'userSlug'>().userSlug!
  const backgroundImagePath = '/logo-green.png'

  return (
    <Layout
      title={`fcQuiz | ${t('Profile')}`}
      withHeader
      withFooter
      backgroundColor="black"
      showCookiebotButton
      hideAvatar
      hideHeaderMobile
      css={css`
        header > * {
          padding-bottom: 0px;
        }
      `}
    >
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 50vh;
          max-height: 500px;
          z-index: -1;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 1px;
            background-image: url(${backgroundImagePath});
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: -1;
          `}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background: linear-gradient(rgba(0, 0, 0, 0.66), #000);
          `}
        />
      </div>
      <Content userSlug={userSlug!} />
    </Layout>
  )
}
