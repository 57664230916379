import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import * as React from 'react'
import { isProduction, rootApiUrl } from '../../config/config'
import { auth as firebaseAuth } from '../firebase'
import { getCurrentLocale } from '../i18n/locale'
import { typePolicies } from './typePolicies'

const AuthorizedApolloProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const getToken = async (): Promise<string | undefined> => {
    try {
      const firebaseToken = await firebaseAuth.currentUser?.getIdToken()

      return firebaseToken
    } catch (error) {
      console.error(`Error getting session token.`)
      return undefined
    }
  }

  const withToken = setContext(async () => {
    const token = await getToken()

    return { token }
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    const { token } = operation.getContext()
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }))
    return forward(operation)
  })

  const localeMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Locale: getCurrentLocale(),
      },
    }))
    return forward(operation)
  })

  const devLogger = new ApolloLink((operation, forward) => {
    if (process.env.NODE_ENV === 'development') {
      const operationType = (operation.query.definitions[0] as any)?.operation
      if (operationType === 'mutation') {
        console.log(
          `[mutation] ${operation.operationName} ${JSON.stringify(
            operation.variables
          )}`
        )
      }
      if (operationType === 'query') {
        console.log(
          `[query] ${operation.operationName} ${JSON.stringify(
            operation.variables
          )}`
        )
      }
    }

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    ;[...(graphQLErrors ?? []), networkError].forEach((error) => {
      Sentry.captureMessage(JSON.stringify(error), {
        extra: { graphqlOperationName: operation.operationName },
      })
    })

    if (process.env.NODE_ENV === 'development') {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error] Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          )
        })
      }

      if (networkError) {
        console.log(`[Network error] ${networkError}`)
        if (isProduction) {
          Sentry.captureEvent({
            message: `NETWORK ERROR: ${JSON.stringify(networkError)}`,
            level: 'error',
            extra: { graphqlOperationName: operation.operationName },
          })
        }
      }
    }
  })

  const customFetch: typeof fetch = (_, options) => {
    const operationName = options?.body
      ? (JSON.parse(options.body.toString()).operationName as
          | string
          | undefined)
      : undefined

    return fetch(
      `${rootApiUrl()}/graphql?name=${operationName ?? 'unknown'}`,
      options
    )
  }

  const httpLink = createHttpLink({
    fetch: customFetch,
  })

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: ApolloLink.from([
      withToken,
      authMiddleware,
      localeMiddleware,
      devLogger,
      errorLink,
      // retryLink,
      httpLink,
    ]),
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
