import { ReactComponent as ChestA } from './Chest A.svg'
import { ReactComponent as ChestB } from './Chest B.svg'
import { ReactComponent as ChestC } from './Chest C.svg'
import { ReactComponent as ChestD } from './Chest D.svg'
import { ReactComponent as ChestE } from './Chest E.svg'
import { ReactComponent as ChestF } from './Chest F.svg'
import { ReactComponent as ChestG } from './Chest G.svg'
import { ReactComponent as ChestH } from './Chest H.svg'
import { ReactComponent as ChestI } from './Chest I.svg'
import { ReactComponent as ChestJ } from './Chest J.svg'
import { ReactComponent as ChestK } from './Chest K.svg'
import { ReactComponent as ChestL } from './Chest L.svg'
import { ReactComponent as ChestM } from './Chest M.svg'
import { ReactComponent as ChestN } from './Chest N.svg'
import { ReactComponent as ChestO } from './Chest O.svg'
import { ReactComponent as ChestTIL } from './Chest TIL.svg'

export const chestStyles = {
  ChestA: {
    icon: <ChestA />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestB: {
    icon: <ChestB />,
    usedColors: 3, //Hacky fix to make one line
    width: 30,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: false,
  },

  ChestC: {
    icon: <ChestC />,
    usedColors: 2,
    width: 50,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestD: {
    icon: <ChestD />,
    usedColors: 2,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestE: {
    icon: <ChestE />,
    usedColors: 2,
    width: 21,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestF: {
    icon: <ChestF />,
    usedColors: 2,
    width: 100,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestG: {
    icon: <ChestG />,
    usedColors: 2,
    width: 150,
    centerIndex: undefined,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ChestH: {
    icon: <ChestH />,
    usedColors: 3,
    width: 30,
    centerIndex: 1,
    rotation: -45,
    offset: 0,
    premium: false,
  },
  ChestI: {
    icon: <ChestI />,
    usedColors: 3,
    width: 50,
    centerIndex: 1,
    rotation: -45,
    offset: 0,
    premium: false,
  },
  ChestJ: {
    icon: <ChestJ />,
    usedColors: 2,
    width: 40,
    centerIndex: 0,
    rotation: -45,
    offset: 0,
    premium: false,
  },
  ChestK: {
    icon: <ChestK />,
    usedColors: 2,
    width: 26,
    centerIndex: 1,
    rotation: -45,
    offset: 0,
    premium: false,
  },
  ChestL: {
    icon: <ChestL />,
    usedColors: 3,
    width: 50,
    centerIndex: undefined,
    rotation: 90,
    offset: 0,
    premium: false,
  },
  ChestM: {
    icon: <ChestM />,
    usedColors: 2,
    width: 150,
    centerIndex: undefined,
    rotation: -45,
    offset: 0,
    premium: false,
  },
  ChestN: {
    icon: <ChestN />,
    usedColors: 2,
    width: 150,
    centerIndex: undefined,
    rotation: -90,
    offset: -17,
    premium: false,
  },
  ChestO: {
    icon: <ChestO />,
    usedColors: 2,
    width: 45,
    centerIndex: 0,
    rotation: -90,
    offset: 5,
    premium: false,
  },
  ChestTIL: {
    icon: <ChestTIL />,
    usedColors: 0,
    width: 21,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: true,
  },
  ChestThai: {
    icon: <ChestA />,
    usedColors: 0,
    width: 21,
    centerIndex: 1,
    rotation: 0,
    offset: 0,
    premium: true,
  },
}
