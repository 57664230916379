import { gql, useMutation, useQuery } from '@apollo/client'
import { viewerQuery } from '../../lib/AuthContext'
import { userProfileQuery } from '../Profile/queries'

const updateUsernameMutation = gql`
  mutation updateUsername($username: String!) {
    updateUsername(username: $username) {
      id
      slug
      username
      displayName
      profileNeedsUpdate
    }
  }
`

export const useUpdateUsernameMutation = () =>
  useMutation<any, { username: string }>(updateUsernameMutation, {
    refetchQueries: [viewerQuery, userProfileQuery],
    update: (cache, { data }) => {
      try {
        if (data?.updateUsername) {
          cache.modify({
            id: cache.identify(data.updateUsername.id),
            fields: {
              slug: () => data.updateUsername.slug,
              username: () => data.updateUsername.username,
              displayName: () => data.updateUsername.displayName,
              profileNeedsUpdate: () => data.updateUsername.profileNeedsUpdate,
            },
          })
        }
      } catch (_) {
        console.log(`Error updating username`)
      }
    },
  })

export const useUpdateProfilePictureUriMutation = () => {
  return useMutation<any, { input: { profilePictureUri: string } }>(gql`
    mutation updateProfilePictureUri($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        profilePictureUrl
      }
    }
  `)
}

export const useUpdateProfileMutation = () => {
  return useMutation<
    any,
    { input: { locale?: string; username?: string } }
  >(gql`
    mutation updateUserProfile($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        locale
        username
      }
    }
  `)
}

// Notification settings
export enum UserNotificationSetting {
  emailNewLeagueQuiz = 'emailNewLeagueQuiz',
  emailLeagueQuizSummary = 'emailLeagueQuizSummary',
  emailLeagueQuizReminder = 'emailLeagueQuizReminder',
  pushNotifications = 'pushNotifications',
}

export type NotificationSettings = {
  emailNewLeagueQuiz: boolean
  emailLeagueQuizSummary: boolean
  emailLeagueQuizReminder: boolean
  pushNotifications: boolean
}

const notificationSettingsQuery = gql`
  query notificationSettings {
    viewer {
      id
      notificationSettings {
        id
        emailNewLeagueQuiz
        emailLeagueQuizSummary
        emailLeagueQuizReminder
        pushNotifications
      }
    }
  }
`
export const useNotificationSettings = () => {
  return useQuery<{ viewer?: { notificationSettings: NotificationSettings } }>(
    notificationSettingsQuery
  )
}

const setNotificationSettingMutation = gql`
  mutation setNotificationSetting($input: NotificationSettingInput!) {
    setNotificationSetting(input: $input) {
      id
      notificationSettings {
        id
        emailNewLeagueQuiz
        emailLeagueQuizSummary
        emailLeagueQuizReminder
        pushNotifications
      }
    }
  }
`

export const useSetNotificationSettingMutation = () =>
  useMutation<
    unknown,
    { input: { setting: UserNotificationSetting; value: boolean } }
  >(setNotificationSettingMutation)
