import { gql, useMutation, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { Locale } from '../../lib/i18n/locale'
import { PublicLeague } from '../Embed/types'
import {
  LeaderboardItem,
  leagueLeaderboardItemFragment,
} from '../League/queries'
import { LeagueQuiz } from '../LeagueQuiz/queries'
import { User } from '../Profile/queries'
import { GlobalLeaderboardType } from '../Leaderboards/queries'

export type ChallengeGroup = {
  id: string
  title?: string
  slug: string
  description?: string
  quizzes: LeagueQuiz[]
  members: User[]
  viewerIsOwner?: boolean
  viewerCanEdit?: boolean
  versusInvitation?: string | undefined
  currentQuiz?: LeagueQuiz | undefined
  matches: ChallengeMatchData[]
  invitation?: string | undefined
  isChallengeLeague?: boolean | undefined
  subscribedLeagues: PublicLeague[]
  viewerIsMember?: boolean | undefined
  iconImageUrl?: string | undefined
  upcomingQuiz?: ChallengeUpcomingQuiz | undefined
  activeQuizzes: LeagueQuiz[]
  backgroundColor: string
  textColor: string
  owners: User[]
  backgroundImageUrl?: string | undefined
  hasRounds?: boolean | undefined
}

export type ChallengeLeagueMinimal = {
  id: string
  title?: string
  slug: string
  description?: string
  backgroundColor: string
  textColor: string
  iconImageUrl?: string
  backgroundImageUrl?: string
  locale: Locale
}

export type ChallengeLeague = ChallengeLeagueMinimal & {
  quizzes: LeagueQuiz[]
  members?: User[]
  memberCount: number
  viewerIsOwner?: boolean
  viewerCanEdit?: boolean
  versusInvitation?: string | undefined
  currentQuiz?: LeagueQuiz | undefined
  leaderboard: ChallengeGroupLeaderboardEntry[]
  invitation?: string | undefined // TODO: remove from league
  isChallengeLeague?: boolean | undefined
  subscribedLeagues?: PublicLeague[]
  viewerIsMember?: boolean | undefined
  upcomingQuiz?: ChallengeUpcomingQuiz | undefined
  activeQuizzes: LeagueQuiz[]
  owners: User[]
  hasRounds?: boolean | undefined
  activeLiveQuizzes: LeagueQuiz[]
  previousLiveQuizzes?: {
    id: string
    participantCount: number
    invitationCode: string
    startTime: string
    quiz?: {
      id: string
      title: string
      slug: string
    }
  }[]
  ongoingLiveQuizzes?: {
    id: string
    host?: User
    participantCount: number
    invitationCode: string
    startTime: string
    quiz?: {
      id: string
      title: string
      slug: string
    }
  }[]
}

export type ChallengeGroupResult = {
  userId: string
  leagueQuizInstanceId?: string | undefined
  correctAnswersCount?: number | undefined
  correctAnswerScore?: number | undefined
  timeBonus?: number | undefined
  totalScore?: number | undefined
}

export type ChallengeMatchData = {
  id: string
  challengeGroupId?: string
  quizId: string
  homeUserId: string
  awayUserId: string
  isChallengeLeague?: boolean
}

export type ChallengeUpcomingQuiz = {
  id: string
  title: string
  embedPromotionalText: string
  availableFrom: string
  viewerCanStartQuiz: boolean
  availableTo: string
}

export const challengeGroupFragment = gql`
  fragment ChallengeGroupFragment on ChallengeGroup {
    id
    title
    slug
    description
    invitation
    isChallengeLeague
    backgroundColor
    textColor
    subscribedLeagues {
      id
      title
      slug
      frequency
      imageUrl
      currentQuiz {
        id
        title
        embedPromotionalText
        availableFrom
        viewerCanStartQuiz
        availableTo
      }
    }
    quizzes {
      id
      title
      slug
      viewerCanStartQuiz
      imageUrl
      status
      questionsCount
      participantCount
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
      }
      challengeGroupResults {
        userId
        leagueQuizInstanceId
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      challengeGroupId
    }
    memberCount
    members {
      id
      username
      slug
      avatarData
      displayName
    }
    viewerIsMember
    viewerIsOwner
    viewerCanEdit
    matches {
      id
      quizId
      challengeGroupId
      homeUserId
      awayUserId
    }
    isChallengeLeague
    iconImageUrl
    backgroundImageUrl
    upcomingQuiz {
      id
      title
      embedPromotionalText
      availableFrom
      viewerCanStartQuiz
      availableTo
    }
    activeQuizzes {
      id
      challengeGroupId
      title
      viewerCanStartQuiz
      availableFrom
      availableTo
      viewerData {
        activeQuizInstance {
          id
        }
      }
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
        imageUrl
        leagueCardImageUrl
      }
      challengeGroupResults {
        userId
        leagueQuizInstanceId
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
    }
    owners {
      id
      username
      slug
      avatarData
      displayName
    }
    hasRounds
  }
`
export const challengeLeagueOwnerFragment = gql`
  fragment ChallengeLeagueOwnerFragment on ChallengeGroup {
    id
    title
    slug
    locale
    description
    isChallengeLeague
    backgroundColor
    textColor
    subscribedLeagues {
      id
      title
      slug
      frequency
      imageUrl
      currentQuiz {
        id
        title
        embedPromotionalText
        availableFrom
        viewerCanStartQuiz
        availableTo
      }
    }
    memberCount
    viewerIsMember
    viewerIsOwner
    viewerCanEdit
    isChallengeLeague
    iconImageUrl
    backgroundImageUrl
    upcomingQuiz {
      id
      title
      embedPromotionalText
      availableFrom
      viewerCanStartQuiz
      availableTo
    }
    activeQuizzes {
      id
      challengeGroupId
      title
      viewerCanStartQuiz
      availableFrom
      availableTo
      viewerData {
        activeQuizInstance {
          id
        }
      }
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
        imageUrl
        leagueCardImageUrl
      }
    }
    activeLiveQuizzes {
      id
      challengeGroupId
      title
      viewerCanStartQuiz
      questionsCount
      availableFrom
      availableTo
      viewerData {
        activeQuizInstance {
          id
        }
      }
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
        imageUrl
        leagueCardImageUrl
      }
    }
    owners {
      id
      username
      slug
      avatarData
      displayName
    }
    hasRounds
  }
`
export const challengeLeagueFragment = gql`
  fragment ChallengeLeagueFragment on ChallengeGroup {
    id
    title
    slug
    locale
    description
    isChallengeLeague
    backgroundColor
    textColor
    memberCount
    viewerIsMember
    viewerIsOwner
    viewerCanEdit
    isChallengeLeague
    iconImageUrl
    backgroundImageUrl
    upcomingQuiz {
      id
      title
      embedPromotionalText
      availableFrom
      viewerCanStartQuiz
      availableTo
    }
    activeQuizzes {
      id
      challengeGroupId
      title
      viewerCanStartQuiz
      availableFrom
      availableTo
      quizType
      viewerData {
        activeQuizInstance {
          id
        }
      }
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
        imageUrl
        leagueCardImageUrl
      }
    }
    activeLiveQuizzes {
      id
      challengeGroupId
      title
      viewerCanStartQuiz
      availableFrom
      availableTo
      viewerData {
        activeQuizInstance {
          id
        }
      }
      league {
        id
        slug
        title
        frequency
        viewerIsSubscribed
        imageUrl
        leagueCardImageUrl
      }
    }
    owners {
      id
      username
      slug
      avatarData
      displayName
    }
    hasRounds
  }
`

export const challengeGroupQuery = gql`
  query challengeGroup($challengeGroupId: ID!) {
    group(id: $challengeGroupId) {
      ...ChallengeGroupFragment
    }
  }

  ${challengeGroupFragment}
`
export const challengeLeagueownerQuery = gql`
  query challengeGroup($challengeGroupId: ID!) {
    group(id: $challengeGroupId) {
      ...ChallengeLeagueOwnerFragment
    }
  }

  ${challengeLeagueOwnerFragment}
`

export const challengeLeagueQuery = gql`
  query challengeGroup($challengeGroupId: ID!) {
    group(id: $challengeGroupId) {
      ...ChallengeLeagueFragment
    }
  }

  ${challengeLeagueFragment}
`

export const useChallengeGroupQuery = (challengeGroupId: string) => {
  return useQuery<{ group: ChallengeGroup }>(challengeGroupQuery, {
    variables: {
      challengeGroupId,
    },
  })
}

export const useChallengeLeagueQuery = (
  challengeGroupId: string,
  options?: { skip?: boolean }
) => {
  return useQuery<{ group: ChallengeLeague }>(challengeLeagueQuery, {
    variables: {
      challengeGroupId,
    },
    skip: options?.skip,
  })
}

export const useChallengeLeagueOwnerQuery = (challengeGroupId: string) => {
  return useQuery<{ group: ChallengeLeague }>(challengeLeagueownerQuery, {
    variables: {
      challengeGroupId,
    },
    fetchPolicy: 'cache-and-network',
  })
}

export const useChallengeGroupMembersQuery = (
  challengeGroupId: string,
  limit?: number,
  offset?: number
) => {
  return useQuery<{ group: { id: string; members: User[] } }>(
    gql`
      query challengeGroup($challengeGroupId: ID!, $limit: Int, $offset: Int) {
        group(id: $challengeGroupId) {
          id
          members(limit: $limit, offset: $offset) {
            id
            username
            slug
            avatarData
            displayName
          }
        }
      }
    `,
    {
      variables: {
        challengeGroupId,
        limit,
        offset,
      },
    }
  )
}

export const useMyChallengeGroups = ({
  daysLimit,
  limit,
}: {
  daysLimit?: number
  limit?: number
}) => {
  const query = useQuery<{ myChallengeGroups: ChallengeGroup[] }>(
    gql`
      query myChallengeGroups($daysLimit: Int, $limit: Int) {
        myChallengeGroups(daysLimit: $daysLimit, limit: $limit) {
          ...ChallengeGroupFragment
        }
      }
      ${challengeGroupFragment}
    `,
    {
      variables: {
        daysLimit,
        limit,
      },
    }
  )
  return {
    ...query,
    data: query.data?.myChallengeGroups ?? [],
  }
}

export const useMyLeagues = ({
  daysLimit,
  limit,
}: {
  daysLimit?: number
  limit?: number
}) => {
  const query = useQuery<{ myChallengeLeagues: ChallengeLeague[] }>(
    gql`
      query myChallengeLeagues($daysLimit: Int, $limit: Int) {
        myChallengeLeagues(daysLimit: $daysLimit, limit: $limit) {
          ...ChallengeLeagueFragment
        }
      }
      ${challengeLeagueFragment}
    `,
    {
      variables: {
        daysLimit,
        limit,
      },
    }
  )
  return {
    ...query,
    data: query.data?.myChallengeLeagues ?? [],
  }
}

const challengeLeaguesQuery = gql`
  query challengeLeagues {
    challengeLeagues {
      ...ChallengeLeagueFragment
    }
  }
  ${challengeLeagueFragment}
`

export const useChallengeLeaguesQuery = ({
  hideFollowing = false,
}: {
  hideFollowing?: boolean
}) => {
  const query = useQuery<{ challengeLeagues: ChallengeLeague[] }>(
    challengeLeaguesQuery,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  if (!hideFollowing) {
    return query
  }

  const unFollowedLeagues = useMemo(
    () =>
      query.data?.challengeLeagues.filter((league) => !league.viewerIsMember) ??
      [],
    [query.data]
  )

  return {
    ...query,
    data: {
      challengeLeagues: unFollowedLeagues,
    },
  }
}

type ChallengeGroupSubscribeToLeagueInput = {
  leagueId: string
  challengeGroupId: string
}

export const useChallengeGroupSubscribeToLeagueMutation = () => {
  return useMutation<unknown, { input: ChallengeGroupSubscribeToLeagueInput }>(
    gql`
      mutation challengeGroupSubscribeToLeague(
        $input: ChallengeGroupSubscribeToLeagueInput!
      ) {
        challengeGroupSubscribeToLeague(input: $input)
      }
    `
  )
}

export const useChallengeGroupUnsubscribeToLeagueMutation = () => {
  return useMutation<unknown, { input: ChallengeGroupSubscribeToLeagueInput }>(
    gql`
      mutation challengeGroupUnsubscribeToLeague(
        $input: ChallengeGroupSubscribeToLeagueInput!
      ) {
        challengeGroupUnsubscribeToLeague(input: $input)
      }
    `
  )
}

export type ChallengeGroupLeaderboardEntry = {
  user: User
  score: number
  quizzesPlayed: number
  rank: number
}

export type ChallengeGroupLeaderboardInput = {
  groupId: string
  variant: GlobalLeaderboardType
}

export type LeagueLeaderboardInput = {
  groupId: string
  variant: GlobalLeaderboardType
  limit?: number
  offset?: number
}

const challengeGroupLeaderboardEntryFragment = gql`
  fragment ChallengeGroupLeaderboardEntryFragment on ChallengeGroupLeaderboardEntry {
    user {
      id
      username
      slug
      avatarData
      displayName
    }
    score
    quizzesPlayed
    rank
  }
`

export const useGroupLeaderboardQuery = (
  input: ChallengeGroupLeaderboardInput
) => {
  return useQuery<{
    groupLeaderboard: { leaderboard: ChallengeGroupLeaderboardEntry[] }
  }>(
    gql`
      query groupLeaderboard($input: ChallengeGroupLeaderboardInput) {
        groupLeaderboard(input: $input) {
          leaderboard {
            ...ChallengeGroupLeaderboardEntryFragment
          }
        }
      }
      ${challengeGroupLeaderboardEntryFragment}
    `,
    {
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    }
  )
}

export const useLeagueLeaderboardQuery = (input: LeagueLeaderboardInput) => {
  const viewerQueryInput = {
    groupId: input.groupId,
    variant: input.variant,
  }
  return useQuery<{
    leagueLeaderboard: LeaderboardItem[]
    viewerLeagueLeaderboardEntry: LeaderboardItem | undefined
  }>(
    gql`
      query leagueLeaderboard(
        $input: LeagueLeaderboardInput!
        $viewerQueryInput: ChallengeGroupLeaderboardInput!
      ) {
        leagueLeaderboard(input: $input) {
          ...LeagueLeaderboardItem
        }
        viewerLeagueLeaderboardEntry(input: $viewerQueryInput) {
          ...LeagueLeaderboardItem
        }
      }
      ${leagueLeaderboardItemFragment}
    `,
    {
      variables: {
        input,
        viewerQueryInput,
      },
      fetchPolicy: 'network-only',
    }
  )
}

export const usePreviousRoundWinnersQuery = (groupId: string) => {
  return useQuery<{
    group: {
      previousRoundWinners: ChallengeGroupLeaderboardEntry[]
    }
  }>(
    gql`
      query previousRoundWinners($groupId: ID!) {
        group(id: $groupId) {
          previousRoundWinners {
            ...ChallengeGroupLeaderboardEntryFragment
          }
        }
      }
      ${challengeGroupLeaderboardEntryFragment}
    `,
    {
      variables: {
        groupId,
      },
      fetchPolicy: 'network-only',
    }
  )
}

export type CreateChallengeGroupInput = {
  title: string
  description?: string
  groupId?: string
  subcriptionLeagueId: string
  backgroundColor: string
  textColor: string
  locale?: string
}

const createChallengeGroupMutation = gql`
  mutation createChallengeGroup($input: CreateChallengeGroupInput!) {
    createChallengeGroup(input: $input) {
      id
    }
  }
`

export const useCreateChallengeGroupMutation = () => {
  return useMutation<
    { createChallengeGroup: { id: string } },
    { input: CreateChallengeGroupInput }
  >(createChallengeGroupMutation)
}

const joinChallengeGroupMutation = gql`
  mutation joinChallengeGroup($groupId: String!) {
    joinChallengeGroup(groupId: $groupId) {
      id
      members {
        id
      }
      viewerIsMember
      activeQuizzes {
        id
        challengeGroupId
        title
        viewerCanStartQuiz
        availableFrom
        availableTo
        viewerData {
          activeQuizInstance {
            id
          }
        }
        challengeGroupResults {
          userId
          leagueQuizInstanceId
          correctAnswersCount
          correctAnswerScore
          timeBonus
          totalScore
        }
      }
    }
  }
`

export const useJoinChallengeGroupMutation = () => {
  return useMutation<
    { joinChallengeGroup: { id: string } },
    { groupId: string }
  >(joinChallengeGroupMutation)
}

const joinChallengeLeagueMutation = gql`
  mutation joinChallengeGroup($groupId: String!) {
    joinChallengeGroup(groupId: $groupId) {
      id
      viewerIsMember
      activeQuizzes {
        id
        challengeGroupId
        title
        viewerCanStartQuiz
        availableFrom
        availableTo
      }
    }
  }
`

export const useJoinChallengeLeagueMutation = () => {
  return useMutation<
    { joinChallengeGroup: { id: string } },
    { groupId: string }
  >(joinChallengeLeagueMutation)
}

export type UpdateGroupInput = {
  title: string
  description?: string
  groupId: string
  backgroundColor?: string
  textColor?: string
  locale: string
}

const updateChallengeGroupMutation = gql`
  mutation updateChallengeGroup($input: UpdateGroupInput!) {
    updateChallengeGroup(input: $input) {
      id
      title
      description
      locale
    }
  }
`
export const useUpdateChallengeGroupMutation = () => {
  return useMutation<
    { updateChallengeGroup: { id: string } },
    { input: UpdateGroupInput }
  >(updateChallengeGroupMutation)
}

//     updateChallengeGroupImage(input: UpdateGroupImageInput!): ChallengeGroup

export type UpdateGroupImageInput = {
  groupId: String
  imageUrl?: String
}

const updateChallengeGroupImageMutation = gql`
  mutation updateChallengeGroupImage($input: UpdateGroupImageInput!) {
    updateChallengeGroupImage(input: $input) {
      id
    }
  }
`
export const useUpdateChallengeGroupImageMutation = () => {
  return useMutation<
    { updateChallengeGroupImage: { id: string } },
    { input: UpdateGroupImageInput }
  >(updateChallengeGroupImageMutation)
}

//updateChallengeGroupBackgroundImage

const updateChallengeGroupBackgroundImageMutation = gql`
  mutation updateChallengeGroupBackgroundImage($input: UpdateGroupImageInput!) {
    updateChallengeGroupBackgroundImage(input: $input) {
      id
    }
  }
`

export const useUpdateChallengeGroupBackgroundImageMutation = () => {
  return useMutation<
    { updateChallengeGroupBackgroundImage: { id: string } },
    { input: UpdateGroupImageInput }
  >(updateChallengeGroupBackgroundImageMutation)
}

const leaveChallengeGroupMutation = gql`
  mutation leaveChallengeGroup($groupId: String!) {
    leaveChallengeGroup(groupId: $groupId) {
      id
    }
  }
`
export const useLeaveChallengeGroupMutation = () => {
  return useMutation<
    { leaveChallengeGroup: { id: string } },
    { groupId: string }
  >(leaveChallengeGroupMutation)
}

const deleteChallengeGroupMutation = gql`
  mutation deleteChallengeGroup($groupId: String!) {
    deleteChallengeGroup(groupId: $groupId) {
      id
    }
  }
`
export const useDeleteChallengeGroupMutation = () => {
  return useMutation<
    { deleteChallengeGroup: { id: string } },
    { groupId: string }
  >(deleteChallengeGroupMutation)
}

export type AddQuizToChallengeGroupInput = {
  quizId: string
  groupId: string
  playMode?: 'default' | 'live'
}

const addQuizToChallengeGroupMutation = gql`
  mutation addQuizToChallengeGroup($input: AddQuizToChallengeGroupInput!) {
    addQuizToChallengeGroup(input: $input) {
      id
    }
  }
`
export const useAddQuizToChallengeGroupMutation = () => {
  return useMutation<
    { addQuizToChallengeGroup: { id: string } },
    { input: AddQuizToChallengeGroupInput }
  >(addQuizToChallengeGroupMutation)
}

type ManageSubscriptionInput = {
  groupId: string
  leagueId: string
}

//addSubscriptionToChallengeGroup(input: ManageSubscriptionInput!): ChallengeGroup
const addSubscriptionToChallengeGroupMutation = gql`
  mutation addSubscriptionToChallengeGroup($input: ManageSubscriptionInput!) {
    addSubscriptionToChallengeGroup(input: $input) {
      ...ChallengeLeagueOwnerFragment
    }
  }
  ${challengeLeagueOwnerFragment}
`

export const useAddSubscriptionToChallengeGroupMutation = () => {
  return useMutation<
    { addSubscriptionToChallengeGroup: ChallengeLeague },
    { input: ManageSubscriptionInput }
  >(addSubscriptionToChallengeGroupMutation)
}

const versusInvitationQuery = gql`
  query versusInvitation($challengeGroupId: ID!, $quizId: String!) {
    group(id: $challengeGroupId) {
      versusInvitation(quizId: $quizId)
    }
  }
`

export const useVersusInvitationQuery = (
  challengeGroupId: string,
  quizId: string
) => {
  return useQuery<{ group: ChallengeGroup }>(versusInvitationQuery, {
    variables: {
      challengeGroupId,
      quizId,
    },
  })
}

export type ChallengeAddActiveQuizLeague = {
  id: string
  title: string
  frequency: string
  imageUrl: string
  quizzes: {
    id: string
    title: string
    viewerCanStartQuiz: boolean
    availableFrom: string
    availableTo: string
  }[]
}

const leagueActiveQuizzesQuery = gql`
  query leagueActiveQuizzes($leagueId: String!) {
    league(slug: $leagueId) {
      id
      title
      slug
      frequency
      imageUrl
      quizzes(status: active) {
        id
        title
        viewerCanStartQuiz
        availableFrom
        availableTo
      }
    }
  }
`

export const useLeagueActiveQuizzesQuery = (leagueId: string) => {
  return useQuery<{ league: ChallengeAddActiveQuizLeague }>(
    leagueActiveQuizzesQuery,
    {
      variables: {
        leagueId,
      },
    }
  )
}

export type AddUserWithRoleInput = {
  groupId: string
  userIdOrSlug: string
  role: string
}

export const useAddUserToLeagueWithRoleMutation = () => {
  return useMutation<
    { addUserToLeagueWithRole: { id: string } },
    { input: AddUserWithRoleInput }
  >(
    gql`
      mutation addUserToLeagueWithRole($input: AddUserWithRoleInput!) {
        addUserToLeagueWithRole(input: $input) {
          id
          owners {
            id
            username
            slug
            avatarData
            displayName
          }
        }
      }
    `
  )
}
