import { useEffect } from 'react'

declare global {
  interface Window {
    Cookiebot?: {
      renew?: () => void
      withdraw: () => void
      // Add other methods here if needed with appropriate signatures
      consent?: {
        necessary: boolean
        preferences: boolean
        statistics: boolean
        marketing: boolean
      }
    }
  }
}

export const ThirdPartyScrips = () => {
  useEffect(() => {
    // Don't load Third party scripts on embed pages
    if (window.location.pathname.includes('embed')) {
      return
    }

    // check if scrips are already loaded
    if (document.getElementById('Cookiebot')) {
      return
    }

    // Cookiebot
    const cookiescript = document.createElement('script')
    cookiescript.id = 'Cookiebot'
    cookiescript.src = 'https://consent.cookiebot.com/uc.js'
    cookiescript.dataset.cbid = 'a741317c-9f6b-4d0c-b59d-00bdf7264786'
    cookiescript.type = 'text/javascript'
    cookiescript.async = true

    document.head.appendChild(cookiescript)
    console.log('Cookiebot loaded')
  }, [])

  return null
}
