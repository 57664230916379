import { useEffect } from 'react'
import { isDevelopment, isProduction } from '../config/config'
import { useNativeApp } from '../lib/native/NativeAppContext'
import * as Sentry from '@sentry/react'

export const InitSentry = () => {
  const { isNativeApp } = useNativeApp()

  useEffect(() => {
    if (isDevelopment) {
      console.log('Sentry is not initialized in development')
      return
    }

    Sentry.init({
      dsn: 'https://2524aea59619a913b3b4eab698b4e544@o4506534548930560.ingest.sentry.io/4506534743834624',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost'],
        }),
      ],
      environment: isNativeApp
        ? 'native-app'
        : isProduction
        ? 'production'
        : 'test',
      // Performance Monitoring
      sampleRate: 0.1, //10% of events are sent to Sentry
    })
  }, [])
  return null
}
