import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { LocaleString } from '../../lib/i18n/locale'
import { Category } from '../../lib/types'
import { CareerPathQuestion } from '../Generate/GenerateQuestion'
import { QuizType } from '../Generate/GeneratePage'
import { Fixture } from '../Generate/queries'
import { authenticLeagueFragment, authenticTeamFragment } from '../Home/queries'

export type QuestionTranslate = {
  id: string
  question: LocaleString
  alternatives: AlternativeTranslate[]
  solutionIndex: number
  timeless: boolean
}

export type AlternativeTranslate = {
  text: LocaleString
  reference: string
  correct?: boolean
  _key?: string
}

export type GetQuizMsg = {
  id: string
  leagueId: string
  title: LocaleString
  questions: QuestionTranslate[]
  language: string
  startDate: string
  endDate: string | null
  promotionalText: LocaleString | undefined
  tags: Category[]
  randomizeOrder: boolean
  quizType: QuizType
  isGenerated: boolean
  fixtures: Fixture[]
}

export type GetCareerPathQuizMsg = {
  id: string
  title: LocaleString
  questions: CareerPathQuestion[]
  startDate: string
  endDate: string | null
  promotionalText: LocaleString | undefined
  randomizeOrder: boolean
  quizType: QuizType
}

export const localeStringFragment = gql`
  fragment LocaleString on LocaleString {
    en
    no
    es
    de
    fr
    it
    ar
    pt
    ja
    sv
    da
    th
  }
`

const getQuizQuery = gql`
  query getQuiz($input: GetQuizInput!) {
    getQuiz(input: $input) {
      id
      leagueId
      title {
        ...LocaleString
      }
      questions {
        id
        question {
          ...LocaleString
        }
        alternatives {
          text {
            ...LocaleString
          }
          reference
          _key
        }
        solutionIndex
        timeless
      }

      language
      startDate
      endDate
      promotionalText {
        ...LocaleString
      }
      tags {
        _id
        name {
          ...LocaleString
        }
      }
      randomizeOrder
      quizType

      fixtures {
        id
        questionId
        homeTeam {
          ...AuthenticTeamFragment
        }
        awayTeam {
          ...AuthenticTeamFragment
        }
        league {
          ...AuthenticLeagueFragment
        }
        date
      }
    }
  }
  ${localeStringFragment}
  ${authenticLeagueFragment}
  ${authenticTeamFragment}
`

const getCareerPathQuizQuery = gql`
  query getCareerPathQuiz($input: GetQuizInput!) {
    getCareerPathQuiz(input: $input) {
      id
      title {
        ...LocaleString
      }
      questions {
        id
        careerPathPlayer {
          playerId
          playerName
          firstName
          lastName
          photoUrl
          position
          nationality
          team {
            id
            name
            logoUrl
            league {
              id
              name
              logoUrl
            }
          }
          careerPath {
            team {
              id
              name
              logoUrl
            }
            type
            year
          }
        }
        timeless
      }
      startDate
      endDate
      promotionalText {
        ...LocaleString
      }
      randomizeOrder
    }
  }
  ${localeStringFragment}
`

/*const getQuestionsQuery = gql`
  query getQuestions($input: GetQuestionsInput!) {
    getQuestions(input: $input) {
      questions {
        id
        question {
          ...LocaleString
        }
        alternatives {
          ...LocaleString
        }
        timeless
      }
    }
  }
  ${localeStringFragment}
`*/

type TranslateQuestionsMsg = {
  question: string
  alternatives: string[]
}

type QuestionTranslateInput = {
  question: string
  alternatives: string[]
  fromLanguage: string
  toLanguage: string
  leagueSlug: string
}

type TranslateToFromInput = {
  from: string
  to: string
  texts: string[]
}

//    translateToFrom(input: TranslateToFromInput!): [String!]!

const translateToFromQuery = gql`
  query translateToFrom($input: TranslateToFromInput!) {
    translateToFrom(input: $input)
  }
`

export const useTranslateToFromQuery = (input?: TranslateToFromInput) => {
  return useQuery<{ translateToFrom: string[] }>(translateToFromQuery, {
    variables: { input },
    fetchPolicy: 'cache-and-network',
    skip: !input,
  })
}

const translateQuestionQuery = gql`
  query translateQuestion($question: QuestionTranslateInput!) {
    translateQuestion(question: $question) {
      question
      alternatives
    }
  }
`

const translateLocaleStrings = gql`
  query translateLocaleStrings($input: TranslateLocaleStringInput!) {
    translateLocaleStrings(input: $input) {
      ...LocaleString
    }
  }
  ${localeStringFragment}
`

const updateQuestionMutation = gql`
  mutation updateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input)
  }
`

export const useGetQuizQuery = (
  input: { leagueSlug: string; quizid: string },
  skip?: boolean,
  quizType?: string
) => {
  if (quizType === 'careerPath') {
    return useQuery<{
      getCareerPathQuiz?: GetCareerPathQuizMsg
    }>(getCareerPathQuizQuery, {
      variables: { input },
      fetchPolicy: 'cache-and-network',
      skip: skip,
    })
  } else {
    return useQuery<{
      getQuiz?: GetQuizMsg
    }>(getQuizQuery, {
      variables: { input },
      fetchPolicy: 'cache-and-network',
      skip: skip,
    })
  }
}

/*export const useGetQuestionsQuery = (input: {
  filters: string[]
  pageNum: number
  fromLang: Locale
}) => {
  return useQuery<{
    getQuestions?: GetQuestionsMsg
  }>(getQuestionsQuery, {
    variables: { input },
    fetchPolicy: 'cache-and-network',
  })
}*/

export const useTranslateQuestionQuery = (
  question: QuestionTranslateInput,
  skip: boolean
) => {
  return useQuery<{
    translateQuestion?: TranslateQuestionsMsg
  }>(translateQuestionQuery, {
    variables: { question },
    fetchPolicy: 'cache-and-network',
    skip: skip,
  })
}

export const useTranslateLocaleStringQuery = () => {
  return useLazyQuery<{ translateLocaleStrings: LocaleString[] }>(
    translateLocaleStrings,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
}

export type UpdateQuestionInput = {
  id: string
  question: string
  alternatives: string[]
  language: string
  leagueSlug: string
}

export const useUpdateQuestionMutation = () => {
  return useMutation<unknown, { input: UpdateQuestionInput }>(
    updateQuestionMutation
  )
}

export const usePrimeLeagueQuizMutation = () => {
  return useMutation<
    unknown,
    { input: { leagueSlug: string; quizId: string } }
  >(
    gql`
      mutation primeLeagueQuiz($input: PrimeLeagueQuizInput!) {
        primeLeagueQuiz(input: $input)
      }
    `
  )
}

export type UpdateQuizInput = {
  quizid: string
  title: string
  promotionalText: string
  language: string
  leagueSlug: string
}

export const useUpdateQuizMutation = () => {
  return useMutation<unknown, { input: UpdateQuizInput }>(
    gql`
      mutation updateQuiz($input: UpdateQuizInput!) {
        updateQuiz(input: $input)
      }
    `
  )
}
