/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Lottie from 'lottie-react'
import React, { useEffect, useState } from 'react'
import pointBlast from './leagueQuiz/assets/lottie/pointBlast.json'
import { Flex } from './layout/Flex'
import {
  CircularProgressbarWithChildren as CPB,
  buildStyles,
} from 'react-circular-progressbar'
import { useInterval } from '../utils/useInterval'

interface ScoreIndicatorProps {
  percentage?: number
  color?: string
  size?: number
  iconPath?: string
  innerBackgroundColor?: string
  duration: number
  delay?: number
  playAnimation?: boolean
}

//easeIn
function easeIn(t: number) {
  return t * t * t
}

const ScoreIndicator: React.FC<ScoreIndicatorProps> = ({
  percentage = 0,
  color = '#3498db',
  size = 120,
  iconPath,
  innerBackgroundColor = '#f5f5f5',
  duration = 1,
  delay = 0,
  playAnimation = false,
}) => {
  percentage = percentage * 100
  const radius = size / 2 - 4
  const [startAnimation, setStartAnimation] = useState(false)
  const [showPointblast, setShowPointBlast] = useState(false)
  const [currentPercentage, setCurrentPercentage] = useState(0)
  const [animationProgress, setAnimationProgress] = useState(0)

  const iconSize = size * 0.5
  const animationDelayTime = delay * 1000
  const animationDurationTime = duration * 1000

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true)
    }, animationDelayTime)

    return () => clearTimeout(timer) // Clear the timeout if the component is unmounted
  }, [])

  const steps = 100
  const animationUpdateInterval = animationDurationTime / steps

  useInterval(
    () => {
      const easeInFactor = easeIn(animationProgress)

      setCurrentPercentage(() => {
        if (animationProgress === 1) {
          return percentage
        }
        setAnimationProgress(animationProgress + 0.01)

        return percentage * easeInFactor
      })
    },
    !startAnimation ||
      currentPercentage >= percentage ||
      animationProgress === 1
      ? null
      : animationUpdateInterval
  )

  useEffect(() => {
    if (startAnimation && !showPointblast) {
      const timer = setTimeout(() => {
        setShowPointBlast(true)
      }, duration * 1000 + 350)

      return () => clearTimeout(timer) // Clear the timeout if the component is unmounted
    }
  }, [startAnimation])

  return (
    <Flex
      column
      horizontal="center"
      vertical="center"
      css={css`
        position: relative;
      `}
    >
      {playAnimation && showPointblast && (
        <Lottie
          animationData={pointBlast}
          loop={false}
          autoplay={true}
          style={{
            position: 'absolute',
            top: `-${size / 2}px`,
            left: `-${size / 2}px`,
            right: '0',
            bottom: '0',
            width: `${size * 2}px`,
            height: `${size * 2}px`,
            zIndex: 0,
          }}
        />
      )}

      <CPB
        css={css`
          position: absolute;
          top: 0;
          left: -${size / 2}px;
          bottom: 0;
          right: 0;
          height: ${size}px;
          width: ${size}px;
          margin: 0;
          padding: 0;
        `}
        value={currentPercentage}
        strokeWidth={10}
        circleRatio={0.75}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: 'round',
          pathColor: color,
        })}
      />
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {/* Inner Background Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius - 4}
          fill={innerBackgroundColor}
        />
        {/* Icon */}
        {iconPath && (
          <image
            href={iconPath}
            width={iconSize}
            height={iconSize}
            x={(size - iconSize) / 2}
            y={(size - iconSize) / 2}
          />
        )}
      </svg>
    </Flex>
  )
}

export default ScoreIndicator
