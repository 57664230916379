/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { TextNew } from '../../components/TextNew'
import { TextShiftsFade } from '../../components/TextShiftsFade'
import { TextShiftsMove } from '../../components/TextShiftsMove'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { LeagueQuizStatus } from '../../lib/types'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { setTimeoutRetry } from '../../utils/util'
import {
  calculateTimeRemaining,
  datePlus,
  timeRemainingToStringClock,
} from '../Admin/Utils'
import {
  LeaguePageQueryResult,
  LeagueStreakQueryResult,
  useSetHasSeenStreak,
} from '../League/queries'
import { useStartLeagueQuizMutation } from '../LeagueQuiz/queries'
import { ShimmerComponent } from './ShimmerComponent'
import { DeadlineAndPlayers } from './SpecialQuiz'
import { CoinAnimated } from './coin/CoinAnimated'

const coinAnimationDuration = (data: LeagueStreakQueryResult | undefined) =>
  2500 + 250 * displayedStreak(data)

const displayedStreak = (data: LeagueStreakQueryResult | undefined) => {
  const streak = data?.league?.streak ?? 0
  if (streak == 0 || streak % 7 !== 0) return streak % 7
  // Special case for streak == 7n, (n: positive integer):
  // If you got streak == 7n today, then display streak = 7
  // If you got streak == 7n yesterday, then display streak = 0
  // This is the only streak number that must be different depending
  // on whether you got the streak today or yesterday.
  const todaysQuiz = getTodaysQuiz(data)
  if (!todaysQuiz) return 0
  return todaysQuiz.viewerData.hasCompletedQuiz ? 7 : 0
}

export const getTodaysQuiz = (
  data: LeagueStreakQueryResult | LeaguePageQueryResult | undefined
) => {
  return data?.league?.currentQuiz
}

export const Coins = ({
  data,
  lightText = false,
}: {
  data: LeagueStreakQueryResult
  lightText?: boolean
}) => {
  const leagueSlug = 'the-daily'
  const t = useT()
  const hasSeenStreak = data.league?.hasSeenStreak ?? true
  const [setHasSeenStreak] = useSetHasSeenStreak()

  useEffect(() => {
    if (hasSeenStreak) return
    setTimeout(() => {
      setHasSeenStreak({ variables: { leagueIdOrSlug: leagueSlug } })
    }, coinAnimationDuration(data))

    const updateCoinsAnimation = (delayedMs: number) => {
      const viewerCoins = document.getElementById('viewerCoins')
      if (!viewerCoins || !viewerCoins.textContent) return
      const actualCoins = parseInt(viewerCoins.textContent)
      const previousCoins = actualCoins - displayedStreak(data)
      viewerCoins.textContent = `${previousCoins}`

      setTimeout(() => {
        viewerCoins.textContent = `${actualCoins}`
      }, coinAnimationDuration(data) - delayedMs)
    }
    setTimeoutRetry(
      () => {
        const viewerCoins = document.getElementById('viewerCoins')
        const viewerCoinsMissing = !viewerCoins || !viewerCoins.textContent
        return !viewerCoinsMissing
      },
      updateCoinsAnimation,
      1000
    )
  }, [])

  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7].map((it) => {
        if (it > displayedStreak(data)) {
          return (
            <Flex key={it} column horizontal="center" gap="tiny">
              <object
                css={css`
                  width: 38px;
                  margin-top: -5px;
                  @media (max-width: 399px) {
                    width: 9.5vw;
                  }
                `}
                data="/CoinHalf.svg"
              />
              <TextNew
                size={10}
                color={`${
                  lightText
                    ? 'rgba(255, 255, 255, 0.90)'
                    : 'rgba(0, 0, 0, 0.50)'
                }`}
                css={css`
                  @media (max-width: 399px) {
                    font-size: 2.5vw;
                  }
                `}
              >
                {t(`Day`)} {it}
              </TextNew>
            </Flex>
          )
        } else {
          if (hasSeenStreak) {
            return (
              <object
                css={css`
                  @media (max-width: 399px) {
                    height: 9.5vw;
                    width: 9.5vw;
                  }
                `}
                key={it}
                data="/CoinDone.svg"
              />
            )
          }
          return (
            <CoinAnimated
              css={css`
                @media (max-width: 399px) {
                  svg {
                    height: 10vw;
                    width: 10vw;
                  }
                }
              `}
              key={it}
              index={it}
              length={displayedStreak(data)}
            />
          )
        }
      })}
    </>
  )
}

const DailyQuizActive = ({ data }: { data: LeagueStreakQueryResult }) => {
  const leagueSlug = 'the-daily'
  const t = useT()
  const navigate = useNavigate()

  const trackEvent = useGetTrackEvent()

  const hasSeenStreak = data.league?.hasSeenStreak ?? true
  const { authUser, signInAsAnonymous } = useAuthContext()
  const viewerQuery = useViewerQuery()
  const [startLeagueQuizMutation, { loading }] = useStartLeagueQuizMutation()

  const hideWelcomeText = window.localStorage.getItem('hideWelcomeText')

  const userCanEdit =
    viewerQuery.data?.viewer?.isAdmin ||
    viewerQuery.data?.viewer?.isTranslater ||
    data.league?.viewerIsOwner

  useEffect(() => {
    setTimeout(() => {
      window.localStorage.setItem('hideWelcomeText', 'true')
    }, 15000)
  }, [])

  const play = async (e: any) => {
    if (loading) return
    if (!authUser) {
      await signInAsAnonymous()
    }

    const todaysQuiz = getTodaysQuiz(data)

    const quizId = todaysQuiz?.id

    if (!quizId) {
      console.log('No quiz found')
      return
    }

    startLeagueQuizMutation({
      variables: {
        input: { leagueQuizId: quizId },
      },
    }).then((result) => {
      const quizInstanceId = result.data?.startLeagueQuiz.id
      if (quizInstanceId) {
        trackEvent(AnalyticsEventType.StartQuiz, {
          quizId,
          leagueId: data.league?.id,
          leagueSlug,
          isEmbed: false,
        })
        navigate(`/play-quiz/${quizInstanceId}`)
      }
    })
    e.stopPropagation()
  }
  return (
    <Flex column>
      <button
        onClick={play}
        css={[
          buttonReset,
          css`
            cursor: pointer;
          `,
        ]}
        aria-label={t('Play the daily quiz')}
      >
        <Flex
          css={css`
            height: 250px;
            position: relative;

            padding: 20px 15px;

            border-radius: 10px;
            background-color: #ffe500;
          `}
          column
          horizontal="center"
          gap="medium"
          vertical="space-between"
        >
          <Flex
            css={css`
              width: 100%;
              max-width: 350px;
              @media (max-width: 399px) {
                gap: 1px;
              }
            `}
            horizontal="space-between"
            gap="small"
            vertical="center"
          >
            <Coins data={data} />
          </Flex>
          {hideWelcomeText ? (
            <TextShiftsFade
              css={css`
                font-size: 70px;
              `}
              italic
              condensed
              strong
              shiftsAtMs={[coinAnimationDuration(data)]}
              disabled={hasSeenStreak}
            >
              {t(`Your daily bonus`)}
              {t(`The Daily`)}
            </TextShiftsFade>
          ) : (
            <TextShiftsMove
              css={css`
                font-size: 70px;
                white-space: nowrap;
              `}
              italic
              condensed
              strong
              shiftsAtMs={[3000 * 1, 3000 * 2, 3000 * 3, 3000 * 4]}
            >
              {t(`Welcome to the new Daily Quiz!`)}
              {t(`Play every day`)}
              {t(`Build a streak!`)}
              {t(`Win coins!`)}
              {t(`The Daily`)}
            </TextShiftsMove>
          )}
          <div
            css={css`
              border-radius: 999px;
              padding: 10px 35px;
              outline: 3px solid rgba(255, 255, 255, 0.5);
              background-color: ${colors.black};
            `}
          >
            <TextNew italic uppercase extraStrong color="white">
              {t(`Play`)}
            </TextNew>
          </div>
          <DeadlineAndPlayers
            color="rgba(0, 0, 0, 0.50)"
            currentQuiz={data.league?.currentQuiz}
            live={data.league?.live}
            participantCount={data.league?.participantCount}
          />
        </Flex>
      </button>
      {userCanEdit && (
        <button
          css={buttonReset}
          onClick={() => {
            const dailySlug = 'the-daily'
            navigate(`/league/${dailySlug}`)
          }}
        >
          <EditIndicator />
        </button>
      )}
    </Flex>
  )
}

const DailyQuizWaiting = ({
  data,
  timeRemaining,
}: {
  data: LeagueStreakQueryResult
  timeRemaining: string
}) => {
  const t = useT()

  const viewerQuery = useViewerQuery()

  const hasSeenStreak = data.league?.hasSeenStreak ?? true

  const navigate = useNavigate()

  const userCanEdit =
    viewerQuery.data?.viewer?.isAdmin ||
    viewerQuery.data?.viewer?.isTranslater ||
    data.league?.viewerIsOwner

  return (
    <Flex column>
      <Button
        css={buttonReset}
        disabled={!userCanEdit}
        onClick={() => {
          const dailySlug = 'the-daily'
          navigate(`/league/${dailySlug}`)
        }}
      >
        <Flex
          css={css`
            height: 100px;
            position: relative;
            padding: 10px 15px;
            border-radius: 10px;
            background-color: ${colors.dailyYellow};
            @media (min-width: 720px) {
              height: 60px;
            }
          `}
          column
        >
          <Flex
            css={css`
              width: 100%;
              height: 100%;
            `}
            column
            horizontal="center"
            vertical="space-evenly"
          >
            <Flex
              css={css`
                width: 100%;
                max-width: 350px;
                @media (min-width: 720px) {
                  position: absolute;
                }
                @media (max-width: 399px) {
                  gap: 1px;
                }
              `}
              horizontal="space-between"
              gap="small"
              vertical="center"
            >
              <Coins data={data} />
            </Flex>
            <Flex
              css={css`
                width: 100%;
              `}
              vertical="center"
              horizontal="space-between"
            >
              <TextShiftsFade
                css={css`
                  font-size: 25px;
                `}
                italic
                condensed
                strong
                shiftsAtMs={[3000 + 250 * displayedStreak(data)]}
                disabled={hasSeenStreak}
              >
                {t(`Your daily bonus`) + ':'}
                {t(`The Daily`)}
              </TextShiftsFade>
              <Flex
                css={css`
                  @media (min-width: 720px) {
                    flex-direction: column;
                    justify-items: center;
                    gap: 0px;
                  }
                `}
                vertical="flex-end"
                gap="small"
              >
                <TextNew
                  css={css`
                    margin-bottom: 2px;
                    @media (min-width: 720px) {
                      margin-bottom: -2px;
                    }
                  `}
                  size={10}
                  strong
                  italic
                  uppercase
                  color="rgba(0, 0, 0, 0.50)"
                >
                  {t(`Next game in`)}
                </TextNew>
                {data.league?.currentQuiz && (
                  <TextNew
                    size={26}
                    italic
                    condensed
                    color="rgba(0, 0, 0, 0.50)"
                  >
                    {timeRemaining}
                  </TextNew>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {userCanEdit && <EditIndicator />}
      </Button>
      {/* <Flex
        horizontal="center"
        vertical="center"
        wrap
        css={css`
          width: 100%;
          padding: 10px 15px;
          border-radius: 10px;
          background-color: ${colors.dailyYellow};
          margin-top: 16px;
        `}
      >
        <TextNew
          size={25}
          italic
          condensed
          strong
          css={css`
            flex: 1;
          `}
        >
          Want more?
        </TextNew>
        <GenerateQuizButton
          backgroundColor={colors.black}
          textColor={colors.white}
          showBetaChip={false}
          textSize={20}
          css={css`
            margin: 0;
            width: fit-content;
          `}
        />
      </Flex> */}
    </Flex>
  )
}

const EditIndicator = () => {
  const t = useT()
  return (
    <Flex
      horizontal="center"
      css={css`
        margin: 0 auto;
        background-color: ${colors.dailyYellow};
        width: fit-content;
        padding: 8px 30px;
        border-radius: 0 0 10px 10px;
      `}
    >
      <TextNew size={12} italic color={colors.black}>
        {t(`Edit`)}
      </TextNew>
    </Flex>
  )
}

export const DailyQuiz = ({
  data,
  loading,
}: {
  data?: LeagueStreakQueryResult
  loading?: boolean
}) => {
  const _timeRemaining = () => {
    const todaysQuiz = getTodaysQuiz(data)
    let endDate = datePlus(new Date(), { hours: 23, minutes: 59, seconds: 59 })
    if (todaysQuiz && todaysQuiz.availableTo) {
      endDate = new Date(todaysQuiz.availableTo)
    }
    return timeRemainingToStringClock(
      calculateTimeRemaining(new Date(), endDate)
    )
  }

  const [timeRemaining, setTimeRemaining] = useState(_timeRemaining())

  useEffect(() => {
    setTimeRemaining(_timeRemaining())
  }, [])

  useInterval(() => {
    setTimeRemaining(_timeRemaining())
  }, 1000)

  if (loading) {
    return (
      <ShimmerComponent
        backgroundColor={colors.dailyYellow}
        css={css`
          border-radius: 10px;
        `}
      />
    )
  }

  if (!data) {
    return <></>
  }

  const todaysQuiz = getTodaysQuiz(data)

  const isWaiting =
    !todaysQuiz ||
    todaysQuiz.status === LeagueQuizStatus.completed ||
    todaysQuiz.viewerData.hasCompletedQuiz

  if (isWaiting) {
    return <DailyQuizWaiting data={data} timeRemaining={timeRemaining} />
  } else {
    return <DailyQuizActive data={data} />
  }
}
