/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../lib/AuthContext'
import { useT } from '../lib/i18n/useT'
import { colors } from '../styles/theme'
import { PrimaryButton, SecondaryButton } from './Button'
import { Flex } from './layout/Flex'
import { TextNew } from './TextNew'

export const VerifyEmailComponent = ({ className }: { className?: string }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const emailSentLocalStorage =
    localStorage.getItem('verificationEmailSent') === 'true'

  const [loading, setLoading] = useState(false)

  const [emailSent, setEmailSent] = useState(emailSentLocalStorage)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButtonDisabled(false)
    }, 3000)

    return () => clearTimeout(timeout)
  })

  const t = useT()

  useEffect(() => {
    if (emailSent) {
      localStorage.setItem('verificationEmailSent', 'true')
    }
  }, [emailSent])

  const { authUser, verifyEmail } = useAuthContext()

  if (!authUser || authUser.isAnonymous || authUser.emailVerified) {
    return null
  }

  return (
    <Flex
      column
      css={css`
        background-color: ${colors.black};
        padding: 24px 16px;
        border-radius: 16px;
      `}
      gap={'medium'}
      className={className}
    >
      <TextNew color={colors.white}>
        {!emailSent
          ? t('To ensure your progress is not lost, please verify your email.')
          : t(
              'Please follow the link sent to your email to verify your account.'
            )}
      </TextNew>
      <TextNew color={colors.white}>
        {!emailSent
          ? t(
              `Click the button below to send a verification link to {{email}}.`,
              {
                email: authUser.email,
              }
            )
          : t(`Link sent to {{email}}.`, { email: authUser.email })}
      </TextNew>

      {!emailSent && (
        <PrimaryButton
          loading={loading}
          variant="white"
          disabled={buttonDisabled}
          onClick={async () => {
            setLoading(true)
            await verifyEmail()
            setEmailSent(true)
            setButtonDisabled(true)
            setLoading(false)
          }}
        >
          {t('Send verification link')}
        </PrimaryButton>
      )}

      {emailSent && (
        <SecondaryButton
          loading={loading}
          disabled={buttonDisabled}
          variant="white"
          onClick={async () => {
            setLoading(true)
            try {
              await verifyEmail()
            } catch (error) {
              console.log(
                'resend verification email | Something went wrong, please try again later'
              )
            }
            setLoading(false)
            setButtonDisabled(true)
          }}
        >
          {t(`Resend verification link`)}
        </SecondaryButton>
      )}
    </Flex>
  )
}
