/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useEffect } from 'react'
import { PrimaryButton } from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { QuizStatistics } from '../../components/clientSideQuiz/queries'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { QuizStatusComponent } from '../../components/leagueQuiz/QuizStatusComponent'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { SanityQuestion } from '../../lib/sanity/types'
import { fadeIn } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useLeagueQuizContext } from '../LeagueQuiz/LeagueQuizContext'
import { renderReferenceSanity } from './ActiveQuestionScreen'
import { PredictionAlternativeButton } from './PredictionAlternativeButton'
import { QuestionAnswer } from './PredictionQuiz'

export const LastCompletedQuestionScreen: FC<{
  lastCompletedQuestion: SanityQuestion
  allQuestionsAnswered: boolean
  triggerCalculation: () => void
  nextQuestion: () => void
  isEmbed: boolean
  clubLeagueSubscriptionId?: string
  quizId: string
  leagueId?: string
  questionsCount: number
  currentQuestionIndex: number
  answer?: QuestionAnswer
  quizStatistics?: QuizStatistics
}> = ({
  lastCompletedQuestion,
  allQuestionsAnswered,
  triggerCalculation,
  nextQuestion,
  isEmbed,
  clubLeagueSubscriptionId,
  quizId,
  leagueId,
  questionsCount,
  currentQuestionIndex,
  answer,
  quizStatistics,
}) => {
  const t = useT()

  const trackEvent = useGetTrackEvent()

  const { campaignHeaderHeight } = useLeagueQuizContext()

  const statsForQuestion = quizStatistics?.questions.find(
    (it) => it.id === lastCompletedQuestion.id
  )

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId,
        leagueQuizId: quizId,
        isEmbed,
        clubLeagueSubscriptionId,
      })
    }
  }, [allQuestionsAnswered, quizId, leagueId])

  const textColor = theme.colors.black

  if (!answer) {
    return (
      <DefaultError
        sentryErrorMessage={'ClientSideQuiz: answer is undefined'}
      />
    )
  }

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <Spacer height={8} />
        <Spacer height={isEmbed ? 2 : 'small'} />
        <QuizStatusComponent
          currentQuestion={currentQuestionIndex}
          totalQuestions={questionsCount}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={lastCompletedQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {lastCompletedQuestion.text}
        </Text>

        {!lastCompletedQuestion.alternatives.some(
          (alternative) => alternative.id === answer.alternativeId
        ) && (
          <Text color={colors.red300} css={margin.bottom()} textAlign="center">
            {t('Unfortunately, the time ran out!')}
          </Text>
        )}
        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <PredictionAlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.id === answer.alternativeId}
              correct={alternative.correct}
              showGlobalStats
              percentageAnswered={
                statsForQuestion?.alternatives.find(
                  (it) => it.id === alternative.id
                )?.percentage || 0
              }
            >
              {alternative.reference?._id
                ? renderReferenceSanity(alternative.reference, textColor)
                : alternative.text}
            </PredictionAlternativeButton>
          ))}

          <TextNew
            condensed
            size={'large'}
            css={css`
              text-align: center;
              margin-top: 16px;
            `}
          >
            {getTextByPercentageAnswered(
              statsForQuestion?.alternatives.find(
                (it) => it.id === answer.alternativeId
              )?.percentage || 0
            )}
          </TextNew>

          <Flex
            column
            css={[
              margin.top(isEmbed ? 'small' : 'large'),
              css`
                opacity: 0;
                animation: ${fadeIn} 600ms ease-out;
                animation-delay: 2000ms;
                animation-fill-mode: forwards;
              `,
            ]}
          >
            {allQuestionsAnswered ? (
              <PrimaryButton
                onClick={() => triggerCalculation()}
                css={css`
                  background-color: 'black';
                  height: 50px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Finish')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={nextQuestion}
                css={css`
                  background-color: black;
                  color: white;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Next')}
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </PageContent>
  )
}

function getTextByPercentageAnswered(percentageAnswered: number) {
  if (percentageAnswered > 50) {
    return 'Going for the safe bet? 🤝'
  }
  if (percentageAnswered <= 20) {
    return 'You got some inside info? 🧠'
  }
  return 'Hmm, interesting choice! 🤔'
}
