/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PiPlayBold } from 'react-icons/pi'
import { Navigate, useNavigate } from 'react-router-dom'
import { IconButton, PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { ANON_FIREBASE_USERID_KEY, useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { useTransferDataMutation } from '../auth/queries'
import { UserAvatar } from '../Avatar/UserAvatar'
import { LocaleForm } from '../EditProfile/EditProfilePage'
import { useUpdateProfileMutation } from '../EditProfile/queries'
import { userProfileQuery, useUserProfileQuery } from './queries'
import { useCurrentLocale, setCurrentLocale } from '../../lib/i18n/locale'

type pageState = 'needName' | 'needLocale' | 'updated' | 'transferAnon'

const Content = () => {
  const { authUser, authProvider } = useAuthContext()
  const { data: viewerData, loading } = useUserProfileQuery(
    authUser?.uid || '',
    !authUser
  )

  const hasUsername = !!viewerData?.user?.username
  const hasLocale = !!viewerData?.user?.locale

  const anonUserId = window.localStorage.getItem(ANON_FIREBASE_USERID_KEY)

  const [currentPageState, setCurrentPageState] = useState<pageState>(
    anonUserId ? 'transferAnon' : 'needLocale'
  )

  const [usernameAlreadyInUse, setUsernameAlreadyInUse] = useState(false)
  const [invalidUsername, setInvalidUsername] = useState(false)

  const [transferAnonData] = useTransferDataMutation()

  const navigate = useNavigate()

  const [username, setUsername] = useState('')

  const currentLocale = useCurrentLocale()
  const [locale, setLocale] = useState(currentLocale)

  useEffect(() => {
    setUsernameAlreadyInUse(false)
    setInvalidUsername(false)
  }, [username])

  useEffect(() => {
    if (hasLocale && !hasUsername) {
      setCurrentPageState('needName')
    }

    if (hasUsername) {
      setUsername(viewerData.user!.username)
    }

    if (hasUsername && hasLocale) {
      setCurrentPageState('updated')
    }
  }, [viewerData])

  const apolloClient = useApolloClient()

  const [updateProfileMutation] = useUpdateProfileMutation()

  const textColor = colors.white

  const t = useT(locale)

  if (loading) {
    return <Loader center variant="yellow" />
  }

  const viewer = viewerData?.user

  const profileComplete = viewer?.username && viewer?.locale

  if (!viewer) {
    return <Navigate to="/" />
  }

  const errorMessageString = usernameAlreadyInUse
    ? t('Player name is taken')
    : invalidUsername
    ? t('Invalid player name')
    : undefined

  const transferAnon = async () => {
    try {
      if (anonUserId) {
        await transferAnonData({
          variables: {
            input: {
              userId: anonUserId,
              authProvider: authProvider || 'firebase',
            },
          },
        })
      }

      window.localStorage.removeItem(ANON_FIREBASE_USERID_KEY)
      setCurrentPageState(profileComplete ? 'updated' : 'needName')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <PageContent column gap={'large'}>
      <HeadingNew level={1} looksLikeLevel={3} color={textColor}>
        {t('WELCOME!')}
      </HeadingNew>

      <Flex horizontal="center">
        <UserAvatar
          clickable={false}
          avatarData={viewer.avatarData}
          height={300}
        />
      </Flex>

      {currentPageState === 'transferAnon' && (
        <>
          <TextNew color={textColor}>
            Before we get started, we need some more information from you.
          </TextNew>
          <PrimaryButton variant="white" onClick={transferAnon}>
            {t('Start')}
          </PrimaryButton>
        </>
      )}

      {currentPageState === 'needName' && (
        <>
          <TextNew color={textColor}>What is your player name?</TextNew>
          <TextInput
            id="username"
            name="username"
            label={
              <TextNew
                color={colors.white}
                size="small"
                css={css`
                  text-align: left;
                `}
              >
                {t('Player name')}
              </TextNew>
            }
            placeholder={t('A unique name')}
            autoComplete="off"
            value={username}
            onValue={(value) => {
              setUsername(value)
            }}
            error={errorMessageString}
          />

          <PrimaryButton
            variant="white"
            rounded
            onClick={async () => {
              try {
                await updateProfileMutation({
                  variables: {
                    input: {
                      username,
                    },
                  },
                })

                await apolloClient.refetchQueries({
                  include: [userProfileQuery],
                })

                setCurrentPageState(hasLocale ? 'updated' : 'needLocale')
              } catch (error) {
                if (hasErrorCode(error, ErrorCode.USERNAME_NOT_AVAILABLE)) {
                  setUsernameAlreadyInUse(true)
                } else if (hasErrorCode(error, ErrorCode.USERNAME_INVALID)) {
                  setInvalidUsername(true)
                }
              }
            }}
          >
            {t('Save')}
          </PrimaryButton>
        </>
      )}
      {currentPageState === 'needLocale' && (
        <>
          <LocaleForm locale={locale} onLocale={setLocale} />

          <PrimaryButton
            variant="white"
            rounded
            onClick={async () => {
              try {
                await updateProfileMutation({
                  variables: {
                    input: {
                      locale,
                    },
                  },
                })

                await apolloClient.refetchQueries({
                  include: [userProfileQuery],
                })

                setCurrentLocale(locale)
                setCurrentPageState(hasUsername ? 'updated' : 'needName')
              } catch (error) {
                if (hasErrorCode(error, ErrorCode.USERNAME_NOT_AVAILABLE)) {
                  setUsernameAlreadyInUse(true)
                } else if (hasErrorCode(error, ErrorCode.USERNAME_INVALID)) {
                  setInvalidUsername(true)
                }
              }
            }}
          >
            {t('Save')}
          </PrimaryButton>
        </>
      )}

      {currentPageState === 'updated' && (
        <Flex column gap="medium" horizontal="center">
          <TextNew color={textColor}>{`${t(
            'Update was successfull'
          )} 🎉`}</TextNew>

          <TextNew color={textColor} size={40}>
            {viewer.username ?? username}
          </TextNew>

          <Flex gap="medium" wrap horizontal="center">
            <IconButton
              backgroundColor={colors.green600}
              textColor={textColor}
              borderColor={textColor}
              noWrap
              rounded
              onClick={() => {
                navigate('/')
              }}
              text={'Start playing!'}
              icon={<PiPlayBold />}
            />
            <IconButton
              backgroundColor={colors.white}
              textColor="black"
              noWrap
              rounded
              onClick={() => {
                navigate('/profile/avatar')
              }}
              text={t(`Edit Avatar`)}
              icon={
                <img
                  src="/PencilSimple.svg"
                  css={css`
                    filter: invert(1);
                  `}
                />
              }
            />
          </Flex>
        </Flex>
      )}
    </PageContent>
  )
}

export const UpdateProfilePage = () => {
  const t = useT()

  return (
    <Layout
      title={`fcQuiz | ${t('Profile')}`}
      backgroundColor={colors.green600}
      showCookiebotButton
      hideAvatar
      hideHeaderMobile
      hideNavbar
      css={css`
        header > * {
          padding-bottom: 0px;
        }
      `}
    >
      <Content />
    </Layout>
  )
}
