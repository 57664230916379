/** @jsxImportSource @emotion/react */

import { Auth0Provider } from '@auth0/auth0-react'
import 'react'
import { RouterProvider } from 'react-router-dom'
import { Auth0Config } from './config/config'
import { AuthContextProvider } from './lib/AuthContext'
import { NotificationProvider } from './lib/NotificationContext'
import AuthorizedApolloProvider from './lib/apollo/apollo'
import { NativeAppProvider } from './lib/native/NativeAppContext'
import { router } from './router'
import { EnsureCorrectLocale } from './utils/EnsureCorrectLocale'
import { SplashScreen } from './utils/Splash'
import { TrackReferrer } from './utils/TrackRefferer'
import { InitSentry } from './utils/sentry'
import { ThirdPartyScrips } from './utils/thirdPartyScripts'
import { ExternalCampaignTracker } from './utils/useExternalCampaign'
import { useIsEmbed } from './utils/useIsEmbed'

export const App = () => {
  return (
    <Auth0Provider
      clientId={Auth0Config.clientId}
      domain={Auth0Config.domain}
      authorizationParams={{
        redirect_uri: Auth0Config.redirect_uri,
      }}
    >
      <AuthContextProvider>
        <AuthorizedApolloProvider>
          <EnsureCorrectLocale />
          <NativeAppProvider>
            <NotificationProvider>
              <SplashScreen />
              <ThirdPartyScrips />
              <TrackReferrer />
              <ExternalCampaignTracker />
              <InitSentry />
              <RouterProvider router={router}></RouterProvider>
            </NotificationProvider>
          </NativeAppProvider>
        </AuthorizedApolloProvider>
      </AuthContextProvider>
    </Auth0Provider>
  )
}
export { useIsEmbed }
