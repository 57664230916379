/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { TextNew } from './TextNew'
import { Trophy } from './icons'
import { Flex } from './layout/Flex'
import { formatNumber } from '../utils/util'

export const Score = ({
  score,
  className,
  trophy,
}: {
  score: number
  className?: string
  trophy?: boolean
}) => {
  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={css`
        padding: 8px;
      `}
      className={className}
      gap="small"
    >
      <TextNew size={'small'} italic strong>
        {formatNumber(score)}
      </TextNew>
      {trophy && (
        <Trophy
          css={css`
            width: 18px;
            height: 18px;
          `}
        />
      )}
    </Flex>
  )
}
