/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import {
  SanityLeagueQuiz,
  SanityLeagueQuizCampaign,
} from '../../lib/sanity/types'
import { colors } from '../../styles/theme'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import QuizAd from '../campaign/QuizAdHeader'
import { Flex } from '../layout/Flex'
import { Layout } from '../layout/Layout'
import { ClientSideQuiz } from './ClientSideQuiz'
import { useQuizStatisticsQuery } from './queries'

const Content = ({
  quiz,
  campaign,
  isEmbed = false,
  clubLeagueSubscriptionId,
  clubId,
}: {
  quiz: SanityLeagueQuiz
  campaign?: SanityLeagueQuizCampaign
  isEmbed?: boolean
  clubLeagueSubscriptionId?: string
  clubId?: string
}) => {
  const statsQuery = useQuizStatisticsQuery(quiz.id)

  const { setClubLeagueSubscriptionId, setClubId } = useLeagueQuizContext()

  useEffect(() => {
    if (clubLeagueSubscriptionId) {
      setClubLeagueSubscriptionId(clubLeagueSubscriptionId)
    }

    if (clubId) {
      setClubId(clubId)
    }
  }, [clubLeagueSubscriptionId, setClubLeagueSubscriptionId, clubId, setClubId])

  const stats = statsQuery.data?.quizStatistics

  return (
    <Flex column grow>
      <QuizAd campaign={campaign} isEmbed={isEmbed} />
      <ClientSideQuiz
        quiz={quiz}
        campaign={campaign}
        isEmbed={isEmbed}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        quizStatistics={stats}
      />
    </Flex>
  )
}
export const ClientSideQuizPage = ({
  quiz,
  campaign,
  isEmbed = false,
  clubLeagueSubscriptionId,
  clubId,
}: {
  quiz: SanityLeagueQuiz
  campaign?: SanityLeagueQuizCampaign
  isEmbed?: boolean
  clubLeagueSubscriptionId?: string
  clubId?: string
}) => {
  return (
    <LeagueQuizContextProvider>
      <Layout hideNavbar hideHeaderPadding backgroundColor={colors.grey100}>
        <Content
          quiz={quiz}
          campaign={campaign}
          isEmbed={isEmbed}
          clubLeagueSubscriptionId={clubLeagueSubscriptionId}
          clubId={clubId}
        />
      </Layout>
    </LeagueQuizContextProvider>
  )
}
