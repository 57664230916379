/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import Lottie from 'lottie-react'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../constants'
import { viewerQuery as viewerQueryGql } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { titleFromQuiz } from '../../utils/util'
import { Badge, leagueStreakQuery } from '../../views/League/queries'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  LeagueQuizInstance,
  useLeagueQuizFinished,
} from '../../views/LeagueQuiz/queries'
import { PrimaryButton } from '../Button'
import { GenerateQuizButton } from '../GenerateQuizButton'
import { Text } from '../Text'
import { TextNew } from '../TextNew'
import { BadgeModalContent } from '../badges/BadgeModalContent'
import { ChallengeButton } from '../challenge/ChallengeButton'
import { Flex } from '../layout/Flex'
import { Modal, ModalTrigger } from '../modal/Modal'
import { Page } from './Page'
import { QuizScoreComponent } from './QuizScoreComponent'
import { TopPlayers } from './TopPlayers'
import confetti from './assets/lottie/confetti.json'

export const FinishedQuizDaily: FC<{
  leagueQuizInstance: LeagueQuizInstance
  playAnimation?: boolean
  newBadges: Badge[]
}> = ({ leagueQuizInstance, playAnimation = false, newBadges }) => {
  const t = useT()
  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [showBadgeModal, setShowBadgeModal] = useState(false)
  const [playConfetti, setPlayConfetti] = useState(false)

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)
  const {
    setIsExpandedAdActive,
    isExpandedAdActive,
    videoAdActive,
    hasSanityCampaign,
  } = useLeagueQuizContext()

  //Query leaderboard with friends only if viewer has friends
  const leagueQuizFinished = useLeagueQuizFinished({
    quizId: leagueQuizInstance.leagueQuiz.id,
    limit: 5,
  })

  const navigate = useNavigate()

  const apolloClient = useApolloClient()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  useEffect(() => {
    if (newBadges.length > 0 && remaining <= 0 && !videoAdActive) {
      setPlayConfetti(true)
      setShowBadgeModal(true)
    }
  }, [newBadges, remaining, videoAdActive])

  useEffect(() => {
    if (playConfetti) {
      setTimeout(() => {
        setPlayConfetti(false)
      }, 5000)
    }
  }, [playConfetti])

  const entries = leagueQuizFinished.data?.leagueQuiz?.leaderboard ?? []
  const friendEntries =
    leagueQuizFinished.data?.leagueQuiz?.friendsLeaderboard ?? []
  const hasFriendEntries = friendEntries.length > 0

  return (
    <Page showFcQuizLogo logoColor={colors.grey200}>
      <Flex
        column
        horizontal={'center'}
        vertical={'space-between'}
        css={css`
          height: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          margin: 0 auto;
          width: 100%;
          max-width: 500px;
        `}
      >
        {showBadgeModal && (
          <ModalTrigger
            forceIsOpen={showBadgeModal}
            button={() => (
              <div
                css={css`
                  position: absolute;
                  width: 0;
                  height: 0;
                `}
              />
            )}
            modal={({ closeModal }) => (
              <Modal
                heading={
                  newBadges.length > 1
                    ? t('New badges unlocked!')
                    : t('New badge unlocked!')
                }
                closeModal={closeModal}
                maxWidth={500}
                css={css`
                  text-align: center;
                `}
                transparent
              >
                <BadgeModalContent badges={newBadges} onClose={closeModal} />
              </Modal>
            )}
          />
        )}
        <Flex
          column
          horizontal={'center'}
          gap={'medium'}
          css={css`
            width: 100%;
          `}
        >
          {(!isExpandedAdActive || !hasSanityCampaign) && (
            <Text color={colors.grey400} size={'small'}>
              {titleFromQuiz(leagueQuizInstance.leagueQuiz, t)}
            </Text>
          )}
          <QuizScoreComponent
            questionsCount={leagueQuizInstance.leagueQuiz.questionsCount}
            result={leagueQuizInstance.result}
            remaining={remaining}
            totalCalculationTime={initialRemaining}
            playAnimation={playAnimation}
          />
        </Flex>
        {!videoAdActive && remaining <= 2000 && (
          <Flex
            column
            vertical={'center'}
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `,
            ]}
          >
            <TopPlayers
              quizId={leagueQuizInstance.leagueQuiz.id}
              leagueSlug={leagueQuizInstance.leagueQuiz.slug}
              entries={hasFriendEntries ? friendEntries : entries}
              viewerHasFriends={hasFriendEntries}
              hideLink
            />
            <Spacer height={32} />
            <GenerateQuizButton css={css``} />
            <Spacer height={24} />

            <ChallengeButton instanceId={leagueQuizInstance.id} />

            <Spacer height={24} />

            <TextNew
              textAlign="center"
              condensed
              italic
              weight={700}
              size={24}
              css={css`
                margin: 0px auto;
                margin-bottom: 24px;
                text-transform: uppercase;
              `}
            >
              {t('or')}
            </TextNew>

            <PrimaryButton
              noWrap
              css={css`
                border-radius: 99px;
                padding: 5px 50px;
                align-self: center;
                min-height: 48px;
              `}
              onClick={() => {
                apolloClient
                  .refetchQueries({
                    include: [leagueStreakQuery, viewerQueryGql],
                  })
                  .then(() => navigate('/'))
              }}
            >
              <TextNew
                condensed
                italic
                strong
                spacingEm={0}
                size={'large'}
                color={'white'}
              >
                {t(`Go to the home page`)}
              </TextNew>
            </PrimaryButton>
          </Flex>
        )}
        {playConfetti && (
          <Lottie
            animationData={confetti}
            loop={false}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000000000,
              // allow clicks to go through
              pointerEvents: 'none',
            }}
          />
        )}
      </Flex>
    </Page>
  )
}
