/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { FaShareSquare } from 'react-icons/fa'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { GroupMembersIcon } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { useFormatDate } from '../../utils/date'
import {
  League,
  useSubscribeToLeague,
  useUnsubscribeFromLeague,
} from './queries'

const Buttons = ({ league }: { league: League }) => {
  const { authUser } = useAuthContext()
  const t = useT()
  const [subscribeToLeague] = useSubscribeToLeague()
  const [unsubscribeFromLeague] = useUnsubscribeFromLeague()
  const client = useApolloClient()
  const [copied, setCopied] = useState(false)
  const trackEvent = useGetTrackEvent()

  const shareLeague = async () => {
    trackEvent(AnalyticsEventType.ShareLeagueClicked)

    const leagueLink = `${window.location.origin}/series/${league.slug}`

    const shareText = `⚽️ fcQuiz – ${league.title} ⚽️ \n\n${leagueLink}`

    if (window.navigator.share) {
      window.navigator.share({ text: shareText }).catch(() => {
        // ignore error
      })
    } else {
      navigator.clipboard.writeText(leagueLink)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }

  return (
    <Flex horizontal="center" gap="medium">
      {league.viewerIsSubscribed ? (
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton variant="white" onClick={openModal}>
              {t('Following')}
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <Modal
              heading={t('Stop following')}
              closeModal={closeModal}
              maxWidth={500}
            >
              <Flex column>
                <Text>
                  {t(
                    'If you stop following this series, you will not receive notifications about new quizzes.'
                  )}
                </Text>
                <Text css={margin.top('small')}>
                  {t('Are you sure you want to stop following?')}
                </Text>

                <Flex
                  horizontal="flex-end"
                  vertical="center"
                  css={margin.top('large')}
                  gap="medium"
                >
                  <button css={buttonReset} onClick={closeModal}>
                    <Text underline>{t('Cancel')}</Text>
                  </button>
                  <PrimaryButton
                    onClick={() => {
                      unsubscribeFromLeague({
                        variables: { leagueId: league.id },
                      }).then(closeModal)
                    }}
                  >
                    {t('Yes, stop following')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Modal>
          )}
        />
      ) : (
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              variant="white"
              onClick={() => {
                if (!authUser || authUser?.isAnonymous) {
                  openModal()
                  return
                }

                subscribeToLeague({
                  variables: { leagueId: league.id },
                }).then(() => client.refetchQueries({ include: 'active' }))
              }}
            >
              {t('Follow')}
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      )}
      <PrimaryButton variant="white" onClick={shareLeague}>
        <Flex vertical="center" gap="small">
          <FaShareSquare color="black" size={20} />
          <span
            css={css`
              position: relative;
              top: 2px;
            `}
          >
            {copied ? t('Copied!') : t('Share')}
          </span>
        </Flex>
      </PrimaryButton>
    </Flex>
  )
}

const boxStyles = css`
  padding: 16px;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const Header = ({ league }: { league: League }) => {
  const formatDate = useFormatDate()

  const t = useT()

  if (!league.match) {
    return null
  }

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px;
      `}
    >
      <Flex
        column
        horizontal="center"
        css={[
          css`
            grid-column: 1 / 3;
          `,
          boxStyles,
        ]}
      >
        <Heading
          level={2}
          textAlign="center"
          color="white"
          wide
          css={css`
            font-weight: 800;
            font-size: 42px;
          `}
        >
          {league.title}
        </Heading>
      </Flex>

      <Flex
        column
        horizontal="center"
        css={[
          css`
            grid-column: 1 / 3;
          `,
          boxStyles,
        ]}
      >
        <Text color="white" size="small">
          {formatDate(league.match.matchDate, 'full-datetime')}
        </Text>
      </Flex>

      {[league.match.homeTeam, league.match.awayTeam].map((item, index) => {
        return (
          <Flex
            column
            horizontal="center"
            vertical="flex-start"
            css={boxStyles}
            key={index}
          >
            {item.imageUrl && (
              <>
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  role="presentation"
                  css={css`
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                  `}
                />
                <Spacer height={8} />
              </>
            )}
            <Text textAlign="center" color="white">
              {item.name}
            </Text>
          </Flex>
        )
      })}

      <div
        css={[
          css`
            grid-column: 1 / 3;
            display: grid;
            grid-gap: 20px;
          `,
          boxStyles,
        ]}
      >
        {league.description && (
          <Text textAlign="center" color="white" size="small">
            {league.description}
          </Text>
        )}
        <Buttons league={league} />
      </div>

      <div
        css={[
          boxStyles,
          css`
            display: grid;
            grid-column: 1 / 3;
          `,
        ]}
      >
        <Flex gap="small" vertical="center" horizontal="center">
          <GroupMembersIcon color="white" />
          <Text color="white" size="tiny" textAlign="center">
            {t('{{count}} player', {
              count: league.participantCount,
            }).toUpperCase()}
          </Text>
        </Flex>
      </div>
    </div>
  )
}

export const MatchLeagueTopContent = ({ league }: { league: League }) => {
  return (
    <Flex column horizontal="center">
      <Flex
        column
        css={css`
          padding: 16px;
          width: 100%;
          max-width: 450px;
        `}
      >
        <Header league={league} />
      </Flex>
    </Flex>
  )
}
