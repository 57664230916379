/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { FitText } from '../../components/FitText'
import { Heading } from '../../components/Heading'
import { QuestionImage } from '../../components/QuestionImage'
import { Text } from '../../components/Text'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { AlternativeButton } from '../../components/leagueQuiz/AlternativeButton'
import { Page } from '../../components/leagueQuiz/Page'
import { SecondsCountdown } from '../../components/leagueQuiz/SecondsCountdown'
import { Timer } from '../../components/leagueQuiz/Timer'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { useRefValue } from '../../utils/useRefValue'
import {
  ActiveQuestion as ActiveQuestionType,
  CompletedQuestion,
} from '../LeagueQuiz/queries'
import {
  TrainingQuizInstance,
  TrainingQuizInstanceStatus,
  useAnswerTrainingQuestionMutation,
  useNextTrainingQuestionMutation,
} from './queries'

const QuizSplash: FC<{
  trainingQuiz: TrainingQuizInstance['trainingQuiz']
  nextQuestion: () => void
}> = ({ trainingQuiz, nextQuestion }) => {
  return (
    <Page backgroundColor={colors.yellow400}>
      <Flex column grow vertical="space-between">
        <Flex column css={margin.top('huge')}>
          <Heading level={2} looksLikeLevel={4}>
            {trainingQuiz.trainingCategory.title}
          </Heading>
          <Heading level={3} css={margin.top('small')}>
            {trainingQuiz.title}
          </Heading>

          <SecondsCountdown seconds={3} onFinished={nextQuestion} />
        </Flex>

        <Logo
          color={colors.yellow300}
          css={css`
            width: 100%;
            max-width: 400px;
            aspect-ratio: 1;
            height: unset;
          `}
        />
      </Flex>
    </Page>
  )
}

const ActiveQuestion: FC<{
  trainingQuizInstance: TrainingQuizInstance
  completedQuestions: CompletedQuestion[]
  activeQuestion: ActiveQuestionType
}> = ({ trainingQuizInstance, completedQuestions, activeQuestion }) => {
  const t = useT()
  const [answerTrainingQuestionMutation, { loading: answerQuestionLoading }] =
    useAnswerTrainingQuestionMutation()

  const answerQuestion = useCallback(
    async ({
      questionId,
      alternativeId,
    }: {
      questionId: string
      alternativeId: string | null
    }) => {
      const response = await answerTrainingQuestionMutation({
        variables: {
          input: {
            trainingQuizInstanceId: trainingQuizInstance.id,
            questionId,
            alternativeId,
          },
        },
      })

      if (!response.data?.answerTrainingQuestion) {
        return
      }

      if (
        response.data.answerTrainingQuestion.completedQuestions[
          response.data.answerTrainingQuestion.completedQuestions.length - 1
        ].alternatives.find(
          (a) => a.isCorrectAlternative && a.selectedAlternative
        )
      ) {
        // sound.playThatsRight()
      } else {
        // sound.playThatsWrong()
      }
    },
    [answerTrainingQuestionMutation, trainingQuizInstance.id]
  )

  const initialSecondsLeft = activeQuestion.secondsLeft

  const [remaining, setRemaining] = useState(initialSecondsLeft)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 ? null : 1000
  )

  const savedOnFinished = useRefValue(() => {
    if (activeQuestion && !answerQuestionLoading) {
      answerQuestion({
        questionId: activeQuestion.id,
        alternativeId: null,
      })
    }
  })
  useEffect(() => {
    if (remaining <= 0) {
      savedOnFinished.current()
    }
  }, [remaining, savedOnFinished])

  const shrink = keyframes`
    from {
      width: ${
        100 * (initialSecondsLeft / activeQuestion.maxTimeToAnswerInSeconds)
      }%;
    }
    to {
      width: 0%;
    }
`

  const progressColor =
    remaining > 6
      ? theme.colors.green300
      : remaining > 3
      ? theme.colors.yellow300
      : theme.colors.red300

  return (
    <>
      <Page backgroundColor={colors.green500}>
        <Flex column>
          <div
            css={css`
              position: relative;
              height: 8px;
              background-color: ${colors.green400};
            `}
          >
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                animation: ${shrink} ${initialSecondsLeft}s linear;
                background-color: ${progressColor};
                height: 8px;
              `}
            />
          </div>
          <Spacer height="medium" />

          <Flex horizontal="space-between">
            <Text strong color={colors.yellow400}>
              {completedQuestions.length + (activeQuestion ? 1 : 0)}/
              {trainingQuizInstance.trainingQuiz.questionCount}
            </Text>

            <Timer seconds={remaining} />
          </Flex>

          <FitText
            key={activeQuestion.text}
            color="white"
            css={margin.vertical('medium')}
            autoHeight={!!activeQuestion.imageUrl}
          >
            {activeQuestion.text}
          </FitText>
          {activeQuestion.imageUrl && (
            <QuestionImage imageSrc={activeQuestion.imageUrl} />
          )}
          <Flex column>
            {activeQuestion.alternatives.map((alternative) => {
              return (
                <AlternativeButton
                  key={alternative.id}
                  onClick={() => {
                    answerQuestion({
                      questionId: activeQuestion.id,
                      alternativeId: alternative.id,
                    })
                  }}
                  disabled={answerQuestionLoading}
                >
                  {alternative.text}
                </AlternativeButton>
              )
            })}

            <Flex column css={margin.top('large')}>
              <PrimaryButton
                disabled={true}
                css={css`
                  background-color: ${colors.yellow400};
                  opacity: 0.5;
                `}
              >
                {t('Next')}
              </PrimaryButton>
            </Flex>
          </Flex>
        </Flex>
      </Page>
    </>
  )
}

const LastCompletedQuestion: FC<{
  trainingQuizInstance: TrainingQuizInstance
  completedQuestions: CompletedQuestion[]
  lastCompletedQuestion: CompletedQuestion
  allQuestionsAnswered: boolean
  setFinished: (finished: boolean) => void
  nextQuestionLoading: boolean
  nextQuestion: () => void
}> = ({
  trainingQuizInstance,
  completedQuestions,
  lastCompletedQuestion,
  allQuestionsAnswered,
  setFinished,
  nextQuestionLoading,
  nextQuestion,
}) => {
  const t = useT()
  const trackEvent = useGetTrackEvent()

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'Training quiz',
        trainingQuizId: trainingQuizInstance.trainingQuiz.id,
      })
    }
  }, [allQuestionsAnswered, trainingQuizInstance.trainingQuiz.id])

  return (
    <Page backgroundColor={colors.green500}>
      <Flex column>
        <div
          css={css`
            height: 8px;
          `}
        />
        <Spacer height="medium" />

        <Flex horizontal="space-between">
          <Text strong color={colors.yellow400}>
            {completedQuestions.length}/
            {trainingQuizInstance.trainingQuiz.questionCount}
          </Text>

          <Timer seconds={lastCompletedQuestion.secondsLeft} />
        </Flex>

        <FitText
          key={lastCompletedQuestion.text}
          color="white"
          css={margin.vertical('medium')}
          autoHeight={!!lastCompletedQuestion.imageUrl}
        >
          {lastCompletedQuestion.text}
        </FitText>
        {lastCompletedQuestion.imageUrl && (
          <QuestionImage imageSrc={lastCompletedQuestion.imageUrl} />
        )}
        {!lastCompletedQuestion.alternatives.some(
          (alternative) => alternative.selectedAlternative
        ) && (
          <Text color={colors.red300} css={margin.bottom()}>
            {t('Unfortunately, the time ran out!')}
          </Text>
        )}
        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <AlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.selectedAlternative}
              correct={
                alternative.selectedAlternative
                  ? alternative.isCorrectAlternative
                  : null
              }
            >
              {alternative.text}
            </AlternativeButton>
          ))}

          <Flex column css={margin.top('large')}>
            {allQuestionsAnswered ? (
              <PrimaryButton
                onClick={() => setFinished(true)}
                css={css`
                  background-color: ${colors.yellow400};
                `}
              >
                <Text>{t('Finish')}</Text>
              </PrimaryButton>
            ) : (
              <PrimaryButton
                loading={nextQuestionLoading}
                onClick={nextQuestion}
                css={css`
                  background-color: ${colors.yellow400};
                `}
              >
                <Text>{t('Next')}</Text>
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Page>
  )
}

const FinishedQuiz = ({
  trainingQuizInstance,
}: {
  trainingQuizInstance: TrainingQuizInstance
}) => {
  const t = useT()
  const navigate = useNavigate()

  const correctAnswers = trainingQuizInstance.completedQuestions.filter(
    (question) =>
      question.alternatives.find(
        (alternative) =>
          alternative.selectedAlternative && alternative.isCorrectAlternative
      )
  ).length

  return (
    <Page backgroundColor={theme.colors.yellow400} showFcQuizLogo>
      <Flex column css={margin.top('huge')}>
        <Heading level={2} looksLikeLevel={4}>
          {trainingQuizInstance.trainingQuiz.trainingCategory.title}
        </Heading>
        <Heading level={3} css={margin.top('small')}>
          {trainingQuizInstance.trainingQuiz.title}
        </Heading>

        <Text
          strong
          css={css`
            font-size: 144px;
            position: relative;
          `}
        >
          {correctAnswers}/{trainingQuizInstance.trainingQuiz.questionCount}
        </Text>

        <PrimaryButton
          onClick={() => {
            navigate(-1)
          }}
          css={margin.top('huge')}
        >
          {t('Back')}
        </PrimaryButton>
      </Flex>
    </Page>
  )
}

export const TrainingQuiz = ({
  trainingQuizInstance,
}: {
  trainingQuizInstance: TrainingQuizInstance
}) => {
  const { trainingQuiz, completedQuestions, activeQuestion } =
    trainingQuizInstance

  const [nextTrainingQuestionMutation, { loading: nextQuestionLoading }] =
    useNextTrainingQuestionMutation()

  const [finished, setFinished] = useState<boolean>(
    trainingQuizInstance.status === TrainingQuizInstanceStatus.completed
  )

  const allQuestionsAnswered =
    completedQuestions.length === trainingQuiz.questionCount
  const lastCompletedQuestion =
    !activeQuestion && completedQuestions.length > 0
      ? completedQuestions[completedQuestions.length - 1]
      : null

  const nextQuestion = useCallback(
    () =>
      nextTrainingQuestionMutation({
        variables: {
          input: { trainingQuizInstanceId: trainingQuizInstance.id },
        },
      }),
    [trainingQuizInstance.id, nextTrainingQuestionMutation]
  )

  if (!activeQuestion && !lastCompletedQuestion) {
    return (
      <QuizSplash trainingQuiz={trainingQuiz} nextQuestion={nextQuestion} />
    )
  } else if (finished) {
    return <FinishedQuiz trainingQuizInstance={trainingQuizInstance} />
  } else if (activeQuestion) {
    return (
      <ActiveQuestion
        key={activeQuestion.id}
        trainingQuizInstance={trainingQuizInstance}
        completedQuestions={completedQuestions}
        activeQuestion={activeQuestion}
      />
    )
  } else if (lastCompletedQuestion) {
    return (
      <LastCompletedQuestion
        trainingQuizInstance={trainingQuizInstance}
        completedQuestions={completedQuestions}
        lastCompletedQuestion={lastCompletedQuestion}
        allQuestionsAnswered={allQuestionsAnswered}
        setFinished={setFinished}
        nextQuestionLoading={nextQuestionLoading}
        nextQuestion={nextQuestion}
      />
    )
  }
  return null
}
