export const isProduction = window.location.host === 'fcquiz.app'
export const isTest = window.location.host === 'test.fcquiz.app'
export const isDevelopment = window.location.host === 'localhost:3000'

export const rootApiUrl = () => {
  if (isDevelopment || window.location.host === 'localhost:3000') {
    return 'http://localhost:3001'
  }

  return ''
}

export const shouldUseProductionFirebaseConfig = () => {
  return window.location.host === 'fcquiz.app'
}

export const rootWebAppUrl = () => {
  if (isDevelopment) {
    return 'http://localhost:3000'
  }

  return window.location.origin
}

export const PROFILE_PICTURE_SIZE = {
  width: 200,
  height: 200,
}

export const sanityRootUrl = isDevelopment
  ? 'http://localhost:3333/desk/'
  : 'https://fcquiz.sanity.studio/desk/'

export const Auth0Config = {
  clientId: 'BhXC5H2iZgW1vFRdGWvIicEltCaZ9RIc',
  domain: 'id-stage.tv2.no',
  redirect_uri: rootWebAppUrl(),
}
