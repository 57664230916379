/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { TextNew } from '../../components/TextNew'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'

const Content = ({ loginMethod }: { loginMethod?: 'firebase' | 'tv2' }) => {
  const { authUser } = useAuthContext()

  const t = useT()

  const navigate = useNavigate()

  if (authUser) {
    navigate('/')
  }

  return (
    <Flex column grow>
      <Flex
        column
        horizontal="center"
        css={css`
          margin: 0 16px;
          background-color: ${colors.black};
          padding: 16px 24px;
        `}
        gap={16}
      >
        {loginMethod === 'tv2' && (
          <Flex column gap={'medium'}>
            <TextNew color={colors.white}></TextNew>
            <TextNew color={colors.white}>
              {t(
                `Please verify the email of the existing account before signing in with TV2.`
              )}
            </TextNew>
            <TextNew color={colors.white}>
              {t(
                `Log in to your account via email, and follow the instructions to verify your email.`
              )}
            </TextNew>
          </Flex>
        )}

        <PrimaryButton variant="white" signUpRequired>
          {t('Log in')}
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}

export const VerifyEmailPage = () => {
  const t = useT()

  const params = useParams()

  const loginMethod = params.loginMethod as 'firebase' | 'tv2' | undefined

  return (
    <Layout
      title={`fcQuiz | ${t('Email')}`}
      backgroundColor={colors.green600}
      showCookiebotButton
      hideAvatar
      hideNavbar
    >
      <Header color="white" />
      <Content loginMethod={loginMethod} />
    </Layout>
  )
}
