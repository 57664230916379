/** @jsxImportSource @emotion/react */

import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { growAndFade } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { UserAvatar } from '../../views/Avatar/UserAvatar'
import { LeagueQuizCard } from '../../views/Challenge/components/LeagueQuizCard'
import { ChallengeMatchData } from '../../views/Challenge/queries'
import { useLeagueQuizInstanceWithTransferQuery } from '../../views/ChallengeMatch/queries'
import { User } from '../../views/Profile/queries'
import { PrimaryButton, SecondaryButton } from '../Button'
import { DefaultError } from '../DefaultError'
import { Header } from '../Header'
import { Loader } from '../Loader'
import { Text } from '../Text'
import { TextNew } from '../TextNew'
import { AddFriendsButtonText } from '../friends/AddFriends'
import { Flex } from '../layout/Flex'
import { Layout } from '../layout/Layout'
import { PageContent } from '../layout/PageContent'
import { Page } from '../leagueQuiz/Page'
import { LoginModalInner } from '../modal/LoginModalInner'
import { ModalTrigger } from '../modal/Modal'

const friendsToChallengeQuery = gql`
  query friendsToChallenge($input: FriendsToChallengeInput!) {
    friendsToChallenge(input: $input) {
      user {
        id
        username
        slug
        avatarData
        displayName
      }
      hasPlayedQuiz
      hasBeenInvited
    }
  }
`

type FriendToChallenge = {
  user: User
  hasPlayedQuiz: boolean
  hasBeenInvited: boolean
}

const useFriendsToChallengeQuery = (quizId?: string) => {
  return useQuery<{
    friendsToChallenge: FriendToChallenge[]
  }>(friendsToChallengeQuery, {
    variables: { input: { quizId } },
    fetchPolicy: 'cache-and-network',
    skip: !quizId,
  })
}

//challengeFriendToMatch(input: ChallengeFriendToMatchInput!): ChallengeMatchData

const challengeFriendToMatchMutation = gql`
  mutation challengeFriendToMatch($input: ChallengeFriendToMatchInput!) {
    challengeFriendToMatch(input: $input) {
      id
    }
  }
`

const useChallengeFriendToMatchMutation = () => {
  return useMutation<
    { challengeFriendToMatch: ChallengeMatchData },
    { input: { quizId: string; friendId: string } }
  >(challengeFriendToMatchMutation)
}

const Content = ({ quizInstanceId }: { quizInstanceId: string }) => {
  const { authUser, loading: authLoading } = useAuthContext()

  const t = useT()

  const { data: instanceData, loading: instanceLoading } =
    useLeagueQuizInstanceWithTransferQuery({ id: quizInstanceId })

  const [challengeFriend] = useChallengeFriendToMatchMutation()

  const apolloClient = useApolloClient()

  const quizId = instanceData?.leagueQuizInstanceWithTransfer?.leagueQuiz.id

  const friendsQuery = useFriendsToChallengeQuery(quizId)

  const friendsToChallenge = friendsQuery.data?.friendsToChallenge || []

  const friendsSorted = useMemo(
    () =>
      [...friendsToChallenge].sort((a, b) => {
        const aCanbeenInvited = !a.hasBeenInvited && !a.hasPlayedQuiz
        const bCanbeenInvited = !b.hasBeenInvited && !b.hasPlayedQuiz

        if (aCanbeenInvited && !bCanbeenInvited) {
          return -1
        } else if (!aCanbeenInvited && bCanbeenInvited) {
          return 1
        }

        return 0
      }),
    [friendsToChallenge.map((f) => f.user.id)]
  )

  if (instanceLoading || authLoading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  const instance = instanceData?.leagueQuizInstanceWithTransfer

  if (!instance || !authUser) {
    const reason = !instance ? 'no instance' : 'no authUser'
    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`Challenge page | ${reason}`}
        />
      </Page>
    )
  }

  if (authUser.isAnonymous) {
    return (
      <Flex
        column
        grow
        css={css`
          width: 100%;
          opacity: 0%;
          animation: ${growAndFade} 350ms cubic-bezier(0.4, 0, 0.15, 1) forwards;
          animation-delay: 150ms;
        `}
      >
        <Flex column>
          <Text
            extraCondensed
            strong
            size="massive"
            textAlign="center"
            css={css`
              font-style: italic;
              font-size: 40px;
              line-height: 40px;
            `}
          >
            {t('Woah!').toUpperCase()}
          </Text>
          <Text
            extraCondensed
            strong
            size="massive"
            textAlign="center"
            css={css`
              font-style: italic;
              font-size: 36px;
              line-height: 32px;
            `}
          >
            {t('Challenge signup prompt pt1')}
          </Text>
          <Spacer height={32} />
          <LeagueQuizCard quiz={instance.leagueQuiz} />
          <Spacer height={32} />

          <Text
            condensed
            strong
            textAlign="center"
            css={css`
              font-size: 20px;
              line-height: 24px;
              padding: 0 24px;
            `}
          >
            {t('Challenge signup prompt pt2')}
          </Text>
          <Text
            condensed
            strong
            textAlign="center"
            css={css`
              font-size: 20px;
              line-height: 24px;
              padding: 0 24px;
            `}
          >
            {t('Challenge signup prompt pt3')}
          </Text>
        </Flex>
        <Spacer height={32} />
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              onClick={openModal}
              css={css`
                height: 50px;
              `}
            >
              <Text size="huge" condensed color="white">
                {t('Sign up / Log in')}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      </Flex>
    )
  }

  return (
    <Flex
      column
      grow
      css={css`
        width: 100%;
      `}
    >
      <Flex column vertical="flex-start">
        <Text
          extraCondensed
          strong
          textAlign="center"
          css={css`
            font-size: 44px;
            font-style: italic;
          `}
        >
          {t('Challenge a friend').toUpperCase()}
        </Text>
        <Spacer height={16} />
        <LeagueQuizCard quiz={instance.leagueQuiz} />
      </Flex>
      <Spacer height={32} />
      <Flex column gap="small">
        {friendsSorted.map((friend) => {
          return (
            <Flex
              vertical="center"
              gap="medium"
              horizontal="space-between"
              key={friend.user.id}
            >
              <Flex vertical="center" gap="tiny">
                <UserAvatar avatarData={friend.user.avatarData} height={56} />
                <TextNew
                  weight={600}
                  italic
                  textAlign="left"
                  condensed
                  css={[
                    css`
                      font-size: 22px;
                      line-height: 1.2;
                      font-style: italic;
                      flex: 1;
                    `,
                  ]}
                >
                  {friend.user.displayName}
                </TextNew>
              </Flex>

              {!friend.hasBeenInvited && !friend.hasPlayedQuiz && quizId ? (
                <PrimaryButton
                  rounded
                  onClick={async () => {
                    await challengeFriend({
                      variables: {
                        input: { quizId, friendId: friend.user.id },
                      },
                    })

                    await apolloClient.refetchQueries({
                      include: [friendsToChallengeQuery],
                    })
                  }}
                >
                  <TextNew
                    weight={600}
                    italic
                    textAlign="left"
                    condensed
                    color={colors.white}
                    css={[
                      css`
                        padding: 0 8px;
                        font-size: 22px;
                        line-height: 1.2;
                        font-style: italic;
                        text-decoration: capitalize;
                      `,
                    ]}
                  >
                    {t('Challenge_verb')}
                  </TextNew>
                </PrimaryButton>
              ) : (
                <TextNew
                  italic
                  textAlign="left"
                  size={'medium'}
                  color={colors.grey400}
                  condensed
                  css={[
                    css`
                      line-height: 1.2;
                      font-style: italic;
                      display: flex;
                    `,
                  ]}
                >
                  {friend.hasBeenInvited ? t('Invited') : t('Already played')}
                </TextNew>
              )}
            </Flex>
          )
        })}
        <AddFriendsButtonText variant="black" />
        <Spacer height={100} />
      </Flex>
    </Flex>
  )
}

export const ChallengePage = () => {
  const quizInstanceId = useParams<{ quizInstanceId: string }>().quizInstanceId
  const t = useT()

  const navigate = useNavigate()

  return (
    <Layout>
      <Header color="grey" hideSignupButton />
      <PageContent gap="medium">
        <Flex>
          <SecondaryButton onClick={() => navigate(-1)}>
            {t('Back')}
          </SecondaryButton>
        </Flex>
        {quizInstanceId ? (
          <Content quizInstanceId={quizInstanceId} />
        ) : (
          <DefaultError
            css={margin.top('huge')}
            sentryErrorMessage="Challenge page | useParams could not find quizInstanceId"
          />
        )}
      </PageContent>
    </Layout>
  )
}
