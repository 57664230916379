/** @jsxImportSource @emotion/react */

import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { UserAvatar } from '../Avatar/UserAvatar'
import { useUserProfileQuery } from '../Profile/queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'

type SetUserEmailVerifiedInput = {
  userId: string
  emailVerified: boolean
}

const setUserEmailVerifiedMutation = gql`
  mutation setUserEmailVerified($input: SetUserEmailVerifiedInput!) {
    setUserEmailVerified(input: $input) {
      id
    }
  }
`

const useSetUserEmailVerifiedMutation = () => {
  return useMutation<
    { setUserEmailVerified: { id: string } },
    { input: SetUserEmailVerifiedInput }
  >(setUserEmailVerifiedMutation)
}

const Content = () => {
  const [userId, setUserId] = useState('')
  const [emailVerified, setEmailVerified] = useState(true)

  const debounced = useDebouncedString(userId, 500, { minLength: 3 })

  const userQuery = useUserProfileQuery(debounced)
  const [setUserEmailVerified, { loading: mutationLoading }] =
    useSetUserEmailVerifiedMutation()

  const loading = mutationLoading || userQuery.loading

  const user = userQuery.data?.user
  return (
    <PageContent gap={'medium'}>
      <AdminNavbar activePage="customerSupport" />

      <Flex gap="medium">
        <TextInput
          value={userId}
          onValue={setUserId}
          label="User id"
          css={css`
            flex: 1;
          `}
          textCss={css`
            border: 1px solid ${colors.grey400};
            flex: 1;
          `}
        />
        <SecondaryButton
          disabled={loading || userId === ''}
          onClick={() => {
            setUserId('')
            setEmailVerified(false)
          }}
        >
          Clear
        </SecondaryButton>
      </Flex>
      {user && (
        <Flex vertical="center" gap="medium">
          <UserAvatar avatarData={user.avatarData} height={40} />
          <TextNew color={colors.black}>
            {user.displayName || user.username}
          </TextNew>
        </Flex>
      )}
      <Flex vertical="center" gap="medium" horizontal="space-between" wrap>
        <ToggleSwitch
          value={emailVerified}
          onValue={setEmailVerified}
          label={emailVerified ? 'Set as Verified' : 'Set as Unverified'}
        />
        <PrimaryButton
          noWrap
          loading={loading}
          onClick={() => {
            setUserEmailVerified({
              variables: {
                input: {
                  userId,
                  emailVerified,
                },
              },
            })
          }}
        >
          Apply
        </PrimaryButton>
      </Flex>
    </PageContent>
  )
}

export const AdminCustomerSupportPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <Header color="green" />
        <Content />
      </AdminAccessControlComponent>
    </Layout>
  )
}
