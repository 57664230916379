/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { useNativeApp } from '../lib/native/NativeAppContext'
import { isProduction } from '../config/config'
import { AnalyticsEventType, useGetTrackEvent } from '../lib/analytics'
import { useIsEmbed } from '../App'

export const TrackReferrer = () => {
  // check if current path includes /embed to avoid tracking referrer for embeds
  const { isNativeApp } = useNativeApp()

  const isEmbed = useIsEmbed()

  const trackEvent = useGetTrackEvent()

  try {
    useEffect(() => {
      if (isNativeApp) {
        return
      }

      if (isEmbed) {
        return
      }

      if (document.referrer) {
        // handle referrer from our own embeds
        if (document.referrer.includes('fcquiz.app')) {
          const embedIdentifier = 'fcquiz.app/embed/club-league-quiz/'
          if (document.referrer.includes(embedIdentifier)) {
            const clubSubscriptionId = document.referrer
              .split(embedIdentifier)[1]
              .split('/')[0]

            trackEvent(AnalyticsEventType.Refferer, {
              referrer: clubSubscriptionId,
            })
          }
          return
        }

        // external referrer
        trackEvent(AnalyticsEventType.Refferer, {
          referrer: document.referrer,
        })
      }
    }, [])
  } catch (error) {
    if (!isProduction) {
      console.error(error)
    }
  }

  return null
}
