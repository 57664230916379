// ContextProvider for notifications

import { createContext, useContext, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useAuthContext } from './AuthContext'
import { FriendStatus } from '../views/Profile/queries'

export type Notification = {
  id: string
  fromUser?: {
    id: string
    slug: string
    avatarData: string
    friendStatus: FriendStatus
    username: string
    displayName?: string
  }
  message: string
  friendshipId?: string
  clubId?: string
  seen: boolean
  createdAt: string
  dateRangeChallenge?: {
    id: string
    title: string
  }
  challengeMatchId?: string
  challengeMatch?: {
    id: string
    status: string
  }
}

type NotificationContextType = {
  notifications: Notification[]
  setNotifications: (notifications: Notification[]) => void
  setNotificationsAsSeen: () => void
  unseenNotifications: number
  refreshNotifications: () => Promise<void>
}

export const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  setNotifications: () => {},
  setNotificationsAsSeen: () => {},
  unseenNotifications: 0,
  refreshNotifications: async () => {},
})

export const useNotificationContext = () => {
  return useContext(NotificationContext)
}

export const NotificationProvider: React.FC = ({ children }) => {
  const isEmbed = location.pathname.includes('embed')
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unseenNotifications, setUnseenNotifications] = useState(0)
  const { refetch } = useNotificationQuery({ skip: isEmbed })
  const { authUser } = useAuthContext()
  const [unseenIds, setUnseenIds] = useState<string[]>([])
  const [markNotificationsAsSeen] =
    useMarkNotificationsAsSeenMutation(unseenIds)

  const setNotificationsAsSeen = () => {
    if (unseenNotifications === 0) return
    markNotificationsAsSeen()
      .then(() => {
        setUnseenNotifications(0)
        setUnseenIds([])
      })
      .catch((error) =>
        console.error('Error marking notifications as seen', error)
      )
  }

  const refreshNotifications = async () => {
    if (location.pathname.includes('embed')) {
      console.log('Skipping notification refresh in embed mode')
      return
    }
    if (!authUser) {
      return
    }
    const { data } = await refetch()
    if (!data) {
      return
    }
    setNotifications(data.notifications)
    const unseen = data.notifications.filter((n) => !n.seen).length
    setUnseenNotifications(unseen)
    setUnseenIds(data.notifications.filter((n) => !n.seen).map((n) => n.id))
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        setNotificationsAsSeen,
        unseenNotifications,
        refreshNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

const useNotificationQuery = ({ skip = false }: { skip?: boolean }) => {
  return useQuery<{ notifications: Notification[] }>(notificationsQuery, {
    fetchPolicy: 'network-only',
    skip,
  })
}

export const notificationsQuery = gql`
  query Notifications {
    notifications {
      id
      fromUser {
        id
        slug
        avatarData
        friendStatus
        username
        displayName
      }
      message
      seen
      createdAt
      friendshipId
      clubId
      dateRangeChallenge {
        id
        title
      }
      challengeMatchId
      challengeMatch {
        id
        status
      }
    }
  }
`

export const useMarkNotificationsAsSeenMutation = (ids: string[]) => {
  return useMutation(markNotificationsAsSeenMutation, {
    variables: { ids },
  })
}

const markNotificationsAsSeenMutation = gql`
  mutation MarkNotificationsAsSeen($ids: [ID!]!) {
    markNotificationsAsSeen(ids: $ids)
  }
`
